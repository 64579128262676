/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative; }
  md-grid-list md-grid-tile,
  md-grid-list md-grid-tile > figure,
  md-grid-list md-grid-tile-header,
  md-grid-list md-grid-tile-footer {
    box-sizing: border-box; }
  md-grid-list md-grid-tile {
    display: block;
    position: absolute; }
    md-grid-list md-grid-tile figure {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
              justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0; }
    md-grid-list md-grid-tile md-grid-tile-header,
    md-grid-list md-grid-tile md-grid-tile-footer {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h3,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-weight: 400;
        margin: 0 0 0 16px; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-footer h3 {
        font-size: 14px; }
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-size: 12px; }
    md-grid-list md-grid-tile md-grid-tile-header {
      top: 0; }
    md-grid-list md-grid-tile md-grid-tile-footer {
      bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff; }
  md-grid-tile-footer {
    border-top: 1px solid #fff; } }
