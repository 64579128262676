@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.row.debug .column:before, .row.debug .columns:before, .styleguide__color-box--x-light-gray:before, .sg.sg-section[id^='section-1.2']:before {
  content: '';
  position: absolute; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1, .heading-style--h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a, .link {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active, .link:active,
a:hover,
.link:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&smallish=33.75em&medium=48em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 16px; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ececed;
  font-family: "Nordea Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 76.5rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
  .row .row {
    margin-right: -0.75rem;
    margin-left: -0.75rem; }
    @media screen and (min-width: 33.75em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  min-width: initial; }
  @media print, screen and (min-width: 48em) {
    .column, .columns {
      padding-right: 0.75rem;
      padding-left: 0.75rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.flex-container {
  display: -ms-flexbox;
  display: flex; }

.flex-child-auto {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }

.flex-child-grow {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.flex-child-shrink {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto; }

.flex-dir-row {
  -ms-flex-direction: row;
      flex-direction: row; }

.flex-dir-row-reverse {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }

.flex-dir-column {
  -ms-flex-direction: column;
      flex-direction: column; }

.flex-dir-column-reverse {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }

.small-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-order-1 {
  -ms-flex-order: 1;
      order: 1; }

.small-order-2 {
  -ms-flex-order: 2;
      order: 2; }

.small-order-3 {
  -ms-flex-order: 3;
      order: 3; }

.small-order-4 {
  -ms-flex-order: 4;
      order: 4; }

.small-order-5 {
  -ms-flex-order: 5;
      order: 5; }

.small-order-6 {
  -ms-flex-order: 6;
      order: 6; }

.small-up-1 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

@media screen and (min-width: 33.75em) {
  .smallish-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .smallish-offset-0 {
    margin-left: 0%; }
  .smallish-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .smallish-offset-1 {
    margin-left: 8.33333%; }
  .smallish-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .smallish-offset-2 {
    margin-left: 16.66667%; }
  .smallish-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .smallish-offset-3 {
    margin-left: 25%; }
  .smallish-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .smallish-offset-4 {
    margin-left: 33.33333%; }
  .smallish-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .smallish-offset-5 {
    margin-left: 41.66667%; }
  .smallish-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .smallish-offset-6 {
    margin-left: 50%; }
  .smallish-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .smallish-offset-7 {
    margin-left: 58.33333%; }
  .smallish-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .smallish-offset-8 {
    margin-left: 66.66667%; }
  .smallish-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .smallish-offset-9 {
    margin-left: 75%; }
  .smallish-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .smallish-offset-10 {
    margin-left: 83.33333%; }
  .smallish-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .smallish-offset-11 {
    margin-left: 91.66667%; }
  .smallish-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .smallish-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .smallish-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .smallish-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .smallish-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .smallish-order-6 {
    -ms-flex-order: 6;
        order: 6; }
  .smallish-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-1 > .column, .smallish-up-1 > .columns {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .smallish-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-2 > .column, .smallish-up-2 > .columns {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .smallish-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-3 > .column, .smallish-up-3 > .columns {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .smallish-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-4 > .column, .smallish-up-4 > .columns {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .smallish-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-5 > .column, .smallish-up-5 > .columns {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .smallish-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-6 > .column, .smallish-up-6 > .columns {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .smallish-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-7 > .column, .smallish-up-7 > .columns {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .smallish-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .smallish-up-8 > .column, .smallish-up-8 > .columns {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 33.75em) and (min-width: 33.75em) {
  .smallish-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media screen and (min-width: 33.75em) {
  .smallish-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .smallish-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .smallish-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .smallish-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
  .smallish-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .smallish-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .smallish-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; } }

.row.smallish-unstack > .column, .row.smallish-unstack > .columns {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media screen and (min-width: 33.75em) {
    .row.smallish-unstack > .column, .row.smallish-unstack > .columns {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media screen and (min-width: 33.75em) {
  .smallish-collapse > .column, .smallish-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .smallish-uncollapse > .column, .smallish-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; } }

@media print, screen and (min-width: 48em) {
  .medium-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .medium-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .medium-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .medium-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .medium-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .medium-order-6 {
    -ms-flex-order: 6;
        order: 6; }
  .medium-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .medium-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .medium-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .medium-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .medium-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .medium-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
  .medium-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .medium-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .medium-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media print, screen and (min-width: 48em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .large-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .large-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .large-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .large-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .large-order-6 {
    -ms-flex-order: 6;
        order: 6; }
  .large-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .large-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .large-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .large-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
  .large-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .large-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .large-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .xlarge-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .xlarge-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .xlarge-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .xlarge-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .xlarge-order-6 {
    -ms-flex-order: 6;
        order: 6; }
  .xlarge-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
  .xlarge-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; } }

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media screen and (min-width: 75em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .xxlarge-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .xxlarge-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .xxlarge-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .xxlarge-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .xxlarge-order-6 {
    -ms-flex-order: 6;
        order: 6; }
  .xxlarge-up-1 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
  .xxlarge-up-2 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      max-width: 50%; }
  .xxlarge-up-3 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxlarge-up-4 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
      max-width: 25%; }
  .xxlarge-up-5 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
      max-width: 20%; }
  .xxlarge-up-6 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxlarge-up-7 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      -ms-flex: 0 0 14.28571%;
          flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxlarge-up-8 {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      -ms-flex: 0 0 12.5%;
          flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 90em) and (min-width: 90em) {
  .xxlarge-expand {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media screen and (min-width: 90em) {
  .xxlarge-flex-dir-row {
    -ms-flex-direction: row;
        flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    -ms-flex-direction: column;
        flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
  .xxlarge-flex-child-auto {
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; } }

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }
  @media screen and (min-width: 90em) {
    .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; } }

@media screen and (min-width: 90em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; } }

.shrink {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.5rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .column-block {
      margin-bottom: 1.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
.heading-style--h1,
h2,
.heading-style--h2,
h3,
.heading-style--h3,
h4,
.heading-style--h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1.5rem;
  font-size: inherit;
  line-height: 1.5;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .heading-style--h1,
h2,
.heading-style--h2,
h3,
.heading-style--h3,
h4,
.heading-style--h4,
h5,
h6 {
  font-family: "Nordea Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-rendering: optimizeLegibility; }
  h1 small, .heading-style--h1 small,
  h2 small, .heading-style--h2 small,
  h3 small, .heading-style--h3 small,
  h4 small, .heading-style--h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #8b8a8d; }

h1, .heading-style--h1 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem; }

h2, .heading-style--h2 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem; }

h3, .heading-style--h3 {
  font-size: 1.1875rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem; }

h4, .heading-style--h4 {
  font-size: 1.125rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem; }

h6 {
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem; }

@media print, screen and (min-width: 48em) {
  h1, .heading-style--h1 {
    font-size: 3rem; }
  h2, .heading-style--h2 {
    font-size: 2.5rem; }
  h3, .heading-style--h3 {
    font-size: 1.9375rem; }
  h4, .heading-style--h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a, .link {
  line-height: inherit;
  color: #0000a0;
  text-decoration: none;
  cursor: pointer; }
  a:hover, .link:hover, a:focus, .link:focus {
    color: #0000a0;
    text-decoration: underline; }
  a img, .link img {
    border: 0; }

hr {
  clear: both;
  max-width: 76.5rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #8b8a8d;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1.5rem;
  list-style-position: outside;
  line-height: 1.5; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1.5rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #8b8a8d; }
  blockquote, blockquote p {
    line-height: 1.5;
    color: #8b8a8d; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8b8a8d; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #000000;
  color: #000000;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #8b8a8d;
  background-color: #e6e4e3;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e4e3;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8b8a8d; }

.lead {
  font-size: 20px;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left, .text--left {
  text-align: left; }

.text-right, .text--right {
  text-align: right; }

.text-center, .text--center {
  text-align: center; }

.text-justify, .text--justify {
  text-align: justify; }

@media screen and (min-width: 33.75em) {
  .smallish-text-left {
    text-align: left; }
  .smallish-text-right {
    text-align: right; }
  .smallish-text-center {
    text-align: center; }
  .smallish-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a, .link,
  a:visited,
  .link:visited {
    text-decoration: underline; }
  a[href]:after, [href].link:after {
    content: " (" attr(href) ")"; }
  .ir a:after, .ir .link:after,
  a[href^='javascript:']:after,
  [href^='javascript:'].link:after,
  a[href^='#']:after,
  [href^='#'].link:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8b8a8d;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  .heading-style--h2,
  h3,
  .heading-style--h3 {
    orphans: 3;
    widows: 3; }
  h2, .heading-style--h2,
  h3,
  .heading-style--h3 {
    page-break-after: avoid; } }

/**
Visibility classes

Foundation for sites <a href="http://http://foundation.zurb.com/sites/docs/visibility.html">visibility classes</a> are included.
See the link for details. Not all modifers are listed here.

Additionally, we have borrowed `.text--hidden` and `.text--squished` from Compass libraries, which use different methods to hide elements.

.hide           - Hides an element, `display: none`
.invisible      - Makes an element invisible but keeps its size,  display: hidden`
.show-on-focus - Hides an element except when it has focus. This is used for creating skip links for screen readers, can be focused by keyboard navigation.
.text--hidden  - Hides an element with `text-indent` so you can see the background. If a text element, element will retain the height of one line of text.
.text--squished  - Hides text in an element by squishing the text into oblivion. Use this if you need to hide text contained in an inline element but still have it read by a screen reader. Height of text elements will be 0.

Markup: <a href="http://www.nordea.fi" class="{$modifiers}">A link to Nordea FI home</a>

Styleguide 3.3
*/
.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 33.6875em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 33.75em) {
  .show-for-small-only {
    display: none !important; } }

@media screen and (min-width: 33.75em) {
  .hide-for-smallish {
    display: none !important; } }

@media screen and (max-width: 33.6875em) {
  .show-for-smallish {
    display: none !important; } }

@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .hide-for-smallish-only {
    display: none !important; } }

@media screen and (max-width: 33.6875em), screen and (min-width: 48em) {
  .show-for-smallish-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

/**
Float classes

Foundation for sites <a href="http://foundation.zurb.com/sites/docs/float-classes.html">float classes</a> are included.
See the link for details.

.float-left   - Float an element on the left
.float-right  - Float an element on the right
.float-center - Center an element. Will only work on block elements with absolute width.

Markup: <div class="clearfix"><div style="display: block;" class="styleguide__color-box--nordea-blue {$modifiers}"></div></div>

Styleguide 3.4
*/
/**
Clearfix

Use this class on the parent element of floating elements to ensure correct container size.

Markup:
<div class="callout" style="margin-bottom: 150px;"><div style="width: 100px; height: 100px;" class="float-left styleguide__color-box--nordea-blue"></div><div class="float-right">Without clearfix</div></div>
<div class="callout clearfix"><div style="width: 100px; height: 100px;" class="float-left styleguide__color-box--nordea-blue"></div><div class="float-right">With clearfix</div></div>

Styleguide 3.4.1
*/
.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-order: 1;
      order: 1; }

.clearfix::after {
  clear: both; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.5rem;
    top: 0.5rem;
    font-size: 2rem;
    line-height: 0.5; }
  .close-button, .close-button.medium {
    right: 0.5rem;
    top: 0.5rem;
    font-size: 2rem;
    line-height: 0.5; }

.menu {
  margin: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: center;
      align-items: center;
  width: 100%; }
  .menu > li {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a, .menu > li > .link {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu .link,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a, .menu > li > .link {
    display: -ms-flexbox;
    display: flex; }
  .menu > li > a, .menu > li > .link {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap; }
    .menu > li > a img, .menu > li > .link img,
    .menu > li > a i,
    .menu > li > .link i,
    .menu > li > a svg,
    .menu > li > .link svg {
      margin-right: 0.25rem; }
  .menu, .menu.horizontal {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .menu > li, .menu.horizontal > li {
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  .menu.expanded > li {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; }
  .menu.expanded > li:first-child:last-child {
    width: 100%; }
  .menu.vertical {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .menu.vertical > li {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%; }
    .menu.vertical > li > a, .menu.vertical > li > .link {
      -ms-flex-pack: start;
          justify-content: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }
  @media screen and (min-width: 33.75em) {
    .menu.smallish-horizontal {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      .menu.smallish-horizontal > li {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .menu.smallish-expanded > li {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }
    .menu.smallish-expanded > li:first-child:last-child {
      width: 100%; }
    .menu.smallish-vertical {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .menu.smallish-vertical > li {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        max-width: 100%; }
      .menu.smallish-vertical > li > a, .menu.smallish-vertical > li > .link {
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; } }
  @media print, screen and (min-width: 48em) {
    .menu.medium-horizontal {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      .menu.medium-horizontal > li {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .menu.medium-expanded > li {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }
    .menu.medium-expanded > li:first-child:last-child {
      width: 100%; }
    .menu.medium-vertical {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .menu.medium-vertical > li {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        max-width: 100%; }
      .menu.medium-vertical > li > a, .menu.medium-vertical > li > .link {
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      .menu.large-horizontal > li {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .menu.large-expanded > li {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }
    .menu.large-expanded > li:first-child:last-child {
      width: 100%; }
    .menu.large-vertical {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .menu.large-vertical > li {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        max-width: 100%; }
      .menu.large-vertical > li > a, .menu.large-vertical > li > .link {
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; } }
  @media screen and (min-width: 75em) {
    .menu.xlarge-horizontal {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      .menu.xlarge-horizontal > li {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .menu.xlarge-expanded > li {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }
    .menu.xlarge-expanded > li:first-child:last-child {
      width: 100%; }
    .menu.xlarge-vertical {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .menu.xlarge-vertical > li {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        max-width: 100%; }
      .menu.xlarge-vertical > li > a, .menu.xlarge-vertical > li > .link {
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; } }
  @media screen and (min-width: 90em) {
    .menu.xxlarge-horizontal {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
      .menu.xxlarge-horizontal > li {
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .menu.xxlarge-expanded > li {
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }
    .menu.xxlarge-expanded > li:first-child:last-child {
      width: 100%; }
    .menu.xxlarge-vertical {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .menu.xxlarge-vertical > li {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        max-width: 100%; }
      .menu.xxlarge-vertical > li > a, .menu.xxlarge-vertical > li > .link {
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a, .menu.simple .link {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right {
    -ms-flex-pack: end;
        justify-content: flex-end; }
  .menu.icon-top > li > a, .menu.icon-top > li > .link {
    -ms-flex-flow: column nowrap;
        flex-flow: column nowrap; }
    .menu.icon-top > li > a img, .menu.icon-top > li > .link img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > .link i,
    .menu.icon-top > li > a svg,
    .menu.icon-top > li > .link svg {
      -ms-flex-item-align: stretch;
          -ms-grid-row-align: stretch;
          align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-top.vertical a > span, .menu.icon-top.vertical .link > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a, .menu .active > .link {
    background: transparent;
    color: #0000a0; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.align-right {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.align-center {
  -ms-flex-pack: center;
      justify-content: center; }

.align-justify {
  -ms-flex-pack: justify;
      justify-content: space-between; }

.align-spaced {
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.align-top {
  -ms-flex-align: start;
      align-items: flex-start; }

.align-self-top {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.align-bottom {
  -ms-flex-align: end;
      align-items: flex-end; }

.align-self-bottom {
  -ms-flex-item-align: end;
      align-self: flex-end; }

.align-middle {
  -ms-flex-align: center;
      align-items: center; }

.align-self-middle {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }

.align-stretch {
  -ms-flex-align: stretch;
      align-items: stretch; }

.align-self-stretch {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; }

.small-order-1 {
  -ms-flex-order: 1;
      order: 1; }

.small-order-2 {
  -ms-flex-order: 2;
      order: 2; }

.small-order-3 {
  -ms-flex-order: 3;
      order: 3; }

.small-order-4 {
  -ms-flex-order: 4;
      order: 4; }

.small-order-5 {
  -ms-flex-order: 5;
      order: 5; }

.small-order-6 {
  -ms-flex-order: 6;
      order: 6; }

@media screen and (min-width: 33.75em) {
  .smallish-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .smallish-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .smallish-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .smallish-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .smallish-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .smallish-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

@media print, screen and (min-width: 48em) {
  .medium-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .medium-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .medium-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .medium-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .medium-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .medium-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .large-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .large-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .large-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .large-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .large-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .xlarge-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .xlarge-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .xlarge-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .xlarge-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .xlarge-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    -ms-flex-order: 1;
        order: 1; }
  .xxlarge-order-2 {
    -ms-flex-order: 2;
        order: 2; }
  .xxlarge-order-3 {
    -ms-flex-order: 3;
        order: 3; }
  .xxlarge-order-4 {
    -ms-flex-order: 4;
        order: 4; }
  .xxlarge-order-5 {
    -ms-flex-order: 5;
        order: 5; }
  .xxlarge-order-6 {
    -ms-flex-order: 6;
        order: 6; } }

/**
Base

Base styles, settings, mixins.

Styleguide 1
*/
body {
  direction: ltr;
  position: relative;
  padding: 0;
  margin: 0; }

html, body {
  height: 100%; }

[data-whatinput="mouse"] *:focus {
  outline: none; }

body:after {
  content: "";
  font-weight: bold; }

/*
Icons

This bundle comes with an SVG icon sprite file <code>icons/icon-pack.svg</code>. There are two ways of using it.

Inline SVG: Inject the contents of the file as the first element in the document's <code>body</code> element. Then reference an icon as in
<pre>
  &lt;svg class="icon" focusable="false"&gt;
    &lt;use xlink:href="#icon_name"&gt;&lt;/use&gt;
  &lt;/svg&gt;
</pre>

<strong>Note:</strong> `focusable="false"` prevents tab navigation focus on the SVG icon in Internet Explorer. This is important for correct tab navigation order.

External resource: Use <a href="https://github.com/jonathantneal/svg4everybody">svg4everybody</a> to ensure cross-browser support.
Then reference an icon as in
<pre>
  &lt;svg class="icon" focusable="false"&gt;
    &lt;use xlink:href="path/to/icon-pack.svg#icon_name"&gt;&lt;/use&gt;
  &lt;/svg&gt;
</pre>

The examples below use the second method. <a href="https://github.com/jonathantneal/svg4everybody">svg4everybody</a> is included for the style guide only.

Styleguide 1.5
*/
/*
Icon accessibility

<h3>Icon is a decorative element inside a container that has a descriptive text</h3>

The icon should have aria-hidden="true" defined to it.

Example:
<pre>
  &lt;a href=".../new_payment..."&gt;
    &lt;svg class="icon" focusable="false" aria-hidden=”true"&gt;
      &lt;use xlink:href="path/to/icon-pack.svg#icon_name"&gt;&lt;/use&gt;
    &lt;/svg&gt;
    New payment
  &lt;/a&gt;
</pre>

<h3>Icon is the only content of a link or a button</h3>

Add aria-label to the element containing the icon.
The icon should have aria-hidden="true" defined to it.

Example:
<pre>
  &lt;a href=".../new_payment..." aria-label="New payment"&gt;
    &lt;svg class="icon" focusable="false" aria-hidden=”true"&gt;
      &lt;use xlink:href="path/to/icon-pack.svg#icon_name"&gt;&lt;/use&gt;
    &lt;/svg&gt;
  &lt;/a&gt;
</pre>

<h3>Icon is purely decorative or an empty element</h3>

Add role="presentation" to the icon.

<pre>
   &lt;svg class="icon" focusable="false" role="presentation"&gt;
     &lt;use xlink:href="path/to/icon-pack.svg#icon_name"&gt;&lt;/use&gt;
   &lt;/svg&gt;
</pre>

<h3>Image or graphic conveys some content that is not conveyed elsewhere in the text</h3>

Add role="img", title and/or description.

<pre>
   &lt;svg class="icon" focusable="false" role="img" aria-labeledby=”title1 dsc1”&gt;
    &lt;title id=”title1”&gt;Descriptive title
    &lt;/title&gt;
    &lt;desc id=”dsc1”&gt;Longer description here….
    &lt;/desc&gt;
       &lt;use xlink:href="path/to/icon-pack.svg#icon_name"&gt;&lt;/use&gt;
   &lt;/svg&gt;
</pre>


Styleguide 1.5.1
*/
/*
Icon sizes

.icon - Default icon
.icon.icon--small - Small icon
.icon.icon--smallish - Smallish icon
.icon.icon--medium - Medium icon
.icon.icon--large - Large icon
.icon.icon--xlarge - Extra large icon

Markup:
<svg class="{$modifiers}" focusable="false">
    <use xlink:href="/build/icons/icon-pack.svg#home"></use>
</svg>

Styleguide 1.5.2

*/
/*
Icon colors

You can override the default icon color using the CSS attribute <code>color</code>.
Below are just some examples.

.icon.text--success - Icon with "success" color
.icon.text--error - Icon with "error" color

Markup:
<svg class="{$modifiers}" focusable="false">
    <use xlink:href="/build/icons/icon-pack.svg#home"></use>
</svg>

Styleguide 1.5.3

*/
/*
List of standard-sized icons

accounts - icon
add - icon
addBeneficiary - icon
additionalInfo - icon
addressBook - icon
advice - icon
alert - icon
arrowDown - icon
arrowLeft - icon
arrowRight - icon
arrowUp - icon
arrowBack - icon
attachment - icon
authenticate - icon
beneficiary - icon
betalingsservice - icon
boat - icon
book - icon
buy - icon
calendar - icon
cards - icon
cart - icon
carTransport - icon
cashSavings - icon
changeOrder - icon
chat - icon
chatBubble - icon
chatBubbleNot - icon
chatBubbleTyping - icon
chatBubbleQuestion - icon
checkAndPay - icon
checkMarkSmall - icon
children - icon
clock - icon
close - icon
close-notification - icon
closeProductCard - icon
clothing - icon
codesApp - icon
coins - icon
companies - icon
company - icon
computer - icon
confirmSign - icon
contactUs - icon
create - icon
crossborder - icon
dashboard - icon
deposit - icon
depositWithdraw - icon
dragAndDrop - icon
editMessage - icon
education - icon
empty - icon
entertainment - icon
envelope - icon
envelopeMail - icon
error - icon
exclude - icon
exportPDF - icon
externalLink - icon
family - icon
familyHousehold - icon
favorite - icon
fill - icon
financialServices - icon
flashOff - icon
flashOn - icon
food - icon
foodDrink - icon
forecast - icon
friendsAndFamily - icon
grid - icon
groceries - icon
health - icon
hideChat - icon
hobbies - icon
hobbiesInterests - icon
home - icon
homeHouse - icon
house - icon
household - icon
income - icon
incomeDollar - icon
incomeCoins - icon
incomeKr - icon
internationalCheck - icon
internetCall - icon
internetBank - icon
investmentSavings - icon
leisure - icon
lightbulb - icon
list - icon
liquidityThreshold - icon
logout - icon
loans - icon
location - icon
login - icon
manageFinances - icon
massMessage - icon
maximise - icon
menu - icon
mic - icon
micNot - icon
minimise - icon
mobileBank - icon
mobilePayment - icon
moneyOrder - icon
moreVert - icon
mysteryCategory - icon
nova - icon
nextProductCard - icon
noConnection - icon
notepad - icon
ok - icon
openInNewWindow - icon
outgoingPayment - icon
owners - icon
pay - icon
payments - icon
pdf - icon
pension - icon
percentage - icon
personalInformation - icon
phoneCall - icon
phoneHandle - icon
portfolio - icon
previousProductCard - icon
print - icon
priorityPass - icon
products - icon
profile - icon
raise - icon
ratingStar - icon
ratingStarFull - icon
reoccurring - icon
reply - icon
receipt - icon
receiptKr - icon
receivedMessage - icon
remove - icon
reservedTransaction - icon
sendMessage - icon
savings - icon
savingsInvestments - icon
scanInvoice - icon
search - icon
sell - icon
settingsCog - icon
setupComplete - icon
shopping - icon
shoppingServices - icon
smartPhone - icon
smileyExtatic - icon
smileyExtaticFull - icon
smileyHappy - icon
smileyHappyFull - icon
smileyNeutral - icon
smileyNeutralFull - icon
smileySad - icon
smileySadFull - icon
smileyVerySad - icon
smileyVerySadFull - icon
subscriptions - icon
summerhomeBoat - icon
swap - icon
switchoff - icon
tags - icon
tickMark - icon
tickMarkBig - icon
toggle - icon
tools - icon
tooltip - icon
transfer - icon
trashcan - icon
travelVacation - icon
tvPhoneInternet - icon
uncategorized - icon
upload - icon
videoCall - icon
videoCallNot - icon
visa - icon
withdraw - icon


Markup: <svg class="icon" focusable="false"> <use xlink:href="/build/icons/icon-pack.svg&#x23;{$modifiers}"></use> </svg> {$modifiers}

Styleguide 1.5.4

*/
/*
List of small icons

alert_sml - icon
arrow_down_sml - icon
arrow_left_sml - icon
arrow_right_sml - icon
arrow_up_sml - icon
close_x_sml - icon
error_sml - icon
fullArrowDown_sml - icon
fullArrowLeft_sml - icon
fullArrowRight_sml - icon
fullArrowUp_sml - icon
info_sml - icon
plus_sml - icon

Markup: <svg class="icon icon--small" focusable="false"> <use xlink:href="/build/icons/icon-pack.svg&#x23;{$modifiers}"></use> </svg> {$modifiers}

Styleguide 1.5.5

*/
.icon {
  box-sizing: content-box;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: #0000a0;
  fill: none; }
  .icon, .icon * {
    stroke: currentColor; }
  .icon use {
    pointer-events: none; }

.icon, .icon svg {
  height: 2rem;
  width: 2rem; }

.icon--small, .icon--small svg {
  width: 1rem;
  height: 1rem; }

.icon--smallish, .icon--smallish svg {
  width: 1.5rem;
  height: 1.5rem; }

.icon--medium, .icon--medium svg {
  width: 2rem;
  height: 2rem; }

.icon--large, .icon--large svg {
  width: 4rem;
  height: 4rem; }

.icon--medium-large, .icon--medium-large svg {
  width: 3rem;
  height: 3rem; }

.icon--xlarge, .icon--xlarge svg {
  width: 8rem;
  height: 8rem; }

.flyout-icon--css-fallback {
  display: block;
  margin: auto;
  height: 1rem;
  width: 1rem;
  background-image: radial-gradient(circle at center, #0000a0 0.13rem, transparent 0.13rem);
  background-size: 0.25rem 0.4rem;
  background-repeat: repeat-y;
  background-position: center;
  vertical-align: middle; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-Light.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-Light.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-Light.woff") format("woff"), url("fonts/NordeaSansSmallWeb-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-LightItalic.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-LightItalic.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-LightItalic.woff") format("woff"), url("fonts/NordeaSansSmallWeb-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-Regular.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-Regular.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-Regular.woff") format("woff"), url("fonts/NordeaSansSmallWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-Medium.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-Medium.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-Medium.woff") format("woff"), url("fonts/NordeaSansSmallWeb-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-MediumItalic.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-MediumItalic.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-MediumItalic.woff") format("woff"), url("fonts/NordeaSansSmallWeb-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-Bold.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-Bold.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-Bold.woff") format("woff"), url("fonts/NordeaSansSmallWeb-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-BoldItalic.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-BoldItalic.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-BoldItalic.woff") format("woff"), url("fonts/NordeaSansSmallWeb-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "Nordea Sans";
  src: url("fonts/NordeaSansSmallWeb-Black.eot");
  src: local("☺"), url("fonts/NordeaSansSmallWeb-Black.woff2") format("woff2"), url("fonts/NordeaSansSmallWeb-Black.woff") format("woff"), url("fonts/NordeaSansSmallWeb-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans Large";
  src: url("fonts/NordeaSansLarge-Regular.eot");
  src: local("☺"), url("fonts/NordeaSansLarge-Regular.woff2") format("woff2"), url("fonts/NordeaSansLarge-Regular.woff") format("woff"), url("fonts/NordeaSansLarge-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans Large";
  src: url("fonts/NordeaSansLarge-Medium.eot");
  src: local("☺"), url("fonts/NordeaSansLarge-Medium.woff2") format("woff2"), url("fonts/NordeaSansLarge-Medium.woff") format("woff"), url("fonts/NordeaSansLarge-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans Large";
  src: url("fonts/NordeaSansLarge-Bold.eot");
  src: local("☺"), url("fonts/NordeaSansLarge-Bold.woff2") format("woff2"), url("fonts/NordeaSansLarge-Bold.woff") format("woff"), url("fonts/NordeaSansLarge-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Nordea Sans Large";
  src: url("fonts/NordeaSansLarge-Black.eot");
  src: local("☺"), url("fonts/NordeaSansLarge-Black.woff2") format("woff2"), url("fonts/NordeaSansLarge-Black.woff") format("woff"), url("fonts/NordeaSansLarge-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

/**
Typography

The default font is Nordea Sans Small Web. It has font faces for the `font-style` and `font-weight` combinations shown in the examples below.

Note that you do not have to use the classes to get the font face (although you can) -- a font face kicks based on the combination of `font-style` and `font-weight`.

.font - Default font
.font.font--light - Light font
.font.font--light-italic - Light italic font
.font.font--medium - Medium font
.font.font--medium-italic - Medium italic font
.font.font--bold - Bold font
.font.font--bold-italic - Bold italic font
.font.font--black - Black

Markup:
<span class="{$modifiers}">The quick brown fox jumps over the lazy dog</span>

Styleguide 1.4
*/
.font {
  font-weight: normal;
  font-style: normal; }
  .font--light, .font--light-italic {
    font-weight: 300; }
  .font--italic, .font--light-italic, .font--medium-italic, .font--bold-italic {
    font-style: italic; }
  .font--medium, .font--medium-italic {
    font-weight: 500; }
  .font--bold, .font--bold-italic {
    font-weight: bold; }
  .font--black {
    font-weight: 900; }

/**
Headings

Heading tags to more than style text, they are critical to screen readers for accessibility, so make sure to use them correctly. Assistive technologies and other browsers rely upon the literal markup of the page to determine structure. Items that are bolded or display in a bigger font are not interpreted to be structural elements.

- Headings should be used to define the hierarchical structure of the content on a page and not for anything else.
- Do not use text formatting, such as font size or bold to give the visual appearance of headings - use actual heading (```<h1> - <h6>```) for all content headings.
- Use headings for headings only. Do not use headers to achieve visual results only on text that does not define the structure of the page.
- The page title should be an ```<h1>``` heading. You can use the ```.invisible```, ```.text--hidden``` or ```.text--squished``` classes to hide the title if necessary, but every page must have one. Additionally every page should only have one ```<h1>``` heading. Do not use this tag for text that is not actually the title of the page.
- Subsections under this should be ```<h2>``` headings.
- The ```.heading-style--h1``` classes can be used to emulate the appearance of heading tags on other elements, in cases where it is inappropriate to use an actual heading tag but the style should look the same.

h1 - Page headlines only.

- Each page should have a clear, short h1 headline copy that will describe the purpose of the page, e.g. "Financial contacts".
- h1: is specified separately as largest headline style 1, that is set 2.0 rem in terms of size. Color is always $black.
- Clear space top: h1 is located 3.0 rem under the navigation bar level 2
- Clear space bottom: 1.647 rem until other elements appear.

h2 - Page subheading.

- Use h2 as titles for the sub-features or panels. This element can be used in more versatile manner.
- h2: is specified separately as basic heading, that is set 1.2x5 rem in terms of size. Color is always $black.
- Clear space top: at least 3.0 rem clear space on top.
- Clear space bottom: at least 2.0 rem clear space at bottom.

h3 - Panel heading.

- Use h3 as headings within panels.
- Clear space top: at least 2.0 rem of clear space on top
- Clear space top: at least 2.0 rem of clear space at bottom

Markup:
<h1>Heading 1 - For page titles only</h1>
<h2>Heading 2 - Second level heading</h2>
<h3>Heading 3 - Third level navigation</h3>
<h4>Heading 4 - Captions</h4>
<div class="heading-style--h1">.heading-style--h1 - h1 heading style text</div>
<div class="heading-style--h2">.heading-style--h2 - h2 heading style text</div>
<div class="heading-style--h3">.heading-style--h3 - h3 heading style text</div>
<div class="heading-style--h4">.heading-style--h1 - h4 heading style text</div>

Styleguide 1.4.1
*/
h1, .heading-style--h1 {
  color: #ffffff;
  margin-bottom: 1.125rem;
  font-family: 'Nordea Sans Large';
  font-size: 2.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 768px) {
    h1, .heading-style--h1 {
      font-size: 1.125rem;
      font-weight: 500;
      margin-top: 1.5rem;
      padding-right: 1rem; } }

h2, .heading-style--h2 {
  font-size: 1.5rem; }

h3, .heading-style--h3 {
  font-size: 1.25rem; }

h4, .heading-style--h4 {
  text-transform: uppercase;
  font-size: 1rem;
  color: #000000; }

/**
Paragraph margins

You can adjust paragraphs and headers to have smaller margins for short pieces of text by wrapping them in a container with ```text-short``` class.

.text--long    - normal paragraph, default
.text--short   - Short text paragraph

Markup:
<div class="{$modifiers}">
<h2>Second level heading</h2>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non orci sit amet libero mattis hendrerit ut ut felis. Lectus turpis, vitae molestie est feugiat laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non orci sit amet libero mattis hendrerit ut ut felis. Lectus turpis, vitae molestie est feugiat laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.</p>
</div>

Styleguide 1.4.2
*/
.text--short h1, .text--short .heading-style--h1,
.text--short h2,
.text--short .heading-style--h2,
.text--short h3,
.text--short .heading-style--h3,
.text--short h4,
.text--short .heading-style--h4,
.text--short h5,
.text--short h6 {
  margin-bottom: 1rem; }

.text--short p,
.text--short blockquote {
  margin-bottom: 2rem; }

/**
Text sizes and colors

You can adjust text to be larger or smaller than normal text or give it a color based on a semantic class.

.text          - normal text, default
.text--large   - large text
.text--small   - small text
.text--smallcaps - small, capitalized text
.text--success - success text color
.text--error   - error text color
.text--caption - caption text color ($nordea-x-dark-grey)
.text--small.text--error  - combine the modifiers to get e.g. small error text

Markup:
<p class="{$modifiers}">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non orci sit amet libero mattis hendrerit ut ut felis. Lectus turpis, vitae molestie est feugiat laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.</p>

Styleguide 1.4.3
*/
.text--large, .text-large {
  font-size: 1.25rem;
  line-height: 1.4; }

.text--small, .text-small, .link--small, .link-small {
  font-size: 0.8125rem; }

.text--success, .text-success {
  color: #006060; }

.text--error, .text-error {
  color: #b00000; }

.text--caption {
  color: #474748; }

.text--smallcaps, .text-smallcaps {
  font-size: 0.8125rem;
  text-transform: uppercase; }

.text--capitalize, .text-capitalize {
  text-transform: capitalize; }

.text--uppercase, .text-uppercase {
  text-transform: uppercase; }

.text--lowercase, .text-lowercase {
  text-transform: lowercase; }

.text--transform-none, .text-transform-none {
  text-transform: none; }

/**
Text visibility classes

Please see section [Layout > Visibility](#/section/3.3) for helper visibility classes, useful for accessibility navigation skip-links, etc.

Markup: -

Styleguide 1.4.4
*/
.text--hidden {
  display: block;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left; }

.text--squished {
  display: inline-block;
  font: 0/0 serif;
  text-shadow: none;
  color: transparent; }

/**
Links

Anchor elements have the `.link` style by default. Use the `.link` class when you need to style a clickable element as a link. In the latter case, if the element is not natively clickable, add the aria attribute `role="button"` and a `tabindex` attribute.

.link                - Link, default style of `<a>` elements
.link.link--external - Link, default style of `<a rel="external">` elements. It should be clear that `<a rel="external"></a>` is always preferred over a non link with this class. For the sake of <a href="https://www.vincit.fi/en/blog/software-development-450-words-per-minute/" rel="external" target="_blank" title="This person would really appreciate proper links">some our users</a>, we should always strive for that.
.link.link--small    - Small text link
.link:hover          - Link hover state

Markup:
<a href="http://www.wikipedia.org/wiki/Lorem_ipsum" class="{$modifiers}">Anchor</a>
<span role="button" tabindex="0" class="{$modifiers}">Span</span>
<button type="button" class="{$modifiers}">Button</button>

Styleguide 1.4.5
*/
a[rel~="external"]:after, [rel~="external"].link:after,
.link--external:after {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 0.2em;
  content: ' ';
  background-image: url("svg/externalLink.svg"); }

.colors-negative a, .colors-negative .link, .colors-negative .link {
  color: #e5f2ff; }
  .colors-negative a:hover, .colors-negative .link:hover, .colors-negative a:focus, .colors-negative .link:focus, .colors-negative .link:hover, .colors-negative .link:focus {
    color: #e5f2ff; }

/**
Text alignment

You can change the text alignment of an element by adding <code>.text-left</code>, <code>.text-right</code>, <code>.text-center</code> or <code>.text-justify</code> to an element.

.text--left    - left-aligned text, default
.text--right   - right-aligned text
.text--center  - centered text
.text--justify - justified text

Markup: <p class="{$modifiers}">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non orci sit amet libero mattis hendrerit ut ut felis. Lectus turpis, vitae molestie est feugiat laoreet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae.</p>

Styleguide 1.4.6
*/
/**
Numbers

Numbers are monospaced in Nordea Sans font.

Markup:
<table class="text--right">
  <tr>
      <td>123,45</td>
  </tr>
  <tr>
      <td>123 456,78</td>
  </tr>
  <tr>
      <td>123 456 789,00</td>
  </tr>
</table>

Styleguide 1.4.7
*/
/*
Negative colors

<p>
Class <code>colors-negative</code> is a global class that converts elements within a container to have
colors that are better visible against a dark background. The class does not itself change the background color
because there is not a single dark background color.
</p>
<p>
Below is an example text elements with negative colors. Buttons, inputs etc. with negative colors are documented in other sections.
</p>

.colors-negative - Sets negative colors to child elements inside a container

Markup: _typography-negative.html

sg-wrapper:
<div class="styleguide__colors-negative">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
.colors-negative {
  color: #ffffff; }
  .colors-negative h1, .colors-negative .heading-style--h1,
  .colors-negative h2,
  .colors-negative .heading-style--h2,
  .colors-negative h3,
  .colors-negative .heading-style--h3 {
    color: #ffffff; }
  .colors-negative h4, .colors-negative .heading-style--h4,
  .colors-negative h5,
  .colors-negative h6 {
    color: #ffffff; }
  .colors-negative a, .colors-negative .link {
    color: #e5f2ff; }
  .colors-negative .icon,
  .colors-negative .close-button {
    color: #f0c1ae; }
  .colors-negative .text-success, .colors-negative .text--success, .colors-negative .text-success {
    color: #40bfa3; }
  .colors-negative .text-error, .colors-negative .text--error, .colors-negative .text-error {
    color: #fc6161; }

@media print {
  #main {
    overflow: visible;
    position: static !important; }
  #main > navigation,
  #main > nav-footer {
    display: none !important; }
  #main > #main-content {
    margin-bottom: 0; }
  help-box,
  sso-component,
  .button:not(.button--tertiary) {
    display: none !important; }
  .panel {
    padding: 0 !important; }
  h1, .heading-style--h1 {
    margin-bottom: 0.5rem !important; }
  body .row > .large-8,
  body .row > .large-4 {
    -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
    max-width: 100% !important; } }

/**
Layout

Styleguide 3
*/
/**
Fluid container

Sets minimum and maximum width of the content as 980px and 1680px and adds padding on the sides.

Use the `.fluid-container` nested inside a <a href="#/section/3.2.1">.fluid-container-background</a> element. This ensures that all the `.fluid-container` elements on the page get the same padding and align nicely in all viewport sizes.

Do not use multiple fluid-container classes in a nested way. This class is meant to be used on a high-level container, e.g. all application content, or main content and navigation bar separately.

This example best viewed in full screen mode. Try also resizing the browser window until a horizontal scrollbar appears.

.fluid-container - A container with minimum and maximum width plus padding

Markup:
<div class="fluid-container-background">
  <div class="fluid-container">
    <div class="callout callout--success">fluid-container in a .fluid-container-background</div>
  </div>
</div>
<div class="fluid-container-background">
  <div class="fluid-container">
    <div class="callout callout--info">.fluid-container in a .fluid-container-background</div>
  </div>
</div>
<div class="fluid-container">
  <div class="callout callout--warning">.fluid-container without a .fluid-container-background</div>
</div>

Styleguide 3.2
*/
/**
Fluid container background

This is a wrapper meant to be used with the <code>.fluid-container</code> class. It creates an element which has a minimum width so that it fits in the smallest viewport we support without a horizontal scrollbar, taking the space taken by a vertical scrollbar into account. It has no maximum width, so it can also be used when setting a background color spanning the entire page width (including scrollable area). This class itself does not change the background color.

Note that there is also a mixin <a href="#/section/4.2.10">fluid-container-background</a> for creating your own fluid container background class.

This example best viewed in full screen mode. Try also resizing the browser window to see how the container behaves when the vertical and/or the horizontal scrollbar appears.

.fluid-container-background - Helper class for wrapping .fluid-container

Markup: _fluid-container-background.html

Styleguide 3.2.1
*/
/**
Grid overlay()

Use <code>.debug</code> on any <code>.row</code> element to display a column overlay as a helper.

4.2.1
*/
/*
Panel layout style

The panel style, similar to a card, defines layout and padding for a block of content and optionally a header. Note that any direct children of the last child of the panel will have margin stripped, so if you have a row of buttons or other elements that include bottom margin placed inline at the bottom of the panel, make sure they are wrapped inside a single `<div>`, `<p>`, or other block element. In the same sense, if you have multiple rows of inline elements at the bottom of the panel, make sure each row is wrapped in a separate `<div>` or other block element to preserve the bottom margin of the second to last row. See 3.6.5 for how not to do it.

Styleguide 3.6
*/
/*
Panel layout style detailed example

Markup:
<p>Lorem dizzle dolizzle sit amizzle, mammasay mammasa mamma oo sa adipiscing crunk. Nullizzle sapizzle velit, owned volutpat, suscipit sure, for sure vizzle, arcu. Pellentesque egizzle tortor. Sed erizzle. Crazy dope dope dapibus turpizzle tempizzle tempor. Mauris shizznit nibh et turpizzle. Vestibulum izzle tortizzle.</p>
<div class="panel">
    <div class="panel__header"><h2>h2 - Panel header</h2></div>
    <div class="panel__content">
      <p>Pellentesque egizzle tortor. Sed erizzle. Crazy dope dope dapibus turpizzle tempizzle tempor. Mauris shizznit nibh et turpizzle. Vestibulum izzle tortizzle. Pellentesque izzle rhoncizzle break yo neck, yall. In hac cool platea dictumst. Ass dapibizzle. </p>
      <p class="button-row text--right">
          <button type="button" class="button button--secondary">Cancel</button>
          <button type="button" class="button button--primary">Confirm</button>
      </p>
      <p>Break it down sapien nulla, iaculizzle dope, molestie sizzle, gangsta izzle, erizzle. Fo shizzle vitae turpis that's the shizzle nibh bibendizzle i saw beyonces tizzles and my pizzle went crizzle. Away dizzle consectetizzle the bizzle. Aliquam erat volutpizzle. Bizzle ut leo izzle lectus break it down faucibus. Crizzle stuff lacizzle brizzle dui condimentum ultricies. Get down get down nisl. Dizzle izzle urna. Integizzle ma nizzle ipsizzle we gonna chung mi. Donec izzle turpis.</p>
      <p class="button-row text--right">
          <button type="button" class="button button--secondary">Cancel</button>
          <button type="button" class="button button--primary">Confirm</button>
      </p>
    </div>
</div>
<p>Vestibulum izzle tortizzle. Pellentesque izzle rhoncizzle break yo neck, yall. In hac cool platea dictumst. Ass dapibizzle. Own yo' tellus urna, pretizzle funky fresh, mattizzle ac, eleifend vitae, nunc. Bizzle suscipizzle. Integizzle nizzle ass sizzle sizzle.</p>

Styleguide 3.6.1
*/
/*
Simple panel layout style

If you have multiple elements, it is safer to use `.panel__header` and `.panel__content` wrappers inside the panel, however if you have a very simple panel with only block elements, it is also possible to use only a `.panel` wrapper.

Markup:
<div class="panel">
  <p>Lorem dizzle dolizzle sit amizzle, mammasay mammasa mamma oo sa adipiscing crunk. Nullizzle sapizzle velit, owned volutpat, suscipit sure, for sure vizzle, arcu. Pellentesque egizzle tortor. Sed erizzle.</p>
</div>

Styleguide 3.6.2
*/
/*
Panel layout style with simplified markup

Not quite as simple as the previous example, but still uses simple block elements and no header, so `.panel__header` and `.panel__content` are not necessary.

Markup:
<div class="panel">
      <p>Lorem dizzle dolizzle sit amizzle, mammasay mammasa mamma oo sa adipiscing crunk. Nullizzle sapizzle velit, owned volutpat, suscipit sure, for sure vizzle, arcu. Pellentesque egizzle tortor. Sed erizzle.</p>
      <p class="button-row text--right">
          <button type="button" class="button button--secondary">Cancel</button>
          <button type="button" class="button button--primary">Confirm</button>
      </p>
      <p>Fo shizzle mah nizzle fo rizzle, mah home g-dizzle yo urna ut nisl. Sed quis arcu. Bizzle stuff, ipsizzle malesuada malesuada shizzle my nizzle crocodizzle, funky fresh purizzle break yo neck, yall funky fresh, fizzle shut the shizzle up ghetto nulla izzle sizzle. Vivamizzle ullamcorpizzle, tortor ma nizzle varizzle bizzle, nibh nunc ultricizzle turpis, in dawg leo elit tellivizzle dawg.</p>
      <p class="button-row text--right">
          <button type="button" class="button button--secondary">Cancel</button>
          <button type="button" class="button button--primary">Confirm</button>
      </p>
</div>

Styleguide 3.6.3
*/
/*
Panel layout style with horizontal rule

Use `<hr />` to create a horizontal rule inside a panel.

Markup:
<div class="panel">
  <div class="panel__header">
    <h2>Panel header</h2>
  </div>
  <div class="panel__content">
    Content
    <hr />
    More content
  </div>
</div>

Styleguide 3.6.4
*/
/*
Panel layout style, poor man's header

Here, we have put the `.panel__header` class directly on the `<h2>` element as it is simple enough that wrapper classes are not needed.

Markup:
<div class="panel">
    <h2 class="panel__header">h2 - Panel header</h2>
    <div class="panel__content">
      <p>Fo shizzle mah nizzle fo rizzle, mah home g-dizzle yo urna ut nisl. Sed quis arcu. Bizzle stuff, ipsizzle malesuada malesuada shizzle my nizzle crocodizzle, funky fresh purizzle break yo neck, yall funky fresh, fizzle shut the shizzle up ghetto nulla izzle sizzle. Vivamizzle ullamcorpizzle, tortor ma nizzle varizzle bizzle, nibh nunc ultricizzle turpis, in dawg leo elit tellivizzle dawg. Mauris we gonna chung, orci vizzle shizzlin dizzle yo, sem augue luctizzle shiz, at things enim fo shizzle izzle for sure.</p>
      <p class="button-row text--right">
          <button type="button" class="button button--secondary">Cancel</button>
          <button type="button" class="button button--primary">Confirm</button>
      </p>
    </div>
</div>

Styleguide 3.6.5
*/
/*
Panel layout style with incorrect markup structure

Markup:
<div class="panel">
    <h2 class="panel__header">h2 - Panel header</h2>
    <div class="panel__content"><p>This is incorrect markup structure because the two inline buttons at the bottom are not wrapped together inside a parent element.
      <button type="button" class="button button--secondary">Cancel</button>
      <button type="button" class="button button--primary">Confirm</button>
    </div>
  </div>
</div>

Styleguide 3.6.6
*/
/*
Table within panel

Section 10 of the Styleguide contains code snippets that are used to insert into other styleguide sections, but by not including the Styleguide 10 section, these 10.n sections are visually hidden from the generated styleguide output since they are not useful on their own. If changing these secion numbers, take extra care to update everywhere they are used, also.

Markup:
<table>
  <thead>
    <tr class="expand-row__preview--header text--smallcaps">
      <th>Header col 1</th>
      <th>Header col 2</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>td col 1</td>
      <td>td col 2</td>
    </tr>
    <tr>
      <td>td col 1</td>
      <td>td col 2</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td>Footer col 1</td>
      <td>Footer col 2</td>
    </tr>
  </tfoot>
</table>

Styleguide 10.1
*/
/*
Panel layout with settings cog

Markup:
<div class="panel">
    <div class="panel__header">
      <h2>h2 - Panel header</h2>
<svg class="icon" focusable="false">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/build/icons/icon-pack.svg#settingsCog"></use>
</svg>
    </div>
    <div class="panel__content">
      <sg-insert>10.1</sg-insert>
    </div>
</div>

Styleguide 3.6.7
*/
/*
Panel layout with button (tertiary)

Markup:
<div class="panel">
    <div class="panel__header"><h2 class="">Messages</h2><button type="button" class="button button--tertiary">See all</button></div>
    <div class="panel__content">
      <sg-insert>10.1</sg-insert>
    </div>
</div>

Styleguide 3.6.8
*/
.fluid-container {
  box-sizing: content-box;
  max-width: 76.5rem;
  padding: 0 0.75rem;
  margin: 0 auto; }

.fluid-container > * {
  box-sizing: border-box; }

.fluid-container-background {
  min-width: 62.9375rem;
  width: 100%; }

.row.debug .column, .row.debug .columns,
.row.debug .columns {
  position: relative; }
  .row.debug .column:before, .row.debug .columns:before,
  .row.debug .columns:before {
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.05);
    height: 100%;
    box-shadow: 0.75rem 0 0 rgba(255, 255, 255, 0.25) inset, -0.75rem 0 0 rgba(255, 255, 255, 0.25) inset;
    left: 0;
    pointer-events: none; }

.group {
  display: -ms-flexbox;
  display: flex; }
  .group > * {
    margin: 0; }

.group--vertical {
  -ms-flex-direction: column;
      flex-direction: column; }
  .group--vertical > * + * {
    margin-top: 0.75rem !important; }

.group--horizontal {
  -ms-flex-direction: row;
      flex-direction: row; }
  .group--horizontal > * + * {
    margin-left: 0.75rem !important; }

.panel {
  padding: 1rem;
  margin: 0 0 0.75rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06); }
  @media (min-width: 768px) {
    .panel {
      padding: 1.3rem 1.5rem 2rem 1.5rem;
      margin: 0 0 1.5rem 0; } }
  .panel__header {
    margin: 0;
    padding-bottom: 0.5rem; }
    @media (min-width: 540px) {
      .panel__header {
        padding-bottom: 0.85rem; } }
    .panel__header h1, .panel__header .heading-style--h1 {
      font-size: 1.25rem; }
      @media (min-width: 540px) {
        .panel__header h1, .panel__header .heading-style--h1 {
          font-size: 2rem; } }
    .panel__header h2, .panel__header .heading-style--h2 {
      font-size: 1rem; }
      @media (min-width: 540px) {
        .panel__header h2, .panel__header .heading-style--h2 {
          font-size: 1.5rem; } }
    .panel__header > * {
      margin: 0; }
  .panel__content {
    margin: 0; }
    .panel__content > *:first-child:not(hr) {
      margin-top: 0; }
    .panel__content > *:last-child:not(hr) {
      margin-bottom: 0; }
      .panel__content > *:last-child:not(hr) > * {
        margin-bottom: 0; }
  .panel hr {
    border-color: #e6e4e3;
    margin: 1rem -1rem; }
    @media (min-width: 768px) {
      .panel hr {
        margin: 1.5rem -1.5rem; } }
  .panel > *:first-child {
    margin-top: 0; }
  .panel > *:last-child {
    margin-bottom: 0; }
    .panel > *:last-child:not(.panel__content) > * {
      margin-bottom: 0; }
  .panel__header {
    position: relative; }
    .panel__header > .button,
    .panel__header > .icon {
      position: absolute;
      right: 0;
      top: 0; }

/**
Components

Styleguide 2
*/
/**
Labels

Below are examples of how to use labels. Use ```.label--secondary``` for smaller, secondary text or table headers, optionally in conjunction with ```.text--smallcaps``` to make it easier to read and create typographic contrast between different text styles. Secondary labels have $x-dark-gray color by default.

.label                  - Default label
.label.label--primary   - Primary label
.label.label--secondary - Secondary label
.label.label--secondary.text--smallcaps - Secondary label, capitalized
.label.label--xlarge    - Extra large label

Markup: <span class="{$modifiers}">.{$modifiers} text label</span>

Styleguide 2.8
*/
/**
Amount

Amount labels are created using <span> elements to separate the parts of the integers and decimals.

.amount                   - Default amount label
.amount.amount--primary   - Primary amount label
.amount.amount--secondary - Secondary amount label
.amount.amount--bold      - Amount with the same font size but bold
.amount.amount--plain     - Amount with the same font style as in its container
.amount.positive          - Positive amount label
.amount.negative          - Negative amount label

Markup:
<span class="{$modifiers}" aria-label="1234.56">
  <span class="amount__integers" aria-hidden="true">1234</span><span class="amount__decimal-symbol" aria-hidden="true">,</span><span class="amount__decimals" aria-hidden="true">56</span>
</span>

Styleguide 2.9
*/
/**
Currency amount

Add the currency symbol in a `.amount__decimals` element as shown in the markup example.

.amount                   - Default amount label
.amount.amount--primary   - Primary amount label
.amount.amount--secondary - Secondary amount label
.amount.amount--bold      - Amount with the same font size but bold
.amount.amount--plain     - Amount with the same font style as in its container
.amount.positive          - Positive amount label
.amount.negative          - Negative amount label

Markup:
<span class="{$modifiers}" aria-label="0.10 USD">
  <span class="amount__integers" aria-hidden="true">0</span><span class="amount__decimal-symbol" aria-hidden="true">,</span><span class="amount__decimals" aria-hidden="true">10</span><span class="amount__currency" aria-hidden="true">&nbsp;USD</span>
</span>

Styleguide 2.9.1
*/
/*
Amount number spacing

Amount style is based on Nordea Sans in which numbers are rendered monospaced.

.dummy  - No style
.amount - Amount

Markup: _table-of-numbers.html

Styleguide 2.9.2
*/
/**
Dividers

Dividers between chunks of content. Elements such as labels and buttons that are displayed with dividers do not take any additional space of their own or change the dimensions or margins of the divider, they are simply overlaid around the divider. Block modifier classnames for these elements specified in examples below do not do anything by themselves, they are merely to identify divider blocks that contain additional elements.

Styleguide 2.10
*/
/**
Simple divider

.divider - Simple divider line

Markup: <div class="{$modifiers}"></div>

Styleguide 2.10.1
*/
/*
 Badges

 The badge is a basic component that displays a number. It's useful for calling out a number of unread items.
 See also <a href="http://foundation.zurb.com/sites/docs/badge.html">Foundation for Sites badge</a>.

 .badge - Default badge
 .badge.badge--primary - Primary badge
 .badge.badge--secondary - Secondary badge
 .badge.badge--info - Info badge
 .badge.badge--success - Success badge
 .badge.badge--warning - Warning badge
 .badge.badge--alert - Alert badge

 Markup:
 <span class="{$modifiers}">1 <span class="show-for-sr">unread messages</span></span>

 Styleguide 2.11
*/
/*
Callouts (notifications)

This section describes different types callouts, also known as notifications. They are based on the <a href="http://foundation.zurb.com/sites/docs/callout.html">Foundation for Sites callout</a> style.

For accessibility, you may need to set the appropriate role on the callout element:

* `role="alert"`: When the callout appears dynamically (e.g. error message), or it is static but contains an alert the user should be aware of.
* `role="alertdialog"`: Like `role="alert"` but additionally the callout contains control elements (like navigation links).
* No role: When the callout static page content and it is not something that the user needs to be especially aware of (the callout is for decorational purposes).

With `role="alert"` or `role="alertdialog"` a screen reader reads the content to the user when entering the page or when the callout appears.

Styleguide 2.7
*/
.callout--contextual {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px 0 rgba(0, 0, 0, 0.12); }

/*
Basic callout

This is a basic callout. The modifier styles are available for all types of callouts.

.callout - No background color
.callout.callout--primary - Primary
.callout.callout--secondary - Secondary
.callout.callout--info - Info
.callout.callout--success - Success
.callout.callout--warning - Warning
.callout.callout--alert - Alert

Markup:
<div class="{$modifiers}">This is a basic callout</div>

Styleguide 2.7.1
*/
/*
Closeable callout

The closeable callout uses <a href="#/section/2.4">close button</a>.

Markup:
<div class="callout callout--success" data-closable>
  This is a closeable callout
  <button class="close-button" aria-label="Close alert" type="button" data-close>
    <span aria-hidden="true">&times;</span>
  </button>
</div>

Styleguide 2.7.1.1
*/
/*
Contextual notifications

Contextual notifications are meant to be used within the main content, close their context. Note the use of Foundation grid classes in the markup.

Only the following colors are approved by UI designers for contextual notifications.

.callout.callout--contextual.callout--error - Error
.callout.callout--contextual.callout--warning - Warning
.callout.callout--contextual.callout--success - Success

Markup:
<div class="{$modifiers}" role="alert">
    <div class="row align-middle">
        <div class="columns callout__content">
          <strong>{$modifiers}:</strong> Notification text
        </div>
    </div>
</div>

Styleguide 2.7.2
*/
/*
Contextual notifications with icon and flat (secondary) button

Contextual callouts with icon and button are created using Foundation grid classes. The button and icon should be in their own columns as specified in the markup below, use the <code>.button--secondary</code> style.

.callout.callout--contextual.callout--error - Error
.callout.callout--contextual.callout--warning - Warning
.callout.callout--contextual.callout--success - Success

Markup:
<div class="{$modifiers}" role="alertdialog">
    <div class="row align-middle">
        <div class="columns shrink callout__icon">
            <svg class="icon icon--medium"><use xlink:href="/build/icons/icon-pack.svg#ok"></use></svg>
        </div>
        <div class="columns callout__content">
            <strong>{$modifiers}:</strong> Notification text
        </div>
        <div class="columns shrink callout__button">
            <button type="button" class="button button--secondary">
                .button--secondary
            </button>
        </div>
    </div>
</div>

Styleguide 2.7.2.1
*/
/*
Contextual notifications with icon and close button

Contextual callouts with icon and/or close button can be created using Foundation grid classes. The icon and close button should be in their own column as specified in the markup below.

.callout.callout--contextual.callout--error - Error
.callout.callout--contextual.callout--warning - Warning
.callout.callout--contextual.callout--success - Success

Markup:
<div class="{$modifiers}" role="alertdialog" data-closable>
    <div class="row align-middle">
        <div class="columns shrink callout__icon">
            <svg class="icon icon--medium"><use xlink:href="/build/icons/icon-pack.svg#ok"></use></svg>
        </div>
        <div class="columns callout__content">
            <strong>{$modifiers}:</strong> Notification text on two lines<br/>This is the second line.
        </div>
        <div class="columns shrink callout__button">
            <button type="button" class="close-button" aria-label="Close" data-close>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

Styleguide 2.7.2.2
*/
/*
Top notifications

Top notifications are meant to be displayed at the top of the page and used for important notifications and notifications for which there is no context on the page.

Using Foundation grid classes, make sure to reserve 1 column on both left and right sides for navigation icons, <em>regardless of whether or not navigation icons are actually present</em>. (See below)

Only the following colors are approved by UI designers for contextual notifications.

.callout.callout--top.callout--error - Error
.callout.callout--top.callout--warning - Warning
.callout.callout--top.callout--success - Success
.callout.callout--top.callout--info - Info

Markup:
<div class="{$modifiers}" role="alert">
    <div class="row align-middle">
        <div class="small-1 columns callout__nav callout__nav--left">1 col</div>
        <div class="columns callout__content"><strong>{$modifiers}:</strong> Notification text</div>
        <div class="small-1 columns callout__button callout__nav callout__nav--right">1 col</div>
    </div>
</div>

Styleguide 2.7.3
*/
/*
Top notifications with navigation, icon, close button and/or tertiary button

Top callouts with navigation, icon, button and/or close button can be created using Foundation grid classes. Each of these 3 element types should be in it's own column as specified in the markup below. If using a button, use the <code>.button--tertiary</code> style.

.callout.callout--top.callout--error - Error
.callout.callout--top.callout--warning - Warning
.callout.callout--top.callout--success - Success
.callout.callout--top.callout--info - Info

Markup:
<div class="{$modifiers}" role="alertdialog" data-closable>
    <div class="row align-middle">
        <div class="columns small-1 callout__nav callout__nav--left">
          <svg class="icon icon--medium"><use xlink:href="/build/icons/icon-pack.svg#summerhomeBoat"></use></svg>
        </div>
        <div class="columns shrink callout__icon">
            <svg class="icon icon--medium"><use xlink:href="/build/icons/icon-pack.svg#ok"></use></svg>
        </div>
        <div class="columns callout__content">
            <strong>{$modifiers}:</strong> Notification text
        </div>
        <div class="columns shrink callout__button">
            <button type="button" class="button button--tertiary">
                .button--tertiary
            </button>
        </div>
        <div class="small-1 columns callout__button  callout__nav callout__nav--right">
            <button type="button" class="close-button" aria-label="Close" data-close>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>

Styleguide 2.7.3.1
*/
/*
Top notifications that span the scrollable width (usage with .fluid-container)

In the example above, the top notification spans the visible width. If you are using the <a href="#/section/3.2">.fluid-container</a> style to set a minimum and maximum width to the content, you will need to make top notifications span the entire scrollable width. See the markup below for how this is done.

This example is best viewed by opening it in full screen.

Markup:
<div class="callout callout--top callout--fluid-container-background callout--success" role="alertdialog">
    <div class="fluid-container">
        <div class="row align-middle">
            <div class="columns small-1 callout__nav callout__nav--left">
              <svg class="icon icon--medium"><use xlink:href="/build/icons/icon-pack.svg#summerhomeBoat"></use></svg>
            </div>
            <div class="columns shrink callout__icon">
                <svg class="icon icon--medium"><use xlink:href="/build/icons/icon-pack.svg#ok"></use></svg>
            </div>
            <div class="columns callout__content">
                Content
            </div>
            <div class="columns shrink callout__button">
                <button type="button" class="button button--tertiary">
                    .button--tertiary
                </button>
            </div>
            <div class="small-1 columns callout__button  callout__nav callout__nav--right">
                <button type="button" class="close-button" aria-label="Close" data-close>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>

Styleguide 2.7.3.2
*/
/**
Spike box

Replace the following variables with wanted values:
$spikebox-width - will set the width of the div without the spike
$spikebox-bg-color - will set the color
$spikebox-left - will set the spike to point to left if value is > 0
$spikebox-right - will set the spike point to right if value is > 0: Either $spikebox-left or $spikebox-right is required to have value 0.
$spikebox-height - will set the height of the spikebox: In order to calculate the spike size correctly give the $spikebox-height value as rems.

<pre>
@include nordea-spikebox ($spikebox-width, $spikebox-bg-color, $spikebox-left, $spikebox-right, $spikebox-height);
</pre>

<h3>Examples</h3>

.spikebox--default - using default values
.spikebox--left - yellow with spike to the left
.spikebox--right - blue with spike to the right
.spikebox--large - large version

Markup:
<div class="{$modifiers}"></div>

Styleguide 2.12
*/
.spikebox--default {
  background-color: #fbc557;
  position: relative;
  height: 4rem;
  width: 100%; }
  .spikebox--default::after {
    border-color: transparent #fbc557;
    border-style: solid;
    border-width: 2rem 0 2rem 1rem;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -1rem; }

.spikebox--left {
  background-color: #fbc557;
  position: relative;
  height: 4rem;
  width: 100%; }
  .spikebox--left::after {
    border-color: transparent #fbc557;
    border-style: solid;
    border-width: 2rem 1rem 2rem 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -1rem; }

.spikebox--right {
  background-color: #54abdb;
  position: relative;
  height: 4rem;
  width: 100%; }
  .spikebox--right::after {
    border-color: transparent #54abdb;
    border-style: solid;
    border-width: 2rem 0 2rem 1rem;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -1rem; }

.spikebox--large {
  background-color: #fc6161;
  position: relative;
  height: 14rem;
  width: 100%; }
  .spikebox--large::after {
    border-color: transparent #fc6161;
    border-style: solid;
    border-width: 7rem 0 7rem 1rem;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -1rem; }

/*
Lists

These block list styles are the same styles that should also be used for <a ui-sref="home#mdSelect">md-select</a> and <a ui-sref="home#mdMenu">md-menu</a>. See "Show markup" below for how to use `.block-list` and `.block-list-item` classes.

Based on https://confluence.oneadr.net:8443/display/DBNS/6.+Lists+and+tables?preview=/124225308/125013341/lists_02.pdf , this may still be a work in progress, as block lists for different components are currently each defining these styles separately.

Markup:
<div class="row">
  <div class="column small-4">
    <div class="panel">
      <ul class="block-list">
        <li class="block-list-item">
          <div class="row align-bottom">
            <div class="column">Body</div>
            <div class="amount column shrink" aria-label="1234.56">
              <span class="amount__integers" aria-hidden="true">1234</span><span class="amount__decimal-symbol" aria-hidden="true">,</span><span class="amount__decimals" aria-hidden="true">56</span>
            </div>
          </div>
        </li>
        <li class="block-list-item">
          <div>Body</div>
          <div class="label label--secondary">Small label `.label--secondary`</div>
        </li>
        <li class="block-list-item">
          <div>Body</div>
          <div class="label label--secondary">Small label</div>
          <div class="label label--secondary">Small label</div>
        </li>
        <li class="block-list-item">
          <div>Body</div>
          <div class="label label--secondary float-left">Small label</div>
          <div class="label label--secondary float-right">Small label</div>
        </li>
      </ul>
    </div>
  </div>
</div>

Styleguide 2.13
*/
/*
Tables

Markup: placeholder for tables

Styleguide 2.14
*/
.label {
  font-size: 1rem;
  line-height: 1.5; }

.label--primary {
  font-size: 1.25rem; }

.label--secondary {
  font-size: 0.8125rem;
  color: #474748; }

.label--xlarge {
  font-size: 3rem; }

.amount {
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  font-feature-settings: 'tnum'; }
  .amount .amount__currency {
    font-size: 0.8125rem;
    line-height: 0.8125rem;
    margin-left: 4px; }
  .amount.amount--secondary .amount__integers,
  .amount.amount--secondary .amount__decimals {
    font-size: 0.8125rem;
    line-height: 1.5; }
  .amount.amount--primary .amount__integers,
  .amount.amount--primary .amount__decimals {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 300; }
  .amount.amount--bold .amount__integers,
  .amount.amount--bold .amount__decimals {
    font-weight: bold; }
  .amount.amount--plain {
    font-size: inherit;
    line-height: inherit; }
    .amount.amount--plain .amount__integers {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit; }
  .amount.positive {
    color: #40bfa3; }
  .amount.negative {
    color: #fc6161; }

.colors-negative .amount.amount--primary, .colors-negative.amount.amount--primary {
  color: #ffffff; }

.colors-negative .amount.positive, .colors-negative.amount.positive {
  color: #c5ece3; }

.colors-negative .amount.negative, .colors-negative.amount.negative {
  color: #b00000; }

.divider {
  border-bottom: 1px solid #e6e4e3;
  height: 0;
  margin-top: 2.75rem;
  margin-bottom: 2.25rem;
  text-align: center; }
  .divider::before, .divider::after {
    display: table;
    content: ' ';
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-order: 1;
        order: 1; }
  .divider::after {
    clear: both; }
  .divider .label {
    display: block;
    transform: translate(0, -100%); }
  .divider .button {
    transform: translate(0, -50%); }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  line-height: 1.5;
  background: #3399ff;
  color: #ffffff;
  vertical-align: middle; }
  .badge.badge--primary, .badge.primary {
    background: #0000a0;
    color: #ffffff; }
  .badge.badge--secondary, .badge.secondary {
    background: #3399ff;
    color: #0000a0; }
  .badge.badge--success, .badge.success {
    background: #006060;
    color: #ffffff; }
  .badge.badge--info, .badge.info {
    background: #3399ff;
    color: #ffffff; }
  .badge.badge--warning, .badge.warning {
    background: #ffe183;
    color: #000000; }
  .badge.badge--alert, .badge.alert {
    background: #b00000;
    color: #ffffff; }
  .badge.badge--error, .badge.error {
    background: #b00000;
    color: #ffffff; }

.close-button:hover {
  text-decoration: none; }

.callout {
  position: relative;
  margin: 0;
  padding: 1rem 1rem 1rem 1.5rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  border: 0;
  font-size: 1rem;
  line-height: 1; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout--primary {
    background-color: #0000a0; }
    .callout--primary,
    .callout--primary .icon,
    .callout--primary button:not(.button--secondary) {
      color: #ffffff;
      border-color: #ffffff; }
  .callout--secondary {
    background-color: #3399ff; }
    .callout--secondary,
    .callout--secondary .icon,
    .callout--secondary button:not(.button--secondary) {
      color: #0000a0;
      border-color: #0000a0; }
  .callout--success {
    background-color: #006060; }
    .callout--success,
    .callout--success .icon,
    .callout--success button:not(.button--secondary) {
      color: #ffffff;
      border-color: #ffffff; }
  .callout--info {
    background-color: #3399ff; }
    .callout--info,
    .callout--info .icon,
    .callout--info button:not(.button--secondary) {
      color: #ffffff;
      border-color: #ffffff; }
  .callout--warning {
    background-color: #ffe183; }
    .callout--warning,
    .callout--warning .icon,
    .callout--warning button:not(.button--secondary) {
      color: #000000;
      border-color: #000000; }
  .callout--alert {
    background-color: #b00000; }
    .callout--alert,
    .callout--alert .icon,
    .callout--alert button:not(.button--secondary) {
      color: #ffffff;
      border-color: #ffffff; }
  .callout--error {
    background-color: #b00000; }
    .callout--error,
    .callout--error .icon,
    .callout--error button:not(.button--secondary) {
      color: #ffffff;
      border-color: #ffffff; }
  .callout .button.button--tertiary {
    border-color: transparent; }
  .callout.default {
    background-color: #bfd994; }
  .callout.accounts {
    background-color: #40bfa3; }
  .callout.cards {
    background-color: #a6d6bd; }
  .callout.cards-credit {
    background-color: #a6d6bd; }
  .callout.cards-debit {
    background-color: #a6d6bd; }
  .callout.cards-combined {
    background-color: #a6d6bd; }
  .callout.is-inactive {
    background-color: #ffe183; }
  .callout.is-blocked {
    background-color: #b00000; }
  .callout.is-new {
    background-color: #006060; }
  .callout.status-inactive {
    background-color: #fbd9ca; }
  .callout.status-blocked {
    background-color: #fbd9ca; }
  .callout.loans {
    background-color: #a6d6bd; }
  .callout.loans-credit {
    background-color: #a6d6bd; }
  .callout.row,
  .callout .row {
    max-width: initial; }

.callout__button button,
.callout__button .button {
  margin: 0; }

.callout__button .close-button {
  position: static;
  line-height: 1em; }

.callout__content {
  display: inline-block; }

.callout__close {
  opacity: 0.7;
  cursor: pointer;
  float: right; }

.callout__close:hover {
  opacity: 1; }

.callout__action--container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-left: 10px; }

.callout__action {
  cursor: pointer;
  text-decoration: underline;
  display: inline-block; }

.callout--success .callout__action {
  color: #ffffff; }

.callout--warning .callout__action {
  color: #0000a0; }

.callout--error .callout__action {
  color: #ffe183; }

.callout--alert .callout__action {
  color: #ffe183; }

.callout--success .callout__close {
  fill: #ffffff; }

.callout--warning .callout__close {
  fill: #000000; }

.callout--alert .callout__close {
  fill: #ffffff; }

.callout--error .callout__close {
  fill: #ffffff; }

.callout__nav--right {
  text-align: right; }

.callout--fluid-container-background {
  min-width: 62.9375rem;
  width: 100%;
  min-width: 0;
  padding-left: 0;
  padding-right: 0; }

.menu.vertical > li > a, .menu.vertical > li > .link {
  -ms-flex-align: center;
      align-items: center; }

.menu-text {
  font-weight: normal; }

.block-list {
  list-style: none;
  margin-left: 0; }
  .block-list .block-list-item, .block-list > li,
  .block-list > * {
    padding: 0.75rem 1.5rem; }
    .block-list .block-list-item::before,
    .block-list > ::before, .block-list .block-list-item::after,
    .block-list > ::after {
      display: table;
      content: ' ';
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
      -ms-flex-order: 1;
          order: 1; }
    .block-list .block-list-item::after,
    .block-list > ::after {
      clear: both; }
    .block-list .block-list-item .column > *, .block-list > li .column > *,
    .block-list > * .column > *, .block-list .block-list-item .columns > *, .block-list > li .columns > *,
    .block-list > * .columns > *,
    .block-list .block-list-item > *:not(.column):not(.columns), .block-list > li > *:not(.column):not(.columns),
    .block-list > * > *:not(.column):not(.columns) {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem; }
    .block-list .block-list-item:hover,
    .block-list > :hover {
      background-color: #f1f8ff; }

.panel .block-list {
  margin-left: -1.3rem 1.5rem 2rem 1.5rem;
  margin-right: -1.3rem 1.5rem 2rem 1.5rem; }

.panel.block-list {
  padding-left: 0;
  padding-right: 0; }

.styleguide {
  box-sizing: border-box; }

.styleguide__safe-zone {
  padding: 0.75rem; }

.styleguide__grid-example .row {
  border: 1px solid #8b8a8d; }

.styleguide__grid-example .column:nth-child(odd), .styleguide__grid-example .columns:nth-child(odd), .styleguide__grid-example .columns:nth-child(odd) {
  background-color: #8b8a8d; }

.styleguide__grid-example .column:nth-child(even), .styleguide__grid-example .columns:nth-child(even), .styleguide__grid-example .columns:nth-child(even) {
  background-color: #e6e4e3; }

.styleguide__color-box {
  height: 100px;
  width: 100px;
  display: inline-block; }
  .styleguide__color-box--x-light-gray {
    position: relative; }
    .styleguide__color-box--x-light-gray:before {
      width: 100%;
      height: 100%;
      content: "Trust me, it's there.";
      font-size: 40%;
      padding: 1.5rem 1rem; }

.sg.sg-section[id^='section-1.2'] {
  position: relative; }
  .sg.sg-section[id^='section-1.2']:before {
    width: 100%;
    height: 100%;
    border-right: 1px solid #e6e4e3;
    border-left: 1px solid #e6e4e3;
    display: block; }
  .sg.sg-section[id^='section-1.2'].sg-section {
    clear: both; }
  .sg.sg-section[id^='section-1.2'] .sg.sg-section-partial {
    float: left;
    width: auto;
    border-left: none; }
    .sg.sg-section[id^='section-1.2'] .sg.sg-section-partial:first-of-type, .sg.sg-section[id^='section-1.2'] .sg.sg-section-partial.sg-code-listing {
      float: none;
      clear: both; }

.styleguide__color-box--deep-blue {
  background-color: #00005e; }

.styleguide__color-box--nordea-blue {
  background-color: #0000a0; }

.styleguide__color-box--vivid-blue {
  background-color: #0000a0; }

.styleguide__color-box--medium-blue {
  background-color: #3399ff; }

.styleguide__color-box--light-blue {
  background-color: #99ccff; }

.styleguide__color-box--x-light-blue {
  background-color: #e5f2ff; }

.styleguide__color-box--dark-pink {
  background-color: #f0c1ae; }

.styleguide__color-box--nordea-pink {
  background-color: #fbd9ca; }

.styleguide__color-box--light-pink {
  background-color: #fdece4; }

.styleguide__color-box--red {
  background-color: #fc6161; }

.styleguide__color-box--dark-red {
  background-color: #b00000; }

.styleguide__color-box--green {
  background-color: #40bfa3; }

.styleguide__color-box--dark-green {
  background-color: #006060; }

.styleguide__color-box--yellow {
  background-color: #ffe183; }

.styleguide__color-box--black {
  background-color: #000000; }

.styleguide__color-box--x-dark-gray {
  background-color: #474748; }

.styleguide__color-box--nordea-gray {
  background-color: #8b8a8d; }

.styleguide__color-box--medium-gray {
  background-color: #8b8a8d; }

.styleguide__color-box--light-gray {
  background-color: #e6e4e3; }

.styleguide__color-box--x-light-gray {
  background-color: #f3f3f3; }

.styleguide__color-box--white {
  background-color: #ffffff; }

.styleguide__color-box.green--dark {
  background-color: #93ba5f; }

.styleguide__color-box.green--base {
  background-color: #a7c975; }

.styleguide__color-box.green--light {
  background-color: #bfd994; }

.styleguide__color-box.brown--dark {
  background-color: #9e592b; }

.styleguide__color-box.brown--base {
  background-color: #b8662e; }

.styleguide__color-box.brown--light {
  background-color: #c78759; }

.styleguide__color-box.orange--dark {
  background-color: #f97a3e; }

.styleguide__color-box.orange--base {
  background-color: #ff944d; }

.styleguide__color-box.orange--light {
  background-color: #fca66b; }

.styleguide__color-box.pink--dark {
  background-color: #c14d7d; }

.styleguide__color-box.pink--base {
  background-color: #cf659d; }

.styleguide__color-box.pink--light {
  background-color: #de85b0; }

.styleguide__color-box.sienna--dark {
  background-color: #a48051; }

.styleguide__color-box.sienna--base {
  background-color: #b7976a; }

.styleguide__color-box.sienna--light {
  background-color: #ccb28a; }

.styleguide__color-box.yellow--dark {
  background-color: #fbc557; }

.styleguide__color-box.yellow--base {
  background-color: #ffd879; }

.styleguide__color-box.yellow--light {
  background-color: #fae3a6; }

.styleguide__color-box.violet--dark {
  background-color: #b56dc1; }

.styleguide__color-box.violet--base {
  background-color: #c585cf; }

.styleguide__color-box.violet--light {
  background-color: #d6a3de; }

.styleguide__color-box.brick--dark {
  background-color: #ee512f; }

.styleguide__color-box.brick--base {
  background-color: #f56b45; }

.styleguide__color-box.brick--light {
  background-color: #f59d83; }

.styleguide__color-box.jeans--dark {
  background-color: #6380c9; }

.styleguide__color-box.jeans--base {
  background-color: #6e8ce0; }

.styleguide__color-box.jeans--light {
  background-color: #a1b0e5; }

.styleguide__color-box.chocolate--dark {
  background-color: #75402b; }

.styleguide__color-box.chocolate--base {
  background-color: #8a4730; }

.styleguide__color-box.chocolate--light {
  background-color: #a3705c; }

.styleguide__color-box.blue--dark {
  background-color: #3289c8; }

.styleguide__color-box.blue--base {
  background-color: #54abdb; }

.styleguide__color-box.blue--light {
  background-color: #8fc4e1; }

.styleguide__color-box.emerald--dark {
  background-color: #71b58f; }

.styleguide__color-box.emerald--base {
  background-color: #89c5a4; }

.styleguide__color-box.emerald--light {
  background-color: #a6d6bd; }

.styleguide__color-box.skin--dark {
  background-color: #ba9675; }

.styleguide__color-box.skin--base {
  background-color: #dbb28c; }

.styleguide__color-box.skin--light {
  background-color: #ebccb5; }

.styleguide__color-box.grey--dark {
  background-color: #7d7873; }

.styleguide__color-box.grey--base {
  background-color: #948c87; }

.styleguide__color-box.grey--light {
  background-color: #b3aba5; }

.styleguide__color-name {
  margin-left: 20px;
  vertical-align: middle; }

code {
  background-color: transparent;
  border: 0px; }

.styleguide__colors-negative, .styleguide__negative, .styleguide__contrast-region, .styleguide--dark-background {
  background-color: #0000a0; }

.styleguide__colors-negative, .styleguide__negative, .styleguide__contrast-region {
  display: inline-block;
  padding: 1rem; }

.sg .sg.sg-section .sg.sg-section-partial {
  background: 0 none; }

.sg .sg-section-description .sg {
  white-space: pre-wrap; }

/**
Functions and Mixins

Styleguide 4
*/
.column, .columns, .columns {
  min-width: 0; }

.text-left, .text--left {
  text-align: left; }

.text-right, .text--right {
  text-align: right; }

.text-center, .text--center {
  text-align: center; }

.text-justify, .text--justify {
  text-align: justify; }

@media screen and (min-width: 33.75em) {
  .smallish-text-left {
    text-align: left; }
  .smallish-text-right {
    text-align: right; }
  .smallish-text-center {
    text-align: center; }
  .smallish-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }
