/* Typography */
/**
 * Section header.
 * Also used for card headers.
 */
.ncc-section-header {
  color: #00005e;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  margin: 0; }

/**
 * Header. Should not be used a lot.
 */
.ncc-header {
  font-size: 18px;
  line-height: 28px;
  line-height: 24px;
  margin: 0; }

.ncc-heading-xl {
  font-family: "Nordea Sans Large";
  font-size: 48px;
  line-height: 56px; }

.ncc-heading-l {
  font-family: "Nordea Sans Large";
  font-size: 40px;
  line-height: 44px; }

.ncc-article h1 {
  font-family: "Nordea Sans Large";
  font-size: 40px;
  line-height: 44px; }

.ncc-article h1 {
  margin: 0 0 32px; }

.ncc-heading-m {
  font-family: "Nordea Sans Large";
  font-size: 32px;
  line-height: 36px; }

.ncc-article h2 {
  font-family: "Nordea Sans Large";
  font-size: 32px;
  line-height: 36px; }

.ncc-article h2 {
  margin: 0 0 24px; }

.ncc-heading-s {
  font-family: "Nordea Sans Large";
  font-size: 26px;
  line-height: 32px; }

.ncc-article h3 {
  font-family: "Nordea Sans Large";
  font-size: 26px;
  line-height: 32px; }

.ncc-article h3 {
  margin: 0 0 24px; }

.ncc-text-xxl {
  font-family: "Nordea Sans Large";
  font-size: 22px;
  line-height: 28px; }

.ncc-article h4 {
  font-family: "Nordea Sans Large";
  font-size: 22px;
  line-height: 28px; }

.ncc-text-xl {
  font-size: 18px;
  line-height: 24px; }

.ncc-article h5 {
  font-size: 18px;
  line-height: 24px; }

.ncc-text-l {
  font-size: 16px;
  line-height: 20px; }

.ncc-text-l {
  font-size: 16px;
  line-height: 20px; }

.ncc-text-m {
  font-size: 14px;
  line-height: 18px; }

.ncc-text-s {
  font-size: 12px;
  line-height: 16px; }

.ncc-text-xs {
  font-size: 11px;
  line-height: 12px; }

.ncc-p {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0; }

.ncc-article p {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0; }

.ncc-link {
  color: #00005e; }

.ncc-article a {
  color: #00005e; }

.ncc-link:visited {
  color: #00005e; }

.ncc-article a:visited {
  color: #00005e; }

.ncc-link:hover {
  color: rgba(0, 0, 94, 0.8); }

.ncc-article a:hover {
  color: rgba(0, 0, 94, 0.8); }

.ncc-link:focus {
  color: rgba(0, 0, 94, 0.8); }

.ncc-article a:focus {
  color: rgba(0, 0, 94, 0.8); }

.ncc-link:active {
  color: rgba(0, 0, 94, 0.8); }

.ncc-article a:active {
  color: rgba(0, 0, 94, 0.8); }

/* p, a, etc */
.ncc-muted {
  color: #c9c7c7; }

/* .muted, etc */
/* Icons */
.ncc-svg-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  /* default, same as "small" */
  width: 16px;
  height: 16px; }

.ncc-svg-container--xs {
  width: 12px;
  height: 12px; }

.ncc-svg-container--s {
  width: 16px;
  height: 16px; }

.ncc-svg-container--m {
  width: 24px;
  height: 24px; }

.ncc-svg-container--l {
  width: 30px;
  height: 30px; }

.ncc-svg-container--xl {
  width: 45px;
  height: 45px; }

.ncc-svg-container svg,
.ncc-svg-container__svg {
  -ms-flex: 1;
      flex: 1; }

.ncc-spinner svg {
  x: 0;
  y: 0;
  /* IE only knows animations on the svg element, so we do the best we can: just rotate */
  -ms-animation: rotate 2s linear infinite; }

.ncc-spinner circle {
  fill: transparent;
  stroke: #0000a0;
  stroke-width: 8%;
  stroke-linecap: round;
  stroke-dasharray: 57;
  transform-origin: 50% 50% 0;
  animation: spinner 2.5s linear infinite;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 57; }
  50% {
    transform: rotate(720deg);
    stroke-dashoffset: 107; }
  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 57; } }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

/* Form */
.ncc-input {
  font-size: 14px;
  display: block;
  min-height: 86px; }

.ncc-input__header {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin-bottom: 4px; }

.ncc-input__label {
  -ms-flex: 1;
      flex: 1; }

.ncc-input--error .ncc-input__label {
  color: #ff6161; }

.ncc-input--disabled .ncc-input__label {
  color: #8b8a8d; }

.ncc-input:focus-within .ncc-input__label {
  color: #0000a0; }

.ncc-input--error:focus-within .ncc-input__label {
  color: #0000a0; }

.ncc-input--error.ncc-input--focus .ncc-input__label {
  color: #0000a0; }

.ncc-input--focus .ncc-input__label {
  color: #0000a0; }

.ncc-input__note {
  color: #8b8a8d; }

.ncc-input__field-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
      align-items: stretch;
  position: relative;
  border: 1px solid #c9c7c7;
  border-radius: 2px;
  box-sizing: border-box;
  background: white;
  height: 40px;
  line-height: 40px;
  /* This padding prevents the border from disappearing when the browser is
     * scaled down to 90 or 80% */
  padding: 1px 0; }

.ncc-input--error .ncc-input__field-container {
  border-color: #ff6161; }

.ncc-input:focus-within .ncc-input__field-container {
  border-color: #0000a0; }

.ncc-input--error:focus-within .ncc-input__field-container {
  border-color: #0000a0; }

.ncc-input--error.ncc-input--focus .ncc-input__field-container {
  border-color: #0000a0; }

.ncc-input--focus .ncc-input__field-container {
  border-color: #0000a0; }

.ncc-input--disabled .ncc-input__field-container {
  color: #9e9e9e;
  background: #f4f2f1; }

.ncc-input__field:disabled {
  color: #9e9e9e;
  background: #f4f2f1; }

.ncc-input__postfix {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-right: 12px; }

.ncc-input--disabled .ncc-input__postfix {
  color: #9e9e9e; }

.ncc-input__icon {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 4px;
  width: 30px;
  height: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.8); }

.ncc-input__icon svg {
  stroke: rgba(0, 0, 0, 0.8); }

.ncc-input--disabled .ncc-input__icon {
  opacity: .5; }

.ncc-input__icon--button {
  border: 0;
  padding: 0;
  background-color: #e3e3e3; }

.ncc-input:not(.ncc-input--disabled) .ncc-input__icon--button:hover {
  background-color: #dcedff;
  color: #0000a0;
  cursor: pointer; }

.ncc-input:not(.ncc-input--disabled) .ncc-input__icon--button:focus {
  background-color: #dcedff;
  color: #0000a0;
  cursor: pointer; }

.ncc-input:not(.ncc-input--disabled) .ncc-input__icon--button:hover svg {
  stroke: #0000a0; }

.ncc-input:not(.ncc-input--disabled) .ncc-input__icon--button:focus svg {
  stroke: #0000a0; }

.ncc-input__field {
  -ms-flex: 1;
      flex: 1;
  min-width: 0;
  box-sizing: border-box;
  border: none;
  padding: 0 12px;
  font-size: 16px;
  font-family: inherit; }

.ncc-input__field::-ms-clear {
  display: none; }

.ncc-input__field:focus {
  outline: none; }

.ncc-input__field:-ms-input-placeholder {
  color: #777777; }

.ncc-input__field::placeholder {
  color: #777777; }

.ncc-input__icon ~ .ncc-input__field {
  padding-left: 8px; }

.ncc-input__footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-top: 4px; }

.ncc-input__error {
  -ms-flex: 1;
      flex: 1;
  color: #ff6161; }

.ncc-input__counter {
  color: #8b8a8d;
  margin-left: 8px; }

.ncc-input--error .ncc-input__counter {
  color: #ff6161; }

.ncc-textarea {
  font-size: 14px;
  display: block;
  min-height: 144px; }

.ncc-textarea__header {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin-bottom: 4px; }

.ncc-textarea__label {
  -ms-flex: 1;
      flex: 1; }

.ncc-textarea.ncc-textarea--error .ncc-textarea__label {
  color: #ff6161; }

.ncc-textarea.ncc-textarea--disabled .ncc-textarea__label {
  color: #8b8a8d; }

.ncc-textarea:focus-within .ncc-textarea__label {
  color: #0000a0; }

.ncc-textarea.ncc-textarea--focus .ncc-textarea__label {
  color: #0000a0; }

.ncc-textarea__note {
  color: #8b8a8d; }

.ncc-textarea__field {
  box-sizing: border-box;
  width: 100%;
  /*
     * 2 * padding      = 16px
     * 4 * line height  = 80px
     * 2 * border       = 2px
     * */
  min-height: 98px;
  border: none;
  padding: 8px 12px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #c9c7c7;
  border-radius: 2px;
  background: white;
  resize: vertical; }

.ncc-textarea.ncc-textarea--error .ncc-textarea__field {
  border-color: #ff6161; }

.ncc-textarea--error .ncc-textarea__field:focus {
  border-color: #0000a0;
  outline: none; }

.ncc-textarea__field:focus {
  border-color: #0000a0;
  outline: none; }

.ncc-textarea__field:disabled {
  color: #9e9e9e;
  background: #f4f2f1; }

.ncc-textarea__field:-ms-input-placeholder {
  color: #777777; }

.ncc-textarea__field::placeholder {
  color: #777777; }

.ncc-textarea__footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-top: 4px; }

.ncc-textarea__error {
  -ms-flex: 1;
      flex: 1;
  color: #ff6161; }

.ncc-textarea__counter {
  color: #8b8a8d;
  margin-left: 8px; }

.ncc-textarea--error .ncc-textarea__counter {
  color: #ff6161; }

.ncc-textarea__counter--error {
  color: #ff6161; }

.ncc-select {
  display: block;
  min-height: 86px; }

.ncc-select:focus-within .ncc-select__field-container {
  border-color: #0000a0; }

.ncc-select:focus-within .ncc-select__label {
  color: #0000a0; }

.ncc-select.ncc-select--focus .ncc-select__label {
  color: #0000a0; }

.ncc-select__header {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  margin-bottom: 4px; }

.ncc-select__label {
  -ms-flex: 1;
      flex: 1; }

.ncc-select:focus-within .ncc-select__label {
  color: #0000a0; }

.ncc-select.ncc-select--focus .ncc-select__label {
  color: #0000a0; }

.ncc-select.ncc-select--error .ncc-select__label {
  color: #ff6161; }

.ncc-select.ncc-select--disabled .ncc-select__label {
  color: #8b8a8d; }

.ncc-select__note {
  color: #8b8a8d; }

.ncc-select__field-container {
  position: relative; }

.ncc-select__field {
  width: 100%;
  border: 1px solid #c9c7c7;
  border-radius: 2px;
  font-size: 16px;
  font-family: inherit;
  line-height: 40px;
  height: 40px;
  padding: 0 42px 0 12px;
  /* 12px + 30px */
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: white; }

.ncc-select__field:focus {
  outline: none;
  border-color: #0000a0; }

.ncc-select.ncc-select--error .ncc-select__field {
  border-color: #ff6161; }

.ncc-select__field::-ms-expand {
  display: none; }

.ncc-select--disabled .ncc-select__field {
  color: #9e9e9e;
  background: #f4f2f1;
  cursor: auto; }

.ncc-select__field:disabled {
  color: #9e9e9e;
  background: #f4f2f1;
  cursor: auto; }

.ncc-select__icon {
  position: absolute;
  top: 5px;
  right: 5px;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 30px;
  height: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  border-radius: 2px;
  background-color: #e3e3e3; }

.ncc-select:not(:disabled):hover .ncc-select__icon {
  background-color: #dcedff; }

.ncc-select__icon svg {
  stroke: rgba(0, 0, 0, 0.8); }

.ncc-select:focus-within .ncc-select__icon {
  background-color: #dcedff; }

.ncc-select.ncc-select--focus .ncc-select__icon {
  background-color: #dcedff; }

.ncc-select:focus-within .ncc-select__icon svg {
  stroke: #0000a0; }

.ncc-select.ncc-select--focus .ncc-select__icon svg {
  stroke: #0000a0; }

.ncc-select--disabled .ncc-select__icon,
.ncc-select__field:disabled + .ncc-select__icon {
  opacity: .5; }

.ncc-select__error {
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #ff6161; }

.ncc-inline-select {
  display: inline-block;
  position: relative; }

.ncc-inline-select__field {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  border: 1px solid #c9c7c7;
  padding: 0 24px 0 8px;
  cursor: pointer;
  border-radius: 2px;
  height: 24px;
  font-size: inherit;
  line-height: 24px; }

.ncc-inline-select__field::-ms-expand {
  display: none; }

.ncc-inline-select__icon {
  position: absolute;
  top: 6px;
  right: 4px;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  border-radius: 2px; }

.ncc-inline-select__icon svg {
  stroke: rgba(0, 0, 0, 0.8); }

.ncc-inline-select:focus-within .ncc-inline-select__icon svg {
  stroke: #0000a0; }

.ncc-inline-select.ncc-select--focus .ncc-inline-select__icon svg {
  stroke: #0000a0; }

.ncc-inline-select--disabled .ncc-select__icon,
.ncc-inline-select__field:disabled + .ncc-select__icon {
  opacity: .5; }

.ncc-datepicker {
  display: inline-block;
  background-color: white;
  padding: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  font-size: 14px; }

.ncc-datepicker--small {
  font-size: 12px; }

/**
 * Header: filter
 */
.ncc-datepicker__filter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.ncc-datepicker__filter-month {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.ncc-datepicker__filter-month svg {
  stroke: rgba(0, 0, 0, 0.8); }

.ncc-datepicker__change-month {
  border: none;
  background-color: transparent;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 8px;
  cursor: pointer;
  line-height: 0;
  border-radius: 2px; }

.ncc-datepicker__change-month:hover {
  background-color: #f4f2f1; }

.ncc-datepicker__change-month--disabled {
  opacity: .1;
  cursor: default; }

.ncc-datepicker__change-month--disabled:hover {
  background-color: transparent; }

.ncc-datepicker__selected-month {
  -ms-flex-positive: 1;
      flex-grow: 1;
  margin: 0;
  font-size: inherit;
  font-weight: bold;
  text-align: center; }

.ncc-datepicker__filter-year {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-left: 8px; }

/**
 * Table: month
 */
.ncc-datepicker__month-container {
  margin-top: 8px;
  min-height: 259px; }

.ncc-datepicker--small .ncc-datepicker__month-container {
  min-height: 232px; }

.ncc-datepicker__month {
  width: 100%; }

.ncc-datepicker__month th {
  font-weight: normal;
  text-align: center;
  padding: 4px; }

.ncc-datepicker__month td {
  font-weight: normal;
  text-align: center;
  padding: 4px; }

.ncc-datepicker__month th {
  font-size: 12px; }

.ncc-datepicker__day {
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  width: 28px;
  height: 26px;
  /* + 2px padding-top */
  line-height: 2em;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding-top: 2px;
  /* to offset our messed up font */ }

.ncc-datepicker__month td.ncc-muted .ncc-datepicker__day,
.ncc-datepicker__month td.ncc-muted .ncc-datepicker__day:hover {
  cursor: default;
  background-color: transparent;
  color: inherit; }

.ncc-datepicker__day.ncc-datepicker__day--selected {
  color: white;
  background-color: #0000a0; }

.ncc-datepicker__day.ncc-datepicker__day--selected:hover {
  color: white;
  background-color: #0000a0; }

.ncc-datepicker__today .ncc-datepicker__day.ncc-datepicker__day--selected {
  color: white;
  background-color: #0000a0; }

.ncc-datepicker__today .ncc-datepicker__day.ncc-datepicker__day--selected:hover {
  color: white;
  background-color: #0000a0; }

.ncc-datepicker__day:hover {
  background-color: #f4f2f1; }

.ncc-datepicker__today .ncc-datepicker__day {
  background-color: #e3e3e3; }

.ncc-datepicker__today .ncc-datepicker__day:hover {
  background-color: #e3e3e3; }

.ncc-datepicker__footer {
  padding: 12px 8px 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.ncc-button {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 272px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 32px;
  font-size: 16px;
  font-family: 'Nordea Sans', Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 50px;
  /*large enough to make the element rounded*/
  -webkit-appearance: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  width: 100%; }

@media (min-width: 768px) {
  .ncc-button {
    display: inline-block;
    width: auto;
    margin: 0; } }

.ncc-button:disabled {
  pointer-events: none; }

.ncc-button--large {
  padding: 12px 24px;
  font-size: 18px; }

/* PRIMARY */
.ncc-button--primary {
  color: white;
  background-color: #0000a0;
  border-color: #0000a0; }

.ncc-button--primary:hover {
  color: #83b8ed;
  background-color: #dcedff;
  border-color: #dcedff; }

.ncc-button--primary:focus {
  color: #83b8ed;
  background-color: #dcedff;
  border-color: #dcedff; }

.ncc-button--primary:disabled {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 94, 0.3);
  border-color: transparent; }

/* SECONDARY */
.ncc-button--secondary {
  color: #0000a0;
  background-color: white;
  border-color: #0000a0; }

.ncc-button--secondary:hover {
  color: #83b8ed;
  background-color: #dcedff;
  border-color: #dcedff; }

.ncc-button--secondary:focus {
  color: #83b8ed;
  background-color: #dcedff;
  border-color: #dcedff; }

.ncc-button--secondary:disabled {
  color: rgba(0, 0, 94, 0.3);
  background-color: white;
  border-color: rgba(0, 0, 94, 0.3); }

/* TERTIARY */
.ncc-button--tertiary {
  color: #0000a0;
  background-color: transparent;
  border-color: transparent; }

.ncc-button--tertiary:hover,
.ncc-button--tertiary:focus {
  opacity: 0.8; }

.ncc-button--tertiary:disabled {
  opacity: 0.3; }

.ncc-button-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

@media (min-width: 768px) {
  .ncc-button-box {
    -ms-flex-direction: row;
        flex-direction: row; }
  .ncc-button-box--center {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    -ms-flex-pack: center;
        justify-content: center; }
  .ncc-button-box--right {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    -ms-flex-pack: start;
        justify-content: flex-start; } }

.ncc-button-box__button {
  margin-bottom: 12px; }

.ncc-button-box__button:last-child {
  margin-bottom: 0; }

button.button-box__button {
  margin: 0 auto 12px; }

button.button-box__button:last-child {
  margin: 0 auto; }

@media (min-width: 768px) {
  .ncc-button-box__button {
    margin: 0 24px 0 0; }
  .ncc-button-box--center .ncc-button-box__button,
  .ncc-button-box--right .ncc-button-box__button {
    margin: 0 0 0 24px; }
  .ncc-button-box__button:last-child {
    margin: 0; } }

.ncc-radio {
  display: block;
  position: relative;
  cursor: pointer; }

.ncc-radio__circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  /* circles should always have uneven dimensions */
  height: 19px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #0000a0;
  z-index: 1; }

.ncc-radio__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 19px;
  margin: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer; }

.ncc-radio__dot {
  width: 9px;
  height: 9px;
  border: 0;
  margin-top: 3px;
  margin-left: 3px;
  background-color: transparent;
  border-radius: 50%; }

.ncc-radio__input:focus + .ncc-radio__circle {
  /* Can't use outline because it is always square */
  border-color: #83b8ed; }

.ncc-radio--checked .ncc-radio__dot {
  background-color: #0000a0; }

.ncc-radio__label {
  display: block;
  margin-left: 30px;
  font-size: 16px;
  line-height: 20px; }

/* Disabled */
.ncc-radio--disabled .ncc-radio__circle {
  border: #c9c7c7 2px solid; }

.ncc-radio--checked.ncc-radio--disabled .ncc-radio__dot {
  background-color: #c9c7c7; }

.ncc-radio--disabled .ncc-radio__label {
  color: #c9c7c7; }

.ncc-radio--disabled,
.ncc-radio--disabled .ncc-radio__circle {
  cursor: default; }

.ncc-checkbox {
  display: block;
  position: relative;
  cursor: pointer; }

.ncc-checkbox__input:focus + .ncc-checkbox__square {
  outline: 2px solid #83b8ed;
  outline-offset: -1px; }

.ncc-checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0; }

.ncc-checkbox--checked .ncc-checkbox__square {
  background-color: #0000a0; }

.ncc-checkbox__span {
  display: block;
  margin-left: 30px;
  font-size: 16px;
  line-height: 20px; }

.ncc-checkbox__square {
  position: absolute;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: 0;
  border: 2px solid #0000a0;
  z-index: 1; }

.ncc-checkbox__square--icon {
  overflow: hidden;
  border: 0; }

/* Disabled */
.ncc-checkbox--disabled,
.ncc-checkbox--disabled .ncc-checkbox__input {
  cursor: default; }

.ncc-checkbox--disabled .ncc-checkbox__square {
  border: 2px solid #c9c7c7; }

.ncc-checkbox--disabled .ncc-checkbox__span {
  color: #c9c7c7; }

.ncc-checkbox--checked.ncc-checkbox--disabled .ncc-checkbox__square {
  background-color: #c9c7c7; }

/* Other */
.ncc-panel {
  border-radius: 8px;
  background-color: white;
  padding: 12px; }

@media (min-width: 768px) {
  .ncc-panel {
    padding: 24px; } }

.ncc-list {
  font-size: 16px;
  line-height: 20px;
  list-style-position: outside;
  padding: 0;
  margin: 0 0 20px 24px; }

.ncc-list--clean {
  list-style: none;
  margin-left: 0; }

/**
 * The table is special in that it doesn't require you to mark up every child
 * with a class. This is mostly for convenience of its users, but also because
 * the rigid structure and specific tag names of tables actually allow it (this
 * can only really go wrong if you start nesting tables which is a big no-no).
 */
.ncc-table table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  background-color: white;
  font-size: 16px;
  line-height: 20px; }

.ncc-table__cell--numeric {
  text-align: right; }

.ncc-table caption {
  text-align: left;
  font-weight: 500;
  margin-bottom: 16px; }

.ncc-table td {
  padding: 12px; }

.ncc-table th {
  padding: 14px 12px;
  background-color: #e3e3e3;
  font-weight: 500; }

.ncc-table--compact th, .ncc-table--compact td {
  padding: 8px 12px; }

.ncc-table td {
  border-bottom: 1px solid #c9c7c7; }

.ncc-semantic {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent; }

.ncc-semantic:focus, .ncc-semantic:active {
  outline: none; }

.ncc-assistive-text {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  text-transform: none !important;
  white-space: nowrap !important; }

.ncc-color-background-warm-grey {
  background-color: #f4f2f1; }

/**
 * CSS variables can not be used in media queries, so this file makes no use of
 * variables at all, and instead relies on comments to document what is what.
 * Please edit with care.
 */
/**
 * breakpoints:
 *      default: 0
 *      medium (m): 768px
 *      large (l): 1200px
 *
 * gutter_width: 12px / 24px
 * half_gutter_width: 6px / 12px
 * gutter_compompensation: -6px / -12px
 * m_min: 768px
 * l_min: 1200px
 */
.ncc-grid-container {
  margin-right: auto;
  margin-left: auto; }

.ncc-row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -6px;
  /* gutter_compompensation */
  margin-left: -6px;
  /* gutter_compompensation */ }

.ncc-row.ncc-reverse {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }

.ncc-col.ncc-reverse {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }

.ncc-col,
.ncc-col-1,
.ncc-col-2,
.ncc-col-3,
.ncc-col-4,
.ncc-col-5,
.ncc-col-6,
.ncc-col-7,
.ncc-col-8,
.ncc-col-9,
.ncc-col-10,
.ncc-col-11,
.ncc-col-12,
.ncc-col-offset-0,
.ncc-col-offset-1,
.ncc-col-offset-2,
.ncc-col-offset-3,
.ncc-col-offset-4,
.ncc-col-offset-5,
.ncc-col-offset-6,
.ncc-col-offset-7,
.ncc-col-offset-8,
.ncc-col-offset-9,
.ncc-col-offset-10,
.ncc-col-offset-11,
.ncc-col-offset-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  padding-right: 6px;
  /* half_gutter_width */
  padding-left: 6px;
  /* half_gutter_width */ }

.ncc-col {
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  max-width: 100%; }

.ncc-col-1 {
  -ms-flex-preferred-size: 8.33333333%;
      flex-basis: 8.33333333%;
  max-width: 8.33333333%; }

.ncc-col-2 {
  -ms-flex-preferred-size: 16.66666667%;
      flex-basis: 16.66666667%;
  max-width: 16.66666667%; }

.ncc-col-3 {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
  max-width: 25%; }

.ncc-col-4 {
  -ms-flex-preferred-size: 33.33333333%;
      flex-basis: 33.33333333%;
  max-width: 33.33333333%; }

.ncc-col-5 {
  -ms-flex-preferred-size: 41.66666667%;
      flex-basis: 41.66666667%;
  max-width: 41.66666667%; }

.ncc-col-6 {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  max-width: 50%; }

.ncc-col-7 {
  -ms-flex-preferred-size: 58.33333333%;
      flex-basis: 58.33333333%;
  max-width: 58.33333333%; }

.ncc-col-8 {
  -ms-flex-preferred-size: 66.66666667%;
      flex-basis: 66.66666667%;
  max-width: 66.66666667%; }

.ncc-col-9 {
  -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
  max-width: 75%; }

.ncc-col-10 {
  -ms-flex-preferred-size: 83.33333333%;
      flex-basis: 83.33333333%;
  max-width: 83.33333333%; }

.ncc-col-11 {
  -ms-flex-preferred-size: 91.66666667%;
      flex-basis: 91.66666667%;
  max-width: 91.66666667%; }

.ncc-col-12 {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  max-width: 100%; }

.ncc-col-offset-0 {
  margin-left: 0; }

.ncc-col-offset-1 {
  margin-left: 8.33333333%; }

.ncc-col-offset-2 {
  margin-left: 16.66666667%; }

.ncc-col-offset-3 {
  margin-left: 25%; }

.ncc-col-offset-4 {
  margin-left: 33.33333333%; }

.ncc-col-offset-5 {
  margin-left: 41.66666667%; }

.ncc-col-offset-6 {
  margin-left: 50%; }

.ncc-col-offset-7 {
  margin-left: 58.33333333%; }

.ncc-col-offset-8 {
  margin-left: 66.66666667%; }

.ncc-col-offset-9 {
  margin-left: 75%; }

.ncc-col-offset-10 {
  margin-left: 83.33333333%; }

.ncc-col-offset-11 {
  margin-left: 91.66666667%; }

.ncc-start {
  -ms-flex-pack: start;
      justify-content: flex-start;
  text-align: start; }

.ncc-center {
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center; }

.ncc-end {
  -ms-flex-pack: end;
      justify-content: flex-end;
  text-align: end; }

.ncc-top {
  -ms-flex-align: start;
      align-items: flex-start; }

.ncc-middle {
  -ms-flex-align: center;
      align-items: center; }

.ncc-bottom {
  -ms-flex-align: end;
      align-items: flex-end; }

.ncc-around {
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.ncc-between {
  -ms-flex-pack: justify;
      justify-content: space-between; }

.ncc-first {
  -ms-flex-order: -1;
      order: -1; }

.ncc-last {
  -ms-flex-order: 1;
      order: 1; }

@media only screen and (min-width: 768px) {
  /* m_min */
  .ncc-grid-container {
    width: 792px;
    /* m_min + gutter_width */ }
  .ncc-row {
    margin-right: -12px;
    /* gutter_compompensation */
    margin-left: -12px;
    /* gutter_compompensation */ }
  .ncc-col,
  .ncc-col-1,
  .ncc-col-2,
  .ncc-col-3,
  .ncc-col-4,
  .ncc-col-5,
  .ncc-col-6,
  .ncc-col-7,
  .ncc-col-8,
  .ncc-col-9,
  .ncc-col-10,
  .ncc-col-11,
  .ncc-col-12,
  .ncc-col-offset-0,
  .ncc-col-offset-1,
  .ncc-col-offset-2,
  .ncc-col-offset-3,
  .ncc-col-offset-4,
  .ncc-col-offset-5,
  .ncc-col-offset-6,
  .ncc-col-offset-7,
  .ncc-col-offset-8,
  .ncc-col-offset-9,
  .ncc-col-offset-10,
  .ncc-col-offset-11,
  .ncc-col-offset-12 {
    padding-right: 12px;
    /* half_gutter_width */
    padding-left: 12px;
    /* half_gutter_width */ }
  .ncc-col-m {
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%; }
  .ncc-col-m-1 {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .ncc-col-m-2 {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .ncc-col-m-3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%; }
  .ncc-col-m-4 {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .ncc-col-m-5 {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .ncc-col-m-6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%; }
  .ncc-col-m-7 {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .ncc-col-m-8 {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .ncc-col-m-9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%; }
  .ncc-col-m-10 {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .ncc-col-m-11 {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .ncc-col-m-12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%; }
  .ncc-col-m-offset-0 {
    margin-left: 0; }
  .ncc-col-m-offset-1 {
    margin-left: 8.33333333%; }
  .ncc-col-m-offset-2 {
    margin-left: 16.66666667%; }
  .ncc-col-m-offset-3 {
    margin-left: 25%; }
  .ncc-col-m-offset-4 {
    margin-left: 33.33333333%; }
  .ncc-col-m-offset-5 {
    margin-left: 41.66666667%; }
  .ncc-col-m-offset-6 {
    margin-left: 50%; }
  .ncc-col-m-offset-7 {
    margin-left: 58.33333333%; }
  .ncc-col-m-offset-8 {
    margin-left: 66.66666667%; }
  .ncc-col-m-offset-9 {
    margin-left: 75%; }
  .ncc-col-m-offset-10 {
    margin-left: 83.33333333%; }
  .ncc-col-m-offset-11 {
    margin-left: 91.66666667%; }
  .ncc-start-m {
    -ms-flex-pack: start;
        justify-content: flex-start;
    text-align: start; }
  .ncc-center-m {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center; }
  .ncc-end-m {
    -ms-flex-pack: end;
        justify-content: flex-end;
    text-align: end; }
  .ncc-top-m {
    -ms-flex-align: start;
        align-items: flex-start; }
  .ncc-middle-m {
    -ms-flex-align: center;
        align-items: center; }
  .ncc-bottom-m {
    -ms-flex-align: end;
        align-items: flex-end; }
  .ncc-around-m {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .ncc-between-m {
    -ms-flex-pack: justify;
        justify-content: space-between; }
  .ncc-first-m {
    -ms-flex-order: -1;
        order: -1; }
  .ncc-last-m {
    -ms-flex-order: 1;
        order: 1; } }

@media only screen and (min-width: 1200px) {
  /* l_min */
  .ncc-grid-container {
    width: 1224px;
    /* l_min + gutter_width */ }
  .ncc-col-l {
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    max-width: 100%; }
  .ncc-col-l-1 {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .ncc-col-l-2 {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .ncc-col-l-3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%; }
  .ncc-col-l-4 {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .ncc-col-l-5 {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .ncc-col-l-6 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%; }
  .ncc-col-l-7 {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .ncc-col-l-8 {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .ncc-col-l-9 {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%; }
  .ncc-col-l-10 {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .ncc-col-l-11 {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .ncc-col-l-12 {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%; }
  .ncc-col-l-offset-0 {
    margin-left: 0; }
  .ncc-col-l-offset-1 {
    margin-left: 8.33333333%; }
  .ncc-col-l-offset-2 {
    margin-left: 16.66666667%; }
  .ncc-col-l-offset-3 {
    margin-left: 25%; }
  .ncc-col-l-offset-4 {
    margin-left: 33.33333333%; }
  .ncc-col-l-offset-5 {
    margin-left: 41.66666667%; }
  .ncc-col-l-offset-6 {
    margin-left: 50%; }
  .ncc-col-l-offset-7 {
    margin-left: 58.33333333%; }
  .ncc-col-l-offset-8 {
    margin-left: 66.66666667%; }
  .ncc-col-l-offset-9 {
    margin-left: 75%; }
  .ncc-col-l-offset-10 {
    margin-left: 83.33333333%; }
  .ncc-col-l-offset-11 {
    margin-left: 91.66666667%; }
  .ncc-start-l {
    -ms-flex-pack: start;
        justify-content: flex-start;
    text-align: start; }
  .ncc-center-l {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center; }
  .ncc-end-l {
    -ms-flex-pack: end;
        justify-content: flex-end;
    text-align: end; }
  .ncc-top-l {
    -ms-flex-align: start;
        align-items: flex-start; }
  .ncc-middle-l {
    -ms-flex-align: center;
        align-items: center; }
  .ncc-bottom-l {
    -ms-flex-align: end;
        align-items: flex-end; }
  .ncc-around-l {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .ncc-between-l {
    -ms-flex-pack: justify;
        justify-content: space-between; }
  .ncc-first-l {
    -ms-flex-order: -1;
        order: -1; }
  .ncc-last-l {
    -ms-flex-order: 1;
        order: 1; } }

.ncc-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
  background: rgba(0, 0, 0, 0.4); }

.ncc-modal--hide {
  display: none; }

.ncc-modal__body {
  -ms-flex: 1;
      flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: stretch;
      align-items: stretch;
  margin: 24px;
  max-width: 600px;
  max-height: calc(100vh - 48px);
  background: white;
  border-radius: 8px;
  overflow: hidden;
  /* To keep borders round */
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.02), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }

@media only screen and (max-width: 768px) {
  .ncc-modal__body {
    margin: 12px;
    max-height: calc(100vh - 24px); } }

.ncc-modal__header {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: #e3e3e3;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 24px; }

@media only screen and (max-width: 768px) {
  .ncc-modal__header {
    padding: 12px; } }

.ncc-modal__title {
  -ms-flex: 1;
      flex: 1;
  font-size: 18px;
  font-weight: 500; }

.ncc-modal__content {
  -ms-flex: 1;
      flex: 1;
  padding: 24px;
  overflow-y: auto; }

@media only screen and (max-width: 768px) {
  .ncc-modal__content {
    padding: 12px; } }

.ncc-modal__footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 24px; }

@media only screen and (max-width: 768px) {
  .ncc-modal__footer {
    padding: 12px; } }

/* Space */
.ncc-space-top-xs {
  margin-top: 4px; }

.ncc-space-top-s {
  margin-top: 8px; }

.ncc-space-top-m {
  margin-top: 12px; }

.ncc-space-top-l {
  margin-top: 16px; }

.ncc-space-top-xl {
  margin-top: 24px; }

.ncc-space-top-xxl {
  margin-top: 32px; }

.ncc-space-right-xs {
  margin-right: 4px; }

.ncc-space-right-s {
  margin-right: 8px; }

.ncc-space-right-m {
  margin-right: 12px; }

.ncc-space-right-l {
  margin-right: 16px; }

.ncc-space-right-xl {
  margin-right: 24px; }

.ncc-space-right-xxl {
  margin-right: 32px; }

.ncc-space-bottom-xs {
  margin-bottom: 4px; }

.ncc-space-bottom-s {
  margin-bottom: 8px; }

.ncc-space-bottom-m {
  margin-bottom: 12px; }

.ncc-space-bottom-l {
  margin-bottom: 16px; }

.ncc-space-bottom-xl {
  margin-bottom: 24px; }

.ncc-space-bottom-xxl {
  margin-bottom: 32px; }

.ncc-space-left-xs {
  margin-left: 4px; }

.ncc-space-left-s {
  margin-left: 8px; }

.ncc-space-left-m {
  margin-left: 12px; }

.ncc-space-left-l {
  margin-left: 16px; }

.ncc-space-left-xl {
  margin-left: 24px; }

.ncc-space-left-xxl {
  margin-left: 32px; }

.ncc-space-vertical-xs {
  margin-top: 4px;
  margin-bottom: 4px; }

.ncc-space-vertical-s {
  margin-top: 8px;
  margin-bottom: 8px; }

.ncc-space-vertical-m {
  margin-top: 12px;
  margin-bottom: 12px; }

.ncc-space-vertical-l {
  margin-top: 16px;
  margin-bottom: 16px; }

.ncc-space-vertical-xl {
  margin-top: 24px;
  margin-bottom: 24px; }

.ncc-space-vertical-xxl {
  margin-top: 32px;
  margin-bottom: 32px; }

.ncc-space-horizontal-xs {
  margin-right: 4px;
  margin-left: 4px; }

.ncc-space-horizontal-s {
  margin-right: 8px;
  margin-left: 8px; }

.ncc-space-horizontal-m {
  margin-right: 12px;
  margin-left: 12px; }

.ncc-space-horizontal-l {
  margin-right: 16px;
  margin-left: 16px; }

.ncc-space-horizontal-xl {
  margin-right: 24px;
  margin-left: 24px; }

.ncc-space-horizontal-xxl {
  margin-right: 32px;
  margin-left: 32px; }

.ncc-space-el-top {
  margin-top: 24px; }

.ncc-space-el-top-large {
  margin-top: 32px; }

.ncc-space-el-right {
  margin-right: 24px; }

.ncc-space-el-right-large {
  margin-right: 32px; }

.ncc-space-el-bottom {
  margin-bottom: 24px; }

.ncc-space-el-bottom-large {
  margin-bottom: 32px; }

.ncc-space-el-left {
  margin-left: 24px; }

.ncc-space-el-left-large {
  margin-left: 32px; }

.ncc-space-el-vertical {
  margin-right: 24px;
  margin-left: 24px; }

.ncc-space-el-vertical-large {
  margin-right: 32px;
  margin-left: 32px; }

.ncc-space-el-horizontal {
  margin-top: 24px;
  margin-bottom: 24px; }

.ncc-space-el-horizontal-large {
  margin-top: 32px;
  margin-bottom: 32px; }

.ncc-space-inset-el-top {
  padding-top: 24px; }

.ncc-space-inset-el-top-large {
  padding-top: 32px; }

.ncc-space-inset-el-right {
  padding-right: 24px; }

.ncc-space-inset-el-right-large {
  padding-right: 32px; }

.ncc-space-inset-el-bottom {
  padding-bottom: 24px; }

.ncc-space-inset-el-bottom-large {
  padding-bottom: 32px; }

.ncc-space-inset-el-left {
  padding-left: 24px; }

.ncc-space-inset-el-left-large {
  padding-left: 32px; }

.ncc-space-inset-el-vertical {
  padding-right: 24px;
  padding-left: 24px; }

.ncc-space-inset-el-vertical-large {
  padding-right: 32px;
  padding-left: 32px; }

.ncc-space-inset-el-horizontal {
  padding-top: 24px;
  padding-bottom: 24px; }

.ncc-space-inset-el-horizontal-large {
  padding-top: 32px;
  padding-bottom: 32px; }

.ncc-space-inset-el {
  padding: 24px; }

.ncc-space-inset-el-large {
  padding: 32px; }

.ncc-space-form-el-bottom {
  margin-bottom: 8px; }

/* Mobile */
@media only screen and (max-width: 768px) {
  .ncc-space-el-top {
    margin-top: 12px; }
  .ncc-space-el-top-large {
    margin-top: 16px; }
  .ncc-space-el-right {
    margin-right: 12px; }
  .ncc-space-el-right-large {
    margin-right: 16px; }
  .ncc-space-el-bottom {
    margin-bottom: 12px; }
  .ncc-space-el-bottom-large {
    margin-bottom: 16px; }
  .ncc-space-el-left {
    margin-left: 12px; }
  .ncc-space-el-left-large {
    margin-left: 16px; }
  .ncc-space-el-vertical {
    margin-right: 12px;
    margin-left: 12px; }
  .ncc-space-el-vertical-large {
    margin-right: 16px;
    margin-left: 16px; }
  .ncc-space-el-horizontal {
    margin-top: 12px;
    margin-bottom: 12px; }
  .ncc-space-el-horizontal-large {
    margin-top: 16px;
    margin-bottom: 16px; }
  .ncc-space-inset-el-top {
    padding-top: 12px; }
  .ncc-space-inset-el-top-large {
    padding-top: 16px; }
  .ncc-space-inset-el-right {
    padding-right: 12px; }
  .ncc-space-inset-el-right-large {
    padding-right: 16px; }
  .ncc-space-inset-el-bottom {
    padding-bottom: 12px; }
  .ncc-space-inset-el-bottom-large {
    padding-bottom: 16px; }
  .ncc-space-inset-el-left {
    padding-left: 12px; }
  .ncc-space-inset-el-left-large {
    padding-left: 16px; }
  .ncc-space-inset-el-vertical {
    padding-right: 12px;
    padding-left: 12px; }
  .ncc-space-inset-el-vertical-large {
    padding-right: 16px;
    padding-left: 16px; }
  .ncc-space-inset-el-horizontal {
    padding-top: 12px;
    padding-bottom: 12px; }
  .ncc-space-inset-el-horizontal-large {
    padding-top: 16px;
    padding-bottom: 16px; }
  .ncc-space-inset-el {
    padding: 12px; }
  .ncc-space-inset-el-large {
    padding: 16px; } }

/* Inline */
.ncc-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row; }

.ncc-inline__item {
  margin-right: 12px; }

.ncc-inline__item:last-child {
  margin-right: 0; }

/* Stacked */
.ncc-inline--m, .ncc-inline--l {
  -ms-flex-direction: column;
      flex-direction: column; }

.ncc-inline--m > .ncc-inline__item {
  margin-right: 0;
  margin-bottom: 12px; }

.ncc-inline--l > .ncc-inline__item {
  margin-right: 0;
  margin-bottom: 12px; }

.ncc-inline--m.ncc-inline--form > .ncc-inline__item {
  margin-bottom: 8px; }

.ncc-inline--l.ncc-inline--form > .ncc-inline__item {
  margin-bottom: 8px; }

.ncc-inline--m > .ncc-inline__item:last-child,
.ncc-inline--l > .ncc-inline__item:last-child {
  margin-bottom: 0; }

/* Extra */
.ncc-inline--grow .ncc-inline__item,
.ncc-inline__item--grow {
  -ms-flex-positive: 1;
      flex-grow: 1; }

@media (min-width: 768px) {
  /* Inline */
  .ncc-inline--m {
    -ms-flex-direction: row;
        flex-direction: row; }
  .ncc-inline > .ncc-inline__item {
    margin-bottom: 0;
    margin-right: 24px; }
  .ncc-inline--m > .ncc-inline__item {
    margin-bottom: 0;
    margin-right: 24px; }
  .ncc-inline > .ncc-inline__item:last-child,
  .ncc-inline--m > .ncc-inline__item:last-child {
    margin-right: 0; }
  /* Stacked */
  .ncc-inline--l > .ncc-inline__item {
    margin-right: 0;
    margin-bottom: 24px; }
  .ncc-inline--l.ncc-inline--form > .ncc-inline__item {
    margin-bottom: 8px; } }

@media (min-width: 1200px) {
  .ncc-inline--l {
    -ms-flex-direction: row;
        flex-direction: row; }
  .ncc-inline--l > .ncc-inline__item {
    margin-bottom: 0;
    margin-right: 24px; }
  .ncc-inline--l > .ncc-inline__item:last-child {
    margin-right: 0; } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px; }

md-autocomplete[disabled] input {
  cursor: default; }

md-autocomplete[md-floating-label] {
  border-radius: 0;
  background: transparent;
  height: auto; }

md-autocomplete[md-floating-label] md-input-container {
  padding-bottom: 0; }

md-autocomplete[md-floating-label] md-autocomplete-wrap {
  height: auto; }

md-autocomplete[md-floating-label] .md-show-clear-button button {
  display: block;
  position: absolute;
  right: 0;
  top: 20px;
  width: 30px;
  height: 30px; }

md-autocomplete[md-floating-label] .md-show-clear-button input {
  padding-right: 30px; }

[dir=rtl] md-autocomplete[md-floating-label] .md-show-clear-button input {
  padding-right: 0;
  padding-left: 30px; }

md-autocomplete md-autocomplete-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  height: 40px; }

md-autocomplete md-autocomplete-wrap.md-menu-showing {
  z-index: 51; }

md-autocomplete md-autocomplete-wrap md-input-container, md-autocomplete md-autocomplete-wrap input {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  box-sizing: border-box;
  min-width: 0; }

md-autocomplete md-autocomplete-wrap md-progress-linear {
  position: absolute;
  bottom: -2px;
  left: 0; }

md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
  bottom: 40px;
  right: 2px;
  left: 2px;
  width: auto; }

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: none; }

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
  transition: none;
  height: 3px; }

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
  transition: opacity 0.15s linear; }

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
  opacity: 1; }

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
  transition: opacity 0.15s linear; }

md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
  opacity: 0; }

md-autocomplete input:not(.md-input) {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  width: 100%;
  padding: 0 15px;
  line-height: 40px;
  height: 40px; }

md-autocomplete input:not(.md-input)::-ms-clear {
  display: none; }

md-autocomplete .md-show-clear-button button {
  position: relative;
  line-height: 20px;
  text-align: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  background: transparent;
  margin: auto 5px; }

md-autocomplete .md-show-clear-button button:after {
  content: '';
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

md-autocomplete .md-show-clear-button button:focus {
  outline: none; }

md-autocomplete .md-show-clear-button button:focus:after {
  transform: scale(1);
  opacity: 1; }

md-autocomplete .md-show-clear-button button md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0.9); }

md-autocomplete .md-show-clear-button button md-icon path {
  stroke-width: 0; }

md-autocomplete .md-show-clear-button button.ng-enter {
  transform: scale(0);
  transition: transform 0.15s ease-out; }

md-autocomplete .md-show-clear-button button.ng-enter.ng-enter-active {
  transform: scale(1); }

md-autocomplete .md-show-clear-button button.ng-leave {
  transition: transform 0.15s ease-out; }

md-autocomplete .md-show-clear-button button.ng-leave.ng-leave-active {
  transform: scale(0); }

@media screen and (-ms-high-contrast: active) {
  md-autocomplete input {
    border: 1px solid #fff; }
  md-autocomplete li:focus {
    color: #fff; } }

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
  height: 100%; }

.md-virtual-repeat-container.md-not-found {
  height: 48px; }

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0; }

.md-autocomplete-suggestions li {
  font-size: 14px;
  overflow: hidden;
  padding: 0 15px;
  line-height: 48px;
  height: 48px;
  transition: background 0.15s linear;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis; }

.md-autocomplete-suggestions li:focus {
  outline: none; }

.md-autocomplete-suggestions li:not(.md-not-found-wrapper) {
  cursor: pointer; }

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff; } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50; }

md-backdrop.md-menu-backdrop {
  position: fixed !important;
  z-index: 99; }

md-backdrop.md-select-backdrop {
  z-index: 81;
  transition-duration: 0; }

md-backdrop.md-dialog-backdrop {
  z-index: 79; }

md-backdrop.md-bottom-sheet-backdrop {
  z-index: 69; }

md-backdrop.md-sidenav-backdrop {
  z-index: 59; }

md-backdrop.md-click-catcher {
  position: absolute; }

md-backdrop.md-opaque {
  opacity: .48; }

md-backdrop.md-opaque.ng-enter {
  opacity: 0; }

md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
  opacity: .48; }

md-backdrop.md-opaque.ng-leave {
  opacity: .48;
  transition: opacity 400ms; }

md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
  opacity: 0; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
.md-inline-form md-checkbox {
  margin: 19px 0 18px; }

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: 0;
  margin-right: 16px; }

[dir=rtl] md-checkbox {
  margin-left: 16px; }

[dir=rtl] md-checkbox {
  margin-right: 0; }

md-checkbox:last-of-type {
  margin-left: 0;
  margin-right: 0; }

md-checkbox.md-focused:not([disabled]) .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px; }

md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
  background-color: rgba(0, 0, 0, 0.12); }

md-checkbox.md-align-top-left > div.md-container {
  top: 12px; }

md-checkbox .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  right: auto; }

[dir=rtl] md-checkbox .md-container {
  left: auto; }

[dir=rtl] md-checkbox .md-container {
  right: 0; }

md-checkbox .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto; }

md-checkbox .md-container:after {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px; }

md-checkbox .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px; }

md-checkbox .md-icon {
  box-sizing: border-box;
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px; }

md-checkbox.md-checked .md-icon {
  border-color: transparent; }

md-checkbox.md-checked .md-icon:after {
  box-sizing: border-box;
  transform: rotate(45deg);
  position: absolute;
  left: 4.66667px;
  top: 0.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: ''; }

md-checkbox[disabled] {
  cursor: default; }

md-checkbox.md-indeterminate .md-icon:after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  width: 12px;
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: ''; }

md-checkbox .md-label {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin-left: 30px;
  margin-right: 0; }

[dir=rtl] md-checkbox .md-label {
  margin-left: 0; }

[dir=rtl] md-checkbox .md-label {
  margin-right: 30px; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px; }

md-icon svg {
  pointer-events: none;
  display: block; }

md-icon[md-font-icon] {
  line-height: 24px;
  width: auto; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */ }

md-input-container:after {
  content: '';
  display: table;
  clear: both; }

md-input-container.md-block {
  display: block; }

md-input-container .md-errors-spacer {
  float: right;
  min-height: 24px;
  min-width: 1px; }

[dir=rtl] md-input-container .md-errors-spacer {
  float: left; }

md-input-container > md-icon {
  position: absolute;
  top: 8px;
  left: 2px;
  right: auto; }

[dir=rtl] md-input-container > md-icon {
  left: auto; }

[dir=rtl] md-input-container > md-icon {
  right: 2px; }

md-input-container textarea,
md-input-container input[type="text"],
md-input-container input[type="password"],
md-input-container input[type="datetime"],
md-input-container input[type="datetime-local"],
md-input-container input[type="date"],
md-input-container input[type="month"],
md-input-container input[type="time"],
md-input-container input[type="week"],
md-input-container input[type="number"],
md-input-container input[type="email"],
md-input-container input[type="url"],
md-input-container input[type="search"],
md-input-container input[type="tel"],
md-input-container input[type="color"] {
  /* remove default appearance from all input/textarea */
  -moz-appearance: none;
  -webkit-appearance: none; }

md-input-container input[type="date"],
md-input-container input[type="datetime-local"],
md-input-container input[type="month"],
md-input-container input[type="time"],
md-input-container input[type="week"] {
  min-height: 26px; }

md-input-container textarea {
  resize: none;
  overflow: hidden; }

md-input-container textarea.md-input {
  min-height: 26px;
  -ms-flex-preferred-size: auto; }

md-input-container textarea[md-no-autogrow] {
  height: auto;
  overflow: auto; }

md-input-container label:not(.md-container-ignore) {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: auto; }

[dir=rtl] md-input-container label:not(.md-container-ignore) {
  left: auto; }

[dir=rtl] md-input-container label:not(.md-container-ignore) {
  right: 0; }

md-input-container label:not(.md-container-ignore).md-required:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top; }

md-input-container label:not(.md-no-float):not(.md-container-ignore),
md-input-container .md-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  -ms-flex-order: 1;
      order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 3px;
  padding-right: 0;
  z-index: 1;
  transform: translate3d(0, 28px, 0) scale(1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-width: 100%;
  transform-origin: left top; }

[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
md-input-container .md-placeholder {
  padding-left: 0; }

[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
md-input-container .md-placeholder {
  padding-right: 3px; }

[dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
md-input-container .md-placeholder {
  transform-origin: right top; }

md-input-container .md-placeholder {
  position: absolute;
  top: 0;
  opacity: 0;
  transition-property: opacity, transform;
  transform: translate3d(0, 30px, 0); }

md-input-container.md-input-focused .md-placeholder {
  opacity: 1;
  transform: translate3d(0, 24px, 0); }

md-input-container.md-input-has-value .md-placeholder {
  transition: none;
  opacity: 0; }

md-input-container:not(.md-input-has-value) input:not(:focus),
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field,
md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
  color: transparent; }

md-input-container .md-input {
  -ms-flex-order: 2;
      order: 2;
  display: block;
  margin-top: 0;
  background: none;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
  border-width: 0 0 1px 0;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-radius: 0;
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  float: left; }

[dir=rtl] md-input-container .md-input {
  float: right; }

md-input-container .md-input:focus {
  outline: none; }

md-input-container .md-input:invalid {
  outline: none;
  box-shadow: none; }

md-input-container .md-input.md-no-flex {
  -ms-flex: none !important;
      flex: none !important; }

md-input-container .md-char-counter {
  text-align: right;
  padding-right: 2px;
  padding-left: 0; }

[dir=rtl] md-input-container .md-char-counter {
  text-align: left; }

[dir=rtl] md-input-container .md-char-counter {
  padding-right: 0; }

[dir=rtl] md-input-container .md-char-counter {
  padding-left: 2px; }

md-input-container .md-input-messages-animation {
  position: relative;
  -ms-flex-order: 4;
      order: 4;
  overflow: hidden;
  clear: left; }

[dir=rtl] md-input-container .md-input-messages-animation {
  clear: right; }

md-input-container .md-input-message-animation, md-input-container .md-char-counter {
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: 1;
  margin-top: 0;
  padding-top: 5px; }

md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 5px;
  padding-left: 0; }

[dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
  padding-right: 0; }

[dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
  padding-left: 5px; }

md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
  opacity: 0;
  margin-top: -100px; }

md-input-container .md-input-message-animation.ng-enter-prepare {
  opacity: 0;
  margin-top: -100px; }

md-input-container .md-input-message-animation.ng-enter:not(.ng-enter-active) {
  opacity: 0;
  margin-top: -100px; }

md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
  transform: translate3d(0, 6px, 0) scale(0.75);
  transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s; }

md-input-container.md-input-has-value label {
  transition: none; }

md-input-container.md-input-focused .md-input,
md-input-container .md-input.ng-invalid.ng-dirty,
md-input-container.md-input-resized .md-input {
  padding-bottom: 0;
  border-width: 0 0 2px 0; }

md-input-container .md-input[disabled],
[disabled] md-input-container .md-input {
  background-position: bottom -1px left 0;
  background-size: 4px 1px;
  background-repeat: repeat-x; }

md-input-container.md-icon-float {
  transition: margin-top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }

md-input-container.md-icon-float > label {
  pointer-events: none;
  position: absolute; }

md-input-container.md-icon-float > md-icon {
  top: 8px;
  left: 2px;
  right: auto; }

[dir=rtl] md-input-container.md-icon-float > md-icon {
  left: auto; }

[dir=rtl] md-input-container.md-icon-float > md-icon {
  right: 2px; }

md-input-container.md-icon-left > label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-icon-left > label .md-placeholder, md-input-container.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-icon-right > label .md-placeholder {
  width: calc(100% - 36px - 18px); }

md-input-container.md-icon-left {
  padding-left: 36px;
  padding-right: 0; }

[dir=rtl] md-input-container.md-icon-left {
  padding-left: 0; }

[dir=rtl] md-input-container.md-icon-left {
  padding-right: 36px; }

md-input-container.md-icon-left > label {
  left: 36px;
  right: auto; }

[dir=rtl] md-input-container.md-icon-left > label {
  left: auto; }

[dir=rtl] md-input-container.md-icon-left > label {
  right: 36px; }

md-input-container.md-icon-right {
  padding-left: 0;
  padding-right: 36px; }

[dir=rtl] md-input-container.md-icon-right {
  padding-left: 36px; }

[dir=rtl] md-input-container.md-icon-right {
  padding-right: 0; }

md-input-container.md-icon-right > md-icon:last-of-type {
  margin: 0;
  right: 2px;
  left: auto; }

[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
  right: auto; }

[dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
  left: 2px; }

md-input-container.md-icon-left.md-icon-right {
  padding-left: 36px;
  padding-right: 36px; }

md-input-container.md-icon-left.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
md-input-container.md-icon-left.md-icon-right > label .md-placeholder {
  width: calc(100% - (36px * 2)); }

.md-resize-wrapper {
  position: relative; }

.md-resize-wrapper:after {
  content: '';
  display: table;
  clear: both; }

.md-resize-handle {
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 10px;
  background: transparent;
  width: 100%;
  cursor: ns-resize; }

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff; } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px;
  max-height: calc(100vh - 10px);
  overflow: auto; }

.md-open-menu-container md-menu-divider {
  margin-top: 4px;
  margin-bottom: 4px;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  width: 100%; }

.md-open-menu-container md-menu-content > * {
  opacity: 0; }

.md-open-menu-container:not(.md-clickable) {
  pointer-events: none; }

.md-open-menu-container.md-active {
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 200ms; }

.md-open-menu-container.md-active > md-menu-content > * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 200ms;
  transition-delay: 100ms; }

.md-open-menu-container.md-leave {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 250ms; }

md-menu-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto; }

md-menu-content.md-dense {
  max-height: 208px; }

md-menu-content.md-dense md-menu-item {
  height: 32px;
  min-height: 0px; }

md-menu-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  min-height: 48px;
  height: 48px;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
  /*
   * We cannot use flex on <button> elements due to a bug in Firefox, so we also can't use it on
   * <a> elements. Add some top padding to fix alignment since buttons automatically align their
   * text vertically.
   */ }

md-menu-item > * {
  width: 100%;
  margin: auto 0;
  padding-left: 16px;
  padding-right: 16px; }

md-menu-item > a.md-button {
  padding-top: 5px; }

md-menu-item > .md-button {
  text-align: left;
  display: inline-block;
  border-radius: 0;
  margin: auto 0;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%; }

md-menu-item > .md-button::-moz-focus-inner {
  padding: 0;
  border: 0; }

[dir=rtl] md-menu-item > .md-button {
  text-align: right; }

md-menu-item > .md-button md-icon {
  margin: auto 16px auto 0; }

[dir=rtl] md-menu-item > .md-button md-icon {
  margin: auto 0 auto 16px; }

md-menu-item > .md-button p {
  display: inline-block;
  margin: auto; }

md-menu-item > .md-button span {
  margin-top: auto;
  margin-bottom: auto; }

md-menu-item > .md-button .md-ripple-container {
  border-radius: inherit; }

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0; }

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px; }
  md-menu-content[width="3"] {
    min-width: 168px; }
  md-menu-content[width="4"] {
    min-width: 224px; }
  md-menu-content[width="5"] {
    min-width: 280px; }
  md-menu-content[width="6"] {
    min-width: 336px; }
  md-menu-content[width="7"] {
    min-width: 392px; } }

@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 192px; }
  md-menu-content[width="4"] {
    min-width: 256px; }
  md-menu-content[width="5"] {
    min-width: 320px; }
  md-menu-content[width="6"] {
    min-width: 384px; }
  md-menu-content[width="7"] {
    min-width: 448px; } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
.md-panel-outer-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

._md-panel-hidden {
  display: none; }

._md-panel-offscreen {
  left: -9999px; }

._md-panel-fullscreen {
  border-radius: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0; }

._md-panel-shown .md-panel {
  opacity: 1;
  transition: none; }

.md-panel {
  opacity: 0;
  position: fixed; }

.md-panel._md-panel-shown {
  opacity: 1;
  transition: none; }

.md-panel._md-panel-animate-enter {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1); }

.md-panel._md-panel-animate-leave {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1); }

.md-panel._md-panel-animate-scale-out, .md-panel._md-panel-animate-fade-out {
  opacity: 0; }

.md-panel._md-panel-backdrop {
  height: 100%;
  position: absolute;
  width: 100%; }

.md-panel._md-opaque-enter {
  opacity: .48;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1); }

.md-panel._md-opaque-leave {
  transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1); }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */

@keyframes indeterminate-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

md-progress-circular {
  position: relative;
  display: block; }

md-progress-circular._md-progress-circular-disabled {
  visibility: hidden; }

md-progress-circular.md-mode-indeterminate svg {
  animation: indeterminate-rotate 1568.63ms linear infinite; }

md-progress-circular svg {
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative; }

md-radio-button[disabled] {
  cursor: default; }

md-radio-button[disabled] .md-container {
  cursor: default; }

md-radio-button .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  left: 0;
  right: auto; }

[dir=rtl] md-radio-button .md-container {
  left: auto; }

[dir=rtl] md-radio-button .md-container {
  right: 0; }

md-radio-button .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px; }

md-radio-button .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto; }

md-radio-button.md-align-top-left > div.md-container {
  top: 12px; }

md-radio-button .md-off {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  transition: border-color ease 0.28s; }

md-radio-button .md-on {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform ease 0.28s;
  transform: scale(0); }

md-radio-button.md-checked .md-on {
  transform: scale(0.5); }

md-radio-button .md-label {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-left: 30px;
  margin-right: 0;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  width: auto; }

[dir=rtl] md-radio-button .md-label {
  margin-left: 0; }

[dir=rtl] md-radio-button .md-label {
  margin-right: 30px; }

md-radio-group {
  /** Layout adjustments for the radio group. */ }

md-radio-group.layout-column md-radio-button, md-radio-group.layout-xs-column md-radio-button, md-radio-group.layout-gt-xs-column md-radio-button, md-radio-group.layout-sm-column md-radio-button, md-radio-group.layout-gt-sm-column md-radio-button, md-radio-group.layout-md-column md-radio-button, md-radio-group.layout-gt-md-column md-radio-button, md-radio-group.layout-lg-column md-radio-button, md-radio-group.layout-gt-lg-column md-radio-button, md-radio-group.layout-xl-column md-radio-button {
  margin-bottom: 16px; }

md-radio-group.layout-row md-radio-button, md-radio-group.layout-xs-row md-radio-button, md-radio-group.layout-gt-xs-row md-radio-button, md-radio-group.layout-sm-row md-radio-button, md-radio-group.layout-gt-sm-row md-radio-button, md-radio-group.layout-md-row md-radio-button, md-radio-group.layout-gt-md-row md-radio-button, md-radio-group.layout-lg-row md-radio-button, md-radio-group.layout-gt-lg-row md-radio-button, md-radio-group.layout-xl-row md-radio-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 16px; }

[dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
  margin-left: 16px; }

[dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
  margin-right: 0; }

md-radio-group.layout-row md-radio-button:last-of-type, md-radio-group.layout-xs-row md-radio-button:last-of-type, md-radio-group.layout-gt-xs-row md-radio-button:last-of-type, md-radio-group.layout-sm-row md-radio-button:last-of-type, md-radio-group.layout-gt-sm-row md-radio-button:last-of-type, md-radio-group.layout-md-row md-radio-button:last-of-type, md-radio-group.layout-gt-md-row md-radio-button:last-of-type, md-radio-group.layout-lg-row md-radio-button:last-of-type, md-radio-group.layout-gt-lg-row md-radio-button:last-of-type, md-radio-group.layout-xl-row md-radio-button:last-of-type {
  margin-left: 0;
  margin-right: 0; }

md-radio-group:focus {
  outline: none; }

md-radio-group.md-focused .md-checked .md-container:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px; }

md-radio-group[disabled] md-radio-button {
  cursor: default; }

md-radio-group[disabled] md-radio-button .md-container {
  cursor: default; }

.md-inline-form md-radio-group {
  margin: 18px 0 19px; }

.md-inline-form md-radio-group md-radio-button {
  display: inline-block;
  height: 30px;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff; } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-origin: left top; }

[dir=rtl] md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transform-origin: right top; }

md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
  transform: translateY(-22px) translateX(-2px) scale(0.75); }

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none;
  transform: translateY(-1px); }

.md-select-menu-container:not(.md-clickable) {
  pointer-events: none; }

.md-select-menu-container md-progress-circular {
  display: table;
  margin: 24px auto !important; }

.md-select-menu-container.md-active {
  display: block;
  opacity: 1; }

.md-select-menu-container.md-active md-select-menu {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 150ms; }

.md-select-menu-container.md-active md-select-menu > * {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 150ms;
  transition-delay: 100ms; }

.md-select-menu-container.md-leave {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 250ms; }

md-input-container > md-select {
  margin: 0;
  -ms-flex-order: 2;
      order: 2; }

md-input-container:not(.md-input-has-value) md-select[required]:not(.md-no-asterisk) .md-select-value span:first-child:after, md-input-container:not(.md-input-has-value) md-select.ng-required:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top; }

md-input-container.md-input-invalid md-select .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px; }

md-select {
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0 26px 0; }

md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top; }

md-select[disabled] .md-select-value {
  background-position: 0 bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
  margin-bottom: -1px; }

md-select:focus {
  outline: none; }

md-select[disabled]:hover {
  cursor: default; }

md-select:not([disabled]):hover {
  cursor: pointer; }

md-select:not([disabled]).ng-invalid.ng-touched .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px; }

md-select:not([disabled]):focus .md-select-value {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 0; }

md-select:not([disabled]):focus.ng-invalid.ng-touched .md-select-value {
  padding-bottom: 0; }

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  transform: translate3d(0, 1px, 0); }

.md-select-value {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: transparent;
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.md-select-value > span:not(.md-select-icon) {
  max-width: 100%;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.md-select-value > span:not(.md-select-icon) .md-text {
  display: inline; }

.md-select-value .md-select-icon {
  display: block;
  -ms-flex-align: end;
      align-items: flex-end;
  text-align: end;
  width: 24px;
  margin: 0 4px;
  transform: translate3d(0, -2px, 0);
  font-size: 1.2rem; }

.md-select-value .md-select-icon:after {
  display: block;
  content: '\25BC';
  position: relative;
  top: 2px;
  speak: none;
  font-size: 13px;
  transform: scaleY(0.5) scaleX(1); }

.md-select-value.md-select-placeholder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 1;
      order: 1;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 2px;
  z-index: 1; }

md-select-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1); }

md-select-menu.md-reverse {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }

md-select-menu:not(.md-overflow) md-content {
  padding-top: 8px;
  padding-bottom: 8px; }

[dir=rtl] md-select-menu {
  transform-origin: right top; }

md-select-menu md-content {
  min-width: 136px;
  min-height: 48px;
  max-height: 256px;
  overflow-y: auto; }

md-select-menu > * {
  opacity: 0; }

md-option {
  cursor: pointer;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: auto;
  transition: background 0.15s linear;
  padding: 0 16px 0 16px;
  height: 48px; }

md-option[disabled] {
  cursor: default; }

md-option:focus {
  outline: none; }

md-option .md-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

md-optgroup {
  display: block; }

md-optgroup label {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  padding: 16px;
  font-weight: 500; }

md-optgroup md-option {
  padding-left: 32px;
  padding-right: 32px; }

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent; }
  md-select-menu {
    border: 1px solid #fff; } }

md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 40px;
  padding-right: 16px; }

[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 16px; }

[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-right: 40px; }

md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  left: 0;
  right: auto; }

[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  left: auto; }

[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  right: 0; }

md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:before {
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto; }

md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:after {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px; }

md-select-menu[multiple] md-option.md-checkbox-enabled .md-container .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px; }

md-select-menu[multiple] md-option.md-checkbox-enabled .md-icon {
  box-sizing: border-box;
  transition: 240ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px; }

md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon {
  border-color: transparent; }

md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon:after {
  box-sizing: border-box;
  transform: rotate(45deg);
  position: absolute;
  left: 4.66667px;
  top: 0.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: ''; }

md-select-menu[multiple] md-option.md-checkbox-enabled[disabled] {
  cursor: default; }

md-select-menu[multiple] md-option.md-checkbox-enabled.md-indeterminate .md-icon:after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: table;
  width: 12px;
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: ''; }

md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  margin-left: 10.66667px;
  margin-right: auto; }

[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  margin-left: auto; }

[dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  margin-right: 10.66667px; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px; }

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  margin-left: inherit;
  margin-right: 16px; }

[dir=rtl] md-switch {
  margin-left: 16px; }

[dir=rtl] md-switch {
  margin-right: inherit; }

md-switch:last-of-type {
  margin-left: inherit;
  margin-right: 0; }

[dir=rtl] md-switch:last-of-type {
  margin-left: 0; }

[dir=rtl] md-switch:last-of-type {
  margin-right: inherit; }

md-switch[disabled] {
  cursor: default; }

md-switch[disabled] .md-container {
  cursor: default; }

md-switch .md-container {
  cursor: -webkit-grab;
  cursor: grab;
  width: 36px;
  height: 24px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 8px;
  float: left; }

[dir=rtl] md-switch .md-container {
  margin-right: 0px;
  margin-left: 8px; }

md-switch.md-inverted .md-container {
  margin-right: initial;
  margin-left: 8px; }

[dir=rtl] md-switch.md-inverted .md-container {
  margin-right: 8px; }

[dir=rtl] md-switch.md-inverted .md-container {
  margin-left: initial; }

md-switch:not([disabled]) .md-dragging,
md-switch:not([disabled]).md-dragging .md-container {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

md-switch.md-focused:not([disabled]) .md-thumb:before {
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px; }

md-switch.md-focused:not([disabled]):not(.md-checked) .md-thumb:before {
  background-color: rgba(0, 0, 0, 0.12); }

md-switch .md-label {
  border-color: transparent;
  border-width: 0;
  float: left; }

md-switch .md-bar {
  left: 1px;
  width: 34px;
  top: 5px;
  height: 14px;
  border-radius: 8px;
  position: absolute; }

md-switch .md-thumb-container {
  top: 2px;
  left: 0;
  width: 16px;
  position: absolute;
  transform: translate3d(0, 0, 0);
  z-index: 1; }

md-switch.md-checked .md-thumb-container {
  transform: translate3d(100%, 0, 0); }

md-switch .md-thumb {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

md-switch .md-thumb:before {
  background-color: transparent;
  border-radius: 50%;
  content: '';
  position: absolute;
  display: block;
  height: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  width: auto; }

md-switch .md-thumb .md-ripple-container {
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px; }

md-switch:not(.md-dragging) .md-bar,
md-switch:not(.md-dragging) .md-thumb-container,
md-switch:not(.md-dragging) .md-thumb {
  transition: all 0.08s linear;
  transition-property: transform, background-color; }

md-switch:not(.md-dragging) .md-bar,
md-switch:not(.md-dragging) .md-thumb {
  transition-delay: 0.05s; }

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666; }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E; }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff; } }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */

@keyframes md-tab-content-hide {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0; }

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0; }

md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 248px; }

md-tabs[md-align-tabs="bottom"] {
  padding-bottom: 48px; }

md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  z-index: 2; }

md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
  top: 0;
  bottom: 48px; }

md-tabs.md-dynamic-height md-tabs-content-wrapper {
  min-height: 0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  overflow: visible; }

md-tabs.md-dynamic-height md-tab-content.md-active {
  position: relative; }

md-tabs[md-border-bottom] md-tabs-wrapper {
  border-width: 0 0 1px;
  border-style: solid; }

md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
  top: 49px; }

md-tabs-wrapper {
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0); }

md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
  height: 100%;
  width: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1em;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
  background: transparent no-repeat center center;
  transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1); }

md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
  outline: none; }

md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
  opacity: 0.25;
  cursor: default; }

md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
  transition: none; }

md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }

[dir="rtl"] md-tabs-wrapper md-prev-button, [dir="rtl"] md-tabs-wrapper md-next-button {
  transform: rotateY(180deg) translateY(-50%); }

md-tabs-wrapper md-prev-button {
  left: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg=="); }

[dir=rtl] md-tabs-wrapper md-prev-button {
  left: auto;
  right: 0; }

md-tabs-wrapper md-next-button {
  right: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K"); }

[dir=rtl] md-tabs-wrapper md-next-button {
  right: auto;
  left: 0; }

md-tabs-wrapper md-next-button md-icon {
  transform: translate3d(-50%, -50%, 0) rotate(180deg); }

md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
  width: 100%;
  -ms-flex-direction: row;
      flex-direction: row; }

md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
  -ms-flex-positive: 1;
      flex-grow: 1; }

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px; }

md-tabs-canvas:after {
  content: '';
  display: table;
  clear: both; }

md-tabs-canvas .md-dummy-wrapper {
  position: absolute;
  top: 0;
  left: 0; }

[dir=rtl] md-tabs-canvas .md-dummy-wrapper {
  left: auto;
  right: 0; }

md-tabs-canvas.md-paginated {
  margin: 0 32px; }

md-tabs-canvas.md-center-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center; }

md-tabs-canvas.md-center-tabs .md-tab {
  float: none;
  display: inline-block; }

md-pagination-wrapper {
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  left: 0;
  transform: translate3d(0, 0, 0); }

md-pagination-wrapper:after {
  content: '';
  display: table;
  clear: both; }

[dir=rtl] md-pagination-wrapper {
  left: auto;
  right: 0; }

md-pagination-wrapper.md-center-tabs {
  position: relative;
  -ms-flex-pack: center;
      justify-content: center; }

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

md-tab-content {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  transform: translate3d(0, 0, 0); }

md-tab-content.md-no-scroll {
  bottom: auto;
  overflow: hidden; }

md-tab-content.ng-leave, md-tab-content.md-no-transition {
  transition: none; }

md-tab-content.md-left:not(.md-active) {
  transform: translateX(-100%);
  animation: 1s md-tab-content-hide;
  visibility: hidden; }

[dir=rtl] md-tab-content.md-left:not(.md-active) {
  transform: translateX(100%); }

md-tab-content.md-left:not(.md-active) * {
  transition: visibility 0s linear;
  transition-delay: 0.5s;
  visibility: hidden; }

md-tab-content.md-right:not(.md-active) {
  transform: translateX(100%);
  animation: 1s md-tab-content-hide;
  visibility: hidden; }

[dir=rtl] md-tab-content.md-right:not(.md-active) {
  transform: translateX(-100%); }

md-tab-content.md-right:not(.md-active) * {
  transition: visibility 0s linear;
  transition-delay: 0.5s;
  visibility: hidden; }

md-tab-content > div {
  -ms-flex: 1 0 100%;
      flex: 1 0 100%;
  min-width: 0; }

md-tab-content > div.ng-leave {
  animation: 1s md-tab-content-hide; }

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px; }

md-ink-bar.md-left {
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }

md-ink-bar.md-right {
  transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px; }

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis; }

[dir=rtl] .md-tab {
  float: right; }

.md-tab.md-focused, .md-tab:focus {
  box-shadow: none;
  outline: none; }

.md-tab.md-active {
  cursor: default; }

.md-tab.md-disabled {
  pointer-events: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  opacity: 0.5;
  cursor: default; }

.md-tab.ng-leave {
  transition: none; }

md-toolbar + md-tabs, md-toolbar + md-dialog-content md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
.md-tooltip {
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  line-height: 32px;
  padding-right: 16px;
  padding-left: 16px; }

.md-tooltip.md-origin-top {
  transform-origin: center bottom;
  margin-top: -24px; }

.md-tooltip.md-origin-right {
  transform-origin: left center;
  margin-left: 24px; }

.md-tooltip.md-origin-bottom {
  transform-origin: center top;
  margin-top: 24px; }

.md-tooltip.md-origin-left {
  transform-origin: right center;
  margin-left: -24px; }

@media (min-width: 960px) {
  .md-tooltip {
    font-size: 10px;
    height: 22px;
    line-height: 22px;
    padding-right: 8px;
    padding-left: 8px; }
  .md-tooltip.md-origin-top {
    margin-top: -14px; }
  .md-tooltip.md-origin-right {
    margin-left: 14px; }
  .md-tooltip.md-origin-bottom {
    margin-top: 14px; }
  .md-tooltip.md-origin-left {
    margin-left: -14px; } }

.md-tooltip.md-show-add {
  transform: scale(0); }

.md-tooltip.md-show {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 150ms;
  transform: scale(1);
  opacity: 0.9; }

.md-tooltip.md-hide {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 150ms;
  transform: scale(0);
  opacity: 0; }

/*!
 * AngularJS Material Design
 * https://github.com/angular/material
 * @license MIT
 * v1.1.5
 */
.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }

.md-virtual-repeat-container .md-virtual-repeat-scroller {
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch; }

.md-virtual-repeat-container .md-virtual-repeat-sizer {
  box-sizing: border-box;
  height: 1px;
  display: block;
  margin: 0;
  padding: 0;
  width: 1px; }

.md-virtual-repeat-container .md-virtual-repeat-offsetter {
  box-sizing: border-box;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap; }

[dir=rtl] .md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  right: auto;
  left: auto; }

/* TRANSFORM / TRANSITION MIXINS */
/* Adds -webkit- prefix for transform (of 2d transformation) */
/* Adds -webkit- prefix for transition-duration */
/* Adds -webkit- prefix for transition timing function */
/* ANIMATIONS MIXINS */
/* SOME BASIC KEYFRAMES AND ANIMATIONS */

@keyframes keyframes-flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    transform: perspective(400px) rotateX(10deg); }
  100% {
    transform: perspective(400px) rotateX(0);
    opacity: 1; } }

@keyframes keyframes-flipOutX {
  0% {
    transform: perspective(400px) rotateX(0);
    opacity: 1; }
  40% {
    transform: perspective(400px) rotateX(10deg); }
  70% {
    transform: perspective(400px) rotateX(-10deg); }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
    display: none; } }

@keyframes keyframes-fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Animations */

@keyframes keyframes-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes keyframes-fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(8px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
meta.foundation-version {
  font-family: "1.2.0"; }

meta.foundation-mq {
  font-family: "small=0&smallish=540px&medium=768px&large=1024px&xlarge=1200px&xxlarge=1440px"; }

/*
  GLOBAL
  ------

  Global styles and settings for Foundation for Apps are stored here. This file must always
  be imported, no matter what.

  Includes:
   -
*/
@keyframes shake {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); }
  100% {
    transform: translateX(0); } }

@keyframes spin-cw {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin-ccw {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }

@keyframes wiggle {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

/*
    Transitions
  */
.slideInUp.ng-enter, .slideInUp.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateY(100%); }

.slideInUp.ng-enter.ng-enter-active, .slideInUp.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0) translateY(0); }

.slideInRight.ng-enter, .slideInRight.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateX(-100%); }

.slideInRight.ng-enter.ng-enter-active, .slideInRight.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0) translateY(0); }

.slideInDown.ng-enter, .slideInDown.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateY(-100%); }

.slideInDown.ng-enter.ng-enter-active, .slideInDown.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0) translateY(0); }

.slideInLeft.ng-enter, .slideInLeft.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateX(100%); }

.slideInLeft.ng-enter.ng-enter-active, .slideInLeft.ng-hide-remove.ng-hide-remove-active {
  transform: translateX(0) translateY(0); }

.slideOutUp.ng-leave, .slideOutUp.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateX(0) translateY(0); }

.slideOutUp.ng-leave.ng-leave-active, .slideOutUp.ng-hide-add.ng-hide-add-active {
  transform: translateY(-100%); }

.slideOutRight.ng-leave, .slideOutRight.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateX(0) translateY(0); }

.slideOutRight.ng-leave.ng-leave-active, .slideOutRight.ng-hide-add.ng-hide-add-active {
  transform: translateX(100%); }

.slideOutDown.ng-leave, .slideOutDown.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateX(0) translateY(0); }

.slideOutDown.ng-leave.ng-leave-active, .slideOutDown.ng-hide-add.ng-hide-add-active {
  transform: translateY(100%); }

.slideOutLeft.ng-leave, .slideOutLeft.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translateX(0) translateY(0); }

.slideOutLeft.ng-leave.ng-leave-active, .slideOutLeft.ng-hide-add.ng-hide-add-active {
  transform: translateX(-100%); }

.fadeIn.ng-enter, .fadeIn.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: opacity;
  opacity: 0; }

.fadeIn.ng-enter.ng-enter-active, .fadeIn.ng-hide-remove.ng-hide-remove-active {
  opacity: 1; }

.fadeOut.ng-leave, .fadeOut.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: opacity;
  opacity: 1; }

.fadeOut.ng-leave.ng-leave-active, .fadeOut.ng-hide-add.ng-hide-add-active {
  opacity: 0; }

.hingeInFromTop.ng-enter, .hingeInFromTop.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  opacity: 0; }

.hingeInFromTop.ng-enter.ng-enter-active, .hingeInFromTop.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  opacity: 1; }

.hingeInFromRight.ng-enter, .hingeInFromRight.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  opacity: 0; }

.hingeInFromRight.ng-enter.ng-enter-active, .hingeInFromRight.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  opacity: 1; }

.hingeInFromBottom.ng-enter, .hingeInFromBottom.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  opacity: 0; }

.hingeInFromBottom.ng-enter.ng-enter-active, .hingeInFromBottom.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  opacity: 1; }

.hingeInFromLeft.ng-enter, .hingeInFromLeft.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  opacity: 0; }

.hingeInFromLeft.ng-enter.ng-enter-active, .hingeInFromLeft.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  opacity: 1; }

.hingeInFromMiddleX.ng-enter, .hingeInFromMiddleX.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  opacity: 0; }

.hingeInFromMiddleX.ng-enter.ng-enter-active, .hingeInFromMiddleX.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  opacity: 1; }

.hingeInFromMiddleY.ng-enter, .hingeInFromMiddleY.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  opacity: 0; }

.hingeInFromMiddleY.ng-enter.ng-enter-active, .hingeInFromMiddleY.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0deg);
  opacity: 1; }

.hingeOutFromTop.ng-leave, .hingeOutFromTop.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0deg);
  transform-origin: top;
  opacity: 1; }

.hingeOutFromTop.ng-leave.ng-leave-active, .hingeOutFromTop.ng-hide-add.ng-hide-add-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hingeOutFromRight.ng-leave, .hingeOutFromRight.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0deg);
  transform-origin: right;
  opacity: 1; }

.hingeOutFromRight.ng-leave.ng-leave-active, .hingeOutFromRight.ng-hide-add.ng-hide-add-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hingeOutFromBottom.ng-leave, .hingeOutFromBottom.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0deg);
  transform-origin: bottom;
  opacity: 1; }

.hingeOutFromBottom.ng-leave.ng-leave-active, .hingeOutFromBottom.ng-hide-add.ng-hide-add-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hingeOutFromLeft.ng-leave, .hingeOutFromLeft.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0deg);
  transform-origin: left;
  opacity: 1; }

.hingeOutFromLeft.ng-leave.ng-leave-active, .hingeOutFromLeft.ng-hide-add.ng-hide-add-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hingeOutFromMiddleX.ng-leave, .hingeOutFromMiddleX.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0deg);
  transform-origin: center;
  opacity: 1; }

.hingeOutFromMiddleX.ng-leave.ng-leave-active, .hingeOutFromMiddleX.ng-hide-add.ng-hide-add-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hingeOutFromMiddleY.ng-leave, .hingeOutFromMiddleY.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0deg);
  transform-origin: center;
  opacity: 1; }

.hingeOutFromMiddleY.ng-leave.ng-leave-active, .hingeOutFromMiddleY.ng-hide-add.ng-hide-add-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.zoomIn.ng-enter, .zoomIn.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, property;
  transform: scale(1.5);
  opacity: 0; }

.zoomIn.ng-enter.ng-enter-active, .zoomIn.ng-hide-remove.ng-hide-remove-active {
  transform: scale(1);
  opacity: 1; }

.zoomOut.ng-leave, .zoomOut.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, property;
  transform: scale(0.5);
  opacity: 1; }

.zoomOut.ng-leave.ng-leave-active, .zoomOut.ng-hide-add.ng-hide-add-active {
  transform: scale(1);
  opacity: 0; }

.spinIn.ng-enter, .spinIn.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(-270deg);
  opacity: 0; }

.spinIn.ng-enter.ng-enter-active, .spinIn.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0);
  opacity: 1; }

.spinOut.ng-leave, .spinOut.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0);
  opacity: 1; }

.spinOut.ng-leave.ng-leave-active, .spinOut.ng-hide-add.ng-hide-add-active {
  transform: rotate(270deg);
  opacity: 0; }

.spinInCCW.ng-enter, .spinInCCW.ng-hide-remove {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(270deg);
  opacity: 0; }

.spinInCCW.ng-enter.ng-enter-active, .spinInCCW.ng-hide-remove.ng-hide-remove-active {
  transform: rotate(0);
  opacity: 1; }

.spinOutCCW.ng-leave, .spinOutCCW.ng-hide-add {
  transition-duration: 500ms;
  transition-timing-function: ease;
  transition-delay: 0;
  transition-property: transform, opacity;
  transform: rotate(0);
  opacity: 1; }

.spinOutCCW.ng-leave.ng-leave-active, .spinOutCCW.ng-hide-add.ng-hide-add-active {
  transform: rotate(-270deg);
  opacity: 0; }

/*
    Transition modifiers
  */
.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.easeIn {
  transition-timing-function: ease-in !important; }

.easeOut {
  transition-timing-function: ease-out !important; }

.easeInOut {
  transition-timing-function: ease-in-out !important; }

.bounceIn {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounceOut {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounceInOut {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

/*
    Animations
  */
.shake {
  animation-name: shake;
  animation-duration: 500ms;
  animation-timing-function: ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-delay: 0; }

.spin-cw {
  animation-name: spin-cw;
  animation-duration: 500ms;
  animation-timing-function: ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-delay: 0; }

.spin-ccw {
  animation-name: spin-ccw;
  animation-duration: 500ms;
  animation-timing-function: ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-delay: 0; }

.wiggle {
  animation-name: wiggle;
  animation-duration: 500ms;
  animation-timing-function: ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-delay: 0; }

/*
    Animation modifiers
  */
.shake.infinite,
.spin-cw.infinite,
.spin-ccw.infinite,
.wiggle.infinite {
  animation-iteration-count: infinite; }

.shake.linear,
.spin-cw.linear,
.spin-ccw.linear,
.wiggle.linear {
  animation-timing-function: linear !important; }

.shake.ease,
.spin-cw.ease,
.spin-ccw.ease,
.wiggle.ease {
  animation-timing-function: ease !important; }

.shake.easeIn,
.spin-cw.easeIn,
.spin-ccw.easeIn,
.wiggle.easeIn {
  animation-timing-function: ease-in !important; }

.shake.easeOut,
.spin-cw.easeOut,
.spin-ccw.easeOut,
.wiggle.easeOut {
  animation-timing-function: ease-out !important; }

.shake.easeInOut,
.spin-cw.easeInOut,
.spin-ccw.easeInOut,
.wiggle.easeInOut {
  animation-timing-function: ease-in-out !important; }

.shake.bounceIn,
.spin-cw.bounceIn,
.spin-ccw.bounceIn,
.wiggle.bounceIn {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.shake.bounceOut,
.spin-cw.bounceOut,
.spin-ccw.bounceOut,
.wiggle.bounceOut {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.shake.bounceInOut,
.spin-cw.bounceInOut,
.spin-ccw.bounceInOut,
.wiggle.bounceInOut {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.shake.slow,
.spin-cw.slow,
.spin-ccw.slow,
.wiggle.slow {
  animation-duration: 750ms !important; }

.shake.fast,
.spin-cw.fast,
.spin-ccw.fast,
.wiggle.fast {
  animation-duration: 250ms !important; }

.shake.delay,
.spin-cw.delay,
.spin-ccw.delay,
.wiggle.delay {
  animation-delay: 300ms !important; }

.shake.long-delay,
.spin-cw.long-delay,
.spin-ccw.long-delay,
.wiggle.long-delay {
  animation-delay: 700ms !important; }

.stagger {
  transition-delay: 150ms;
  transition-duration: 0; }

.stort-stagger {
  transition-delay: 150ms;
  transition-duration: 0; }

.long-stagger {
  transition-delay: 150ms;
  transition-duration: 0; }

.position-absolute {
  overflow: hidden;
  position: relative; }

[ui-view].ng-enter-active, [ui-view].ng-leave-active {
  position: absolute !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/*
  MODAL
  -----

  The humble modal hides off-canvas until summoned with an fa-open directive. Modals appear over an overlay that darkens the rest of the page, and have a maxmimum width. You can construct a grid inside a modal, or attach panels to it.

  Note that the modal overlay is hardcoded into the CSS, because whether or not you build your modal semantically, the overlay is always required and will always look the same.
*/
.modal {
  position: relative;
  z-index: 1101;
  background: #ececed;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  padding: 1rem; }
  @media only screen and (min-width: 48em) {
    .modal {
      height: auto;
      max-width: 600px; } }
  .modal .grid-content, .modal .grid-block {
    margin: 0; }
  .modal .close-button, .modal [fa-close] {
    z-index: 1101; }

.modal {
  max-width: 600px; }
  .tiny > .modal {
    max-width: 300px; }
  .small > .modal {
    max-width: 500px; }
  .large > .modal {
    max-width: 100%; }
  .dialog > .modal {
    height: auto; }
  .collapse > .modal {
    padding: 0; }

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  display: none;
  background-color: rgba(51, 51, 51, 0.7);
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -webkit-transform: translate3d(0, 0, 0); }
  .modal-overlay.is-active {
    display: -ms-flexbox;
    display: flex; }

/*
  NOTIFICATION
  ------------

  An alert that pins to the corner of the screen when triggered by JavaScript. It can be set to disappear after a certain period of time, or to stay put until the user clicks on it. A custom action can be asigned to a notification as well.

  Optionally, the notifications directive can also tap into the browser's native notification support, if it exists.
*/
.notification, .static-notification {
  z-index: 1000;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: none; }
  .notification h1, .static-notification h1 {
    font-size: 1.25em;
    margin: 0; }
  .notification p, .static-notification p {
    margin: 0; }
  .is-active.notification, .is-active.static-notification {
    display: -ms-flexbox;
    display: flex; }
  .notification .close-button, .static-notification .close-button {
    color: white; }

.notification-container {
  z-index: 3000;
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

/*
  CSS Output
*/
.notification {
  background: #3399ff;
  padding: 1rem;
  border-radius: 0; }
  .notification, .notification h1, .notification h2, .notification h3, .notification h4, .notification h5, .notification h6 {
    color: #ffffff; }
  .notification.success {
    background: #3adb76;
    padding: 1rem;
    border-radius: 0; }
    .notification.success, .notification.success h1, .notification.success h2, .notification.success h3, .notification.success h4, .notification.success h5, .notification.success h6 {
      color: #ffffff; }
  .notification.warning {
    background: #ffae00;
    padding: 1rem;
    border-radius: 0; }
    .notification.warning, .notification.warning h1, .notification.warning h2, .notification.warning h3, .notification.warning h4, .notification.warning h5, .notification.warning h6 {
      color: #ffffff; }
  .notification.alert {
    background: #cc4b37;
    padding: 1rem;
    border-radius: 0; }
    .notification.alert, .notification.alert h1, .notification.alert h2, .notification.alert h3, .notification.alert h4, .notification.alert h5, .notification.alert h6 {
      color: #ffffff; }
  .notification.dark {
    background: #232323;
    padding: 1rem;
    border-radius: 0; }
    .notification.dark, .notification.dark h1, .notification.dark h2, .notification.dark h3, .notification.dark h4, .notification.dark h5, .notification.dark h6 {
      color: #fff; }

.static-notification {
  background: #3399ff;
  padding: 1rem;
  border-radius: 0;
  position: fixed !important; }
  .static-notification, .static-notification h1, .static-notification h2, .static-notification h3, .static-notification h4, .static-notification h5, .static-notification h6 {
    color: #ffffff; }
  .static-notification.top-right {
    width: 25rem;
    right: 0;
    top: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .static-notification.top-right {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .static-notification.top-left {
    width: 25rem;
    left: 0;
    top: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .static-notification.top-left {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .static-notification.top-middle {
    width: 25rem;
    left: 50%;
    margin-left: -12.5rem;
    top: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .static-notification.top-middle {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .static-notification.bottom-right {
    width: 25rem;
    right: 0;
    top: auto;
    bottom: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .static-notification.bottom-right {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .static-notification.bottom-left {
    width: 25rem;
    left: 0;
    top: auto;
    bottom: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .static-notification.bottom-left {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .static-notification.bottom-middle {
    width: 25rem;
    left: 50%;
    margin-left: -12.5rem;
    top: auto;
    bottom: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .static-notification.bottom-middle {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .static-notification.success {
    background: #3adb76;
    padding: 1rem;
    border-radius: 0; }
    .static-notification.success, .static-notification.success h1, .static-notification.success h2, .static-notification.success h3, .static-notification.success h4, .static-notification.success h5, .static-notification.success h6 {
      color: #ffffff; }
  .static-notification.warning {
    background: #ffae00;
    padding: 1rem;
    border-radius: 0; }
    .static-notification.warning, .static-notification.warning h1, .static-notification.warning h2, .static-notification.warning h3, .static-notification.warning h4, .static-notification.warning h5, .static-notification.warning h6 {
      color: #ffffff; }
  .static-notification.alert {
    background: #cc4b37;
    padding: 1rem;
    border-radius: 0; }
    .static-notification.alert, .static-notification.alert h1, .static-notification.alert h2, .static-notification.alert h3, .static-notification.alert h4, .static-notification.alert h5, .static-notification.alert h6 {
      color: #ffffff; }
  .static-notification.dark {
    background: #232323;
    padding: 1rem;
    border-radius: 0; }
    .static-notification.dark, .static-notification.dark h1, .static-notification.dark h2, .static-notification.dark h3, .static-notification.dark h4, .static-notification.dark h5, .static-notification.dark h6 {
      color: #fff; }

.notification-container {
  width: 25rem;
  right: 0;
  top: 0; }
  @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
    .notification-container {
      width: auto;
      left: 0;
      right: 0;
      margin-left: 0; } }
  .notification-container.top-right {
    width: 25rem;
    right: 0;
    top: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .notification-container.top-right {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .notification-container.top-left {
    width: 25rem;
    left: 0;
    top: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .notification-container.top-left {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .notification-container.top-middle {
    width: 25rem;
    left: 50%;
    margin-left: -12.5rem;
    top: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .notification-container.top-middle {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .notification-container.bottom-right {
    width: 25rem;
    right: 0;
    top: auto;
    bottom: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .notification-container.bottom-right {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .notification-container.bottom-left {
    width: 25rem;
    left: 0;
    top: auto;
    bottom: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .notification-container.bottom-left {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }
  .notification-container.bottom-middle {
    width: 25rem;
    left: 50%;
    margin-left: -12.5rem;
    top: auto;
    bottom: 0; }
    @media only screen and (min-width: 0em) and (max-width: 539.9375px) {
      .notification-container.bottom-middle {
        width: auto;
        left: 0;
        right: 0;
        margin-left: 0; } }

.notification-icon {
  -ms-flex: 0 0 60px;
      flex: 0 0 60px;
  margin-right: 1rem;
  -ms-flex-item-align: start;
      align-self: flex-start; }
  .notification-icon img {
    width: 100%;
    height: auto; }

.notification-content {
  -ms-flex: 1;
      flex: 1; }

[zf-open],
[zf-close],
[zf-toggle] {
  cursor: pointer; }

.notification-container {
  z-index: 2000; }

.avatar {
  position: relative;
  display: inline-block;
  width: 4.625rem;
  height: 4.625rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #40bfa3; }

.avatar__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  height: 100%; }

.avatar__image {
  width: 4.625rem; }

.avatar__name {
  color: #ffffff;
  font-size: 2rem; }

.avatar__icon {
  margin: 0 !important; }
  .avatar__icon.icon {
    color: #ffffff;
    width: 86.48649%;
    height: 86.48649%; }
  .avatar__icon.icon svg {
    width: 100%;
    height: 100%; }

.moneybar .row.divider {
  margin-top: 0;
  margin-bottom: 0; }

.moneybar__fill-container {
  background-color: #bfd994; }

.moneybar__fill {
  background-color: #a7c975; }

.accounts .moneybar__fill-container {
  background-color: #40bfa3; }

.accounts .moneybar__fill {
  background-color: #40bfa3; }

.cards .moneybar__fill-container {
  background-color: #a6d6bd; }

.cards .moneybar__fill {
  background-color: #89c5a4; }

.cards-credit .moneybar__fill-container {
  background-color: #a6d6bd; }

.cards-credit .moneybar__fill {
  background-color: #89c5a4; }

.cards-debit .moneybar__fill-container {
  background-color: #a6d6bd; }

.cards-debit .moneybar__fill {
  background-color: #89c5a4; }

.cards-combined .moneybar__fill-container {
  background-color: #a6d6bd; }

.cards-combined .moneybar__fill {
  background-color: #89c5a4; }

.is-inactive .moneybar__fill-container {
  background-color: #ffe183; }

.is-inactive .moneybar__fill {
  background-color: #000000; }

.is-blocked .moneybar__fill-container {
  background-color: #b00000; }

.is-blocked .moneybar__fill {
  background-color: #ffffff; }

.is-new .moneybar__fill-container {
  background-color: #006060; }

.is-new .moneybar__fill {
  background-color: #ffffff; }

.status-inactive .moneybar__fill-container {
  background-color: #fbd9ca; }

.status-inactive .moneybar__fill {
  background-color: #f0c1ae; }

.status-blocked .moneybar__fill-container {
  background-color: #fbd9ca; }

.status-blocked .moneybar__fill {
  background-color: #f0c1ae; }

.loans .moneybar__fill-container {
  background-color: #a6d6bd; }

.loans .moneybar__fill {
  background-color: #89c5a4; }

.loans-credit .moneybar__fill-container {
  background-color: #a6d6bd; }

.loans-credit .moneybar__fill {
  background-color: #89c5a4; }

.moneybar {
  cursor: pointer; }
  .moneybar__content.ng-enter-prepare {
    display: none; }
  .moneybar__content.ng-enter, .moneybar__content.ng-enter-active {
    display: initial; }
  .moneybar__fill-container {
    position: relative; }
  .moneybar__fill {
    height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    display: block;
    position: absolute; }
  .moneybar .column {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .moneybar .column.row {
      overflow: visible; }
  .moneybar__card-image {
    border-radius: 5px;
    position: relative;
    overflow: hidden; }
    .moneybar__card-image img {
      height: 75px;
      width: auto; }
    .moneybar__card-image .moneybar__card-status {
      position: absolute;
      bottom: 0.5rem;
      width: 100%;
      padding: 0.25rem 0;
      font-size: 0.8125rem;
      color: #ffffff; }
      .moneybar__card-image .moneybar__card-status.is-new {
        background-color: #006060;
        color: #ffffff; }
      .moneybar__card-image .moneybar__card-status.is-blocked {
        background-color: #b00000;
        color: #ffffff; }
      .moneybar__card-image .moneybar__card-status.is-inactive, .moneybar__card-image .moneybar.is-inactive .moneybar__card-status, .moneybar.is-inactive .moneybar__card-image .moneybar__card-status {
        background-color: #ffe183;
        color: #000000; }
  .moneybar__details-container {
    background-color: #ffffff; }
  .moneybar h2,
  .moneybar span.label,
  .moneybar .compact .label {
    margin-right: 0.5rem; }
  .moneybar .label {
    overflow: hidden;
    text-overflow: ellipsis; }
  .moneybar--expanded .moneybar__fill-container {
    height: 11rem;
    -ms-flex-line-pack: justify;
        align-content: space-between; }
  .moneybar--expanded .moneybar__subcontent--topleft {
    height: 1px;
    overflow: visible;
    float: left; }
  .moneybar--expanded .divider {
    border: 0;
    margin-top: 0;
    margin-bottom: 0; }
  .moneybar--expanded h2 {
    display: inline; }
  .moneybar--collapsed .moneybar__fill-container {
    padding: 0.25rem 0;
    margin: 0.3125rem 0; }
  .moneybar--collapsed .moneybar__content {
    padding: 1.5rem 0; }
  .moneybar__content {
    padding: 1rem 0; }
    .moneybar__content.compact {
      -ms-flex-align: center;
          align-items: center;
      padding: 1rem 0; }
  .moneybar .compact .moneybar__fill-container, .moneybar .compact .moneybar__card-image {
    display: none; }
  .moneybar .compact .label {
    display: inline; }

.month-calendar-header {
  font-size: 1.25rem;
  background-color: #0000a0;
  height: 3.25rem;
  line-height: 3.25rem;
  text-align: center;
  color: #fdece4; }
  .month-calendar-header .icon {
    color: #fdece4; }
  .month-calendar-header span {
    display: inline-block; }
  .month-calendar-header__content {
    width: 100%;
    position: relative; }
  .month-calendar-header__nav {
    position: absolute;
    cursor: pointer;
    min-width: 1rem;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    .month-calendar-header__nav:hover {
      color: #3399ff; }
    .month-calendar-header__nav--previous {
      top: 18px;
      left: 24px; }
    .month-calendar-header__nav--next {
      top: 18px;
      right: 24px; }
    .month-calendar-header__nav--invisible {
      opacity: 0;
      cursor: auto; }
  .month-calendar-header__title {
    min-width: 9rem; }
  .month-calendar-header__month-name {
    text-transform: capitalize; }
  .month-calendar-header__close {
    height: 2rem;
    position: absolute;
    top: 0.625rem;
    right: 1rem;
    cursor: pointer; }
  .month-calendar-header--dark-pink {
    background-color: #f0c1ae;
    color: #000000; }
    .month-calendar-header--dark-pink .icon {
      color: #0000a0; }
    .month-calendar-header--dark-pink .month-calendar-header__nav:hover {
      color: #3399ff; }
    .month-calendar-header--dark-pink .month-calendar-header__nav--invisible {
      opacity: 0;
      cursor: auto; }

.month-calendar {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }
  .month-calendar__title {
    text-align: left;
    padding-top: 0.75rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem; }
  .month-calendar__title-cell {
    color: #8b8a8d;
    padding: 1rem 0.25rem 0.25rem; }
  .month-calendar__value-cell {
    color: #000000;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
    .month-calendar__value-cell.month-calendar__value-cell--highlighted {
      background-color: #fbd9ca; }
    .month-calendar__value-cell.month-calendar__value-cell--highlighted-start {
      background: linear-gradient(to right, white 0%, white 50%, #fbd9ca 50%); }
    .month-calendar__value-cell.month-calendar__value-cell--highlighted-end {
      background: linear-gradient(to right, #fbd9ca 50%, white 50%, white 0%); }
  .month-calendar__day {
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%; }
    .month-calendar__day:hover:not(.month-calendar__day--disabled), .month-calendar__day.month-calendar__day--selected, .month-calendar__day.month-calendar__day--selected.month-calendar__day--today {
      color: #fdece4;
      background-color: #0000a0; }
    .month-calendar__day.month-calendar__day--today {
      background-color: #ffffff;
      border: 1px solid #0000a0; }
    .month-calendar__day.month-calendar__day--disabled {
      color: #c9c7c7;
      cursor: default; }
    .month-calendar__day.month-calendar__day--sunday {
      color: #fc6161; }

.colors-negative .month-calendar-header {
  background-color: #f0c1ae;
  color: #000000; }
  .colors-negative .month-calendar-header .icon {
    color: #0000a0; }
  .colors-negative .month-calendar-header .month-calendar-header__nav:hover {
    color: #3399ff; }
  .colors-negative .month-calendar-header .month-calendar-header__nav--invisible {
    opacity: 0;
    cursor: auto; }

.datePicker {
  display: inline-block;
  position: relative; }
  .datePicker td {
    outline: none; }

.colors-negative .datePicker-opened .icon {
  background-color: #f0c1ae;
  color: #0000a0;
  border-radius: 50%; }

.datePicker-panel {
  opacity: 0;
  background-color: #ffffff;
  position: absolute;
  z-index: 100;
  display: none; }
  .datePicker-panel:last-child {
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .datePicker-panel--visible {
    display: block;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-iteration-count: initial;
    animation-name: keyframes-fadeIn;
    animation-direction: initial;
    animation-fill-mode: both; }
  .datePicker-panel--single {
    width: 19rem; }
    .datePicker-panel--single .datePicker-month {
      width: 100%; }
  .datePicker-panel--double {
    width: 38rem; }
    .datePicker-panel--double .datePicker-month {
      width: 50%; }
  .datePicker-panel--top-left {
    top: 0;
    left: 0; }
  .datePicker-panel--top-right {
    top: 0;
    right: 0; }
  .datePicker-panel--bottom-left {
    bottom: 0;
    left: 0; }
  .datePicker-panel--bottom-right {
    bottom: 0;
    right: 0; }
  .datePicker-panel--static {
    position: static; }

md-input-container .datePicker-panel--top-left {
  top: 40px;
  left: 0; }

md-input-container .datePicker-panel--top-right {
  top: 40px;
  right: 0; }

.datePicker-month {
  display: inline-block; }

.datePicker-buttons {
  text-align: center;
  cursor: pointer;
  padding: 0 0 1rem 0;
  color: #0000a0; }
  .datePicker-buttons span {
    padding: 0 0.75rem; }
    .datePicker-buttons span:hover {
      color: #3399ff; }

md-input-container.nd-icon-right-inside > nd-date-picker.icon:last-of-type {
  left: 0; }

.md-panel-outer-wrapper .nd-panel__wrapper {
  transform: scale(0.1);
  transform-origin: left top 0;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 1rem; }

.md-panel-outer-wrapper .nd-panel__wrapper:focus {
  outline: none; }

.md-panel-outer-wrapper .nd-panel__animation-open {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 100ms; }
  .md-panel-outer-wrapper .nd-panel__animation-open > .nd-panel__wrapper {
    transform: scale(1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 250ms; }

.md-panel-outer-wrapper .nd-panel__animation-close {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition-duration: 200ms; }
  .md-panel-outer-wrapper .nd-panel__animation-close > .nd-panel__wrapper {
    transform: scale(1); }

.md-panel-outer-wrapper .md-scroll-mask {
  pointer-events: none; }

.nd-panel__button {
  -webkit-appearance: button;
  -moz-appearance: button; }

.nd-panel__button {
  font-size: inherit;
  line-height: inherit; }

.nd-panel__button:focus {
  outline: 2px solid #3399ff; }

@media (-webkit-min-device-pixel-ratio: 0) {
  .nd-panel__button:focus {
    outline: -webkit-focus-ring-color auto; } }

[data-whatinput='mouse'] .nd-panel__button {
  outline: none; }

/* stylelint-disable */
@keyframes sliderFocusThumb {
  0% {
    transform: scale(0.7); }
  30% {
    transform: scale(1); }
  100% {
    transform: scale(0.7); } }

@keyframes sliderDiscreteFocusThumb {
  0% {
    transform: scale(0.7); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(0); } }

@keyframes sliderDiscreteFocusRing {
  0% {
    transform: scale(0.7);
    opacity: 0; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0); } }

nd-range-slider {
  height: 48px;
  min-width: 128px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  display: block;
  -ms-flex-direction: row;
      flex-direction: row;
  /**
  * Track
  */
  /**
  * Slider thumb
  */
  /* The sign that's focused in discrete mode */
  /**
  * The border/background that comes in when focused in non-discrete mode
  */
  /* Don't animate left/right while panning */ }
  nd-range-slider *,
  nd-range-slider *::after {
    box-sizing: border-box; }
  nd-range-slider .md-slider-wrapper {
    outline: none;
    width: 100%;
    height: 100%; }
  nd-range-slider .md-slider-content {
    position: relative; }
  nd-range-slider .md-track-container {
    width: 100%;
    position: absolute;
    top: 23px;
    height: 2px; }
  nd-range-slider .md-track {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  nd-range-slider .md-track-fill {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: width, height; }
  nd-range-slider .md-track-ticks {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  nd-range-slider .md-track-ticks canvas {
    width: 100%;
    height: 100%; }
  nd-range-slider .md-thumb-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: left, bottom; }
  nd-range-slider .md-thumb {
    z-index: 1;
    position: absolute;
    left: -10px;
    top: 14px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    nd-range-slider .md-thumb::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border-width: 3px;
      border-style: solid;
      transition: inherit; }
  nd-range-slider .md-thumb2 {
    z-index: 1;
    position: absolute;
    left: -10px;
    top: 14px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    nd-range-slider .md-thumb2::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border-width: 3px;
      border-style: solid;
      transition: inherit; }
  nd-range-slider .md-sign {
    /* Center the children (slider-thumb-text) */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    position: absolute;
    left: -14px;
    top: -17px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.4) translate3d(0, 67.5px, 0);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    /* The arrow pointing down under the sign */ }
    nd-range-slider .md-sign::after {
      position: absolute;
      content: '';
      left: 0px;
      border-radius: 16px;
      top: 19px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top-width: 16px;
      border-top-style: solid;
      opacity: 0;
      transform: translate3d(0, -8px, 0);
      transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1); }
    nd-range-slider .md-sign .md-thumb-text {
      z-index: 1;
      font-size: 12px;
      font-weight: bold; }
  nd-range-slider .md-focus-ring {
    position: absolute;
    left: -17px;
    top: 7px;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
  nd-range-slider .md-disabled-thumb {
    position: absolute;
    left: -14px;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.5);
    border-width: 4px;
    border-style: solid;
    display: none; }
  nd-range-slider.md-min .md-sign {
    opacity: 0; }
  nd-range-slider:focus {
    outline: none; }
  nd-range-slider.md-dragging .md-thumb-container,
  nd-range-slider.md-dragging .md-track-fill {
    transition: none; }
  nd-range-slider:not([md-discrete]) {
    /* Hide the sign and ticks in non-discrete mode */ }
    nd-range-slider:not([md-discrete]) .md-track-ticks,
    nd-range-slider:not([md-discrete]) .md-sign {
      display: none; }
    nd-range-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper .md-thumb:hover {
      transform: scale(0.8); }
    nd-range-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
      transform: scale(1);
      opacity: 1; }
    nd-range-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
      animation: sliderFocusThumb 0.7s cubic-bezier(0.35, 0, 0.25, 1); }
    nd-range-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb {
      transform: scale(1); }
  nd-range-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
    transform: scale(0);
    animation: sliderDiscreteFocusRing 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  nd-range-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
    animation: sliderDiscreteFocusThumb 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  nd-range-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb, nd-range-slider[md-discrete]:not([disabled]).md-active .md-thumb {
    transform: scale(0); }
  nd-range-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign,
  nd-range-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign:after, nd-range-slider[md-discrete]:not([disabled]).md-active .md-sign,
  nd-range-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  nd-range-slider[md-discrete][disabled][readonly] .md-thumb {
    transform: scale(0); }
  nd-range-slider[md-discrete][disabled][readonly] .md-sign,
  nd-range-slider[md-discrete][disabled][readonly] .md-sign::after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  nd-range-slider[disabled] .md-track-fill {
    display: none; }
  nd-range-slider[disabled] .md-track-ticks {
    opacity: 0; }
  nd-range-slider[disabled]:not([readonly]) .md-sign {
    opacity: 0; }
  nd-range-slider[disabled] .md-thumb {
    transform: scale(0.5); }
  nd-range-slider[disabled] .md-disabled-thumb {
    display: block; }
  nd-range-slider[md-vertical] {
    -ms-flex-direction: column;
        flex-direction: column;
    min-height: 128px;
    min-width: 0; }
    nd-range-slider[md-vertical] .md-slider-wrapper {
      -ms-flex: 1;
          flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 48px;
      -ms-flex-item-align: center;
          align-self: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center; }
    nd-range-slider[md-vertical] .md-track-container {
      height: 100%;
      width: 2px;
      top: 0;
      left: calc(50% - (2px / 2)); }
    nd-range-slider[md-vertical] .md-thumb-container {
      top: auto;
      margin-bottom: 23px;
      left: calc(50% - 1px);
      bottom: 0; }
      nd-range-slider[md-vertical] .md-thumb-container .md-thumb::after {
        left: 1px; }
      nd-range-slider[md-vertical] .md-thumb-container .md-focus-ring {
        left: -16px; }
    nd-range-slider[md-vertical] .md-track-fill {
      bottom: 0; }
    nd-range-slider[md-vertical][md-discrete] .md-sign {
      left: -40px;
      top: 9.5px;
      transform: scale(0.4) translate3d(67.5px, 0, 0);
      /* The arrow pointing left next the sign */ }
      nd-range-slider[md-vertical][md-discrete] .md-sign::after {
        top: 9.5px;
        left: 19px;
        border-top: 14px solid transparent;
        border-right: 0;
        border-bottom: 14px solid transparent;
        border-left-width: 16px;
        border-left-style: solid;
        opacity: 0;
        transform: translate3d(0, -8px, 0);
        transition: all 0.2s ease-in-out; }
      nd-range-slider[md-vertical][md-discrete] .md-sign .md-thumb-text {
        z-index: 1;
        font-size: 12px;
        font-weight: bold; }
    nd-range-slider[md-vertical][md-discrete].md-active .md-sign::after,
    nd-range-slider[md-vertical][md-discrete] .md-focused .md-sign::after, nd-range-slider[md-vertical][md-discrete][disabled][readonly] .md-sign::after {
      top: 0; }
    nd-range-slider[md-vertical][disabled][readonly] .md-thumb {
      transform: scale(0); }
    nd-range-slider[md-vertical][disabled][readonly] .md-sign,
    nd-range-slider[md-vertical][disabled][readonly] .md-sign::after {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
  nd-range-slider[md-invert]:not([md-vertical]) .md-track-fill {
    left: auto;
    right: 0; }
  nd-range-slider[md-invert][md-vertical] .md-track-fill {
    bottom: auto;
    top: 0; }

md-slider-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: row;
      flex-direction: row; }
  md-slider-container > *:first-child:not(md-slider),
  md-slider-container > *:last-child:not(md-slider) {
    min-width: 25px;
    max-width: 42px;
    height: 25px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: color, max-width; }
  md-slider-container > *:first-child:not(md-slider) {
    margin-right: 16px; }
  md-slider-container > *:last-child:not(md-slider) {
    margin-left: 16px; }
  md-slider-container[md-vertical] {
    -ms-flex-direction: column;
        flex-direction: column; }
    md-slider-container[md-vertical] > *:first-child:not(md-slider),
    md-slider-container[md-vertical] > *:last-child:not(md-slider) {
      margin-right: 0;
      margin-left: 0;
      text-align: center; }
  md-slider-container md-input-container input[type='number'] {
    text-align: center;
    padding-left: 15px;
    height: 50px;
    margin-top: -25px; }

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff; } }

/* stylelint-enable */
nd-range-slider .md-track-container {
  height: 6px; }

nd-range-slider .md-track {
  background-color: #e6e4e3;
  border-radius: 3px; }

nd-range-slider .md-track-fill {
  background-color: #0000a0;
  transition-property: width, height, left, top; }

nd-range-slider[disabled] .md-track-fill {
  display: block;
  background: #c9c7c7; }

nd-range-slider .nd-range-slider-track-label {
  position: absolute;
  top: 10px;
  font-size: 0.8125rem; }

nd-range-slider .nd-range-slider-track-label-min {
  left: 0; }

nd-range-slider .nd-range-slider-track-label-max {
  right: 0; }

nd-range-slider[disabled] .md-thumb {
  transform: scale(0.7); }

nd-range-slider[disabled] .md-thumb::after {
  background: #c9c7c7;
  border-color: #c9c7c7; }

nd-range-slider .md-disabled-thumb {
  border-color: #c9c7c7; }

nd-range-slider .md-focus-ring {
  background-color: rgba(0, 0, 160, 0.4);
  top: 8px;
  left: -18px; }

nd-range-slider .md-slider-wrapper.md-focused .md-focus-ring {
  display: none; }

nd-range-slider .md-slider-wrapper.md-focused .md-thumb {
  transform: scale(1);
  animation: none !important; }

nd-range-slider .md-slider-wrapper.md-focused .md-thumb::after {
  background: #ffffff; }

nd-range-slider .md-thumb {
  width: 28px;
  height: 28px;
  left: -14px;
  top: 12px; }
  nd-range-slider .md-thumb::after {
    background-color: #0000a0;
    border-color: #0000a0;
    border-width: 5px;
    width: 28px;
    height: 28px; }

nd-range-slider.md-active .md-slider-wrapper .md-thumb::after {
  background: #ffffff; }

nd-range-slider .md-sign {
  transform: none;
  text-align: center;
  top: -4px; }
  nd-range-slider .md-sign .md-thumb-text {
    font-size: 1rem;
    font-weight: inherit; }

nd-range-slider.md-min .md-thumb-container .md-sign,
nd-range-slider.md-max .md-thumb-container .md-sign {
  opacity: 1; }

nd-range-slider:not([md-discrete]) {
  /* do not hide the sign and ticks in non-discrete mode */ }
  nd-range-slider:not([md-discrete]) .md-track-ticks,
  nd-range-slider:not([md-discrete]) .md-sign {
    display: inline-block; }

nd-range-slider.colors-negative .md-track,
.colors-negative nd-range-slider .md-track {
  background-color: #e6e4e3; }

nd-range-slider.colors-negative .md-track-fill,
.colors-negative nd-range-slider .md-track-fill {
  background-color: #ffffff; }

nd-range-slider.colors-negative .md-thumb::after,
.colors-negative nd-range-slider .md-thumb::after {
  background-color: #ffffff;
  border-color: #ffffff; }

nd-range-slider.colors-negative[disabled] .md-track-fill,
.colors-negative nd-range-slider[disabled] .md-track-fill {
  background: #e6e4e3; }

nd-range-slider.colors-negative[disabled] .md-thumb::after,
.colors-negative nd-range-slider[disabled] .md-thumb::after {
  background: #e6e4e3;
  border-color: #e6e4e3; }

nd-range-slider.colors-negative.md-active .md-slider-wrapper .md-thumb::after,
.colors-negative nd-range-slider.md-active .md-slider-wrapper .md-thumb::after {
  background: #e6e4e3; }

.nd-spinner {
  width: 100%;
  position: relative;
  pointer-events: none; }
  .nd-spinner:focus {
    outline: none; }

.nd-spinner__progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .nd-spinner__progress:focus {
    outline: none; }

nd-button-expand {
  display: inline-block; }
  nd-button-expand .button--expand {
    vertical-align: middle;
    position: relative;
    border-radius: 0;
    padding: 0.5rem 1.2rem 0.3rem 0;
    background-color: transparent;
    color: #0000a0;
    border: none; }
    nd-button-expand .button--expand:hover, nd-button-expand .button--expand:focus {
      background-color: transparent !important; }
    nd-button-expand .button--expand::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0.25rem;
      left: 0;
      width: 100%; }
    nd-button-expand .button--expand.button--icon-right .icon:last-of-type {
      right: -0.15rem;
      margin-top: 0.125rem; }
  nd-button-expand:hover .button--expand::after {
    border-bottom: 1px solid #0000a0; }

.button--expand__text {
  display: inline-block;
  vertical-align: middle; }

.dialog {
  overflow: auto;
  height: auto;
  max-height: 90vh;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 0; }
  .dialog__container {
    position: relative;
    overflow: hidden;
    padding: 1.75rem 1.5rem 0.75rem 1.5rem; }
  .dialog__content__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 1rem;
    -ms-flex-direction: column;
        flex-direction: column; }
    @media (min-width: 540px) {
      .dialog__content__container {
        -ms-flex-direction: row;
            flex-direction: row; } }
  .dialog__icon {
    padding-right: 0;
    padding-bottom: 1rem; }
    @media (min-width: 540px) {
      .dialog__icon {
        padding-right: 1rem;
        padding-bottom: 0; } }
    .dialog__icon--x-dark-gray .icon {
      color: #474748; }
    .dialog__icon--medium-gray .icon {
      color: #8b8a8d; }
    .dialog__icon--dark-green .icon {
      color: #006060; }
    .dialog__icon--dark-red .icon {
      color: #b00000; }
  .dialog__header {
    margin-bottom: 1.75rem;
    box-sizing: border-box;
    color: #000000; }
    .dialog__header > h2 {
      font-weight: 500;
      font-size: 1.25rem;
      margin-bottom: 0; }
  .dialog__body {
    overflow: auto;
    max-height: calc(90vh - 8rem);
    text-align: center; }
    @media (min-width: 540px) {
      .dialog__body {
        text-align: left; } }
  .dialog__footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
        justify-content: flex-end;
    width: 100%; }

.nd-modal-hide-scrollbar {
  overflow: hidden !important; }

.modal {
  overflow: auto;
  height: auto;
  max-height: 90vh;
  background-color: #ffffff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 0; }
  .large > .modal {
    min-height: 100%;
    -ms-flex-item-align: start;
        align-self: flex-start; }
  .modal__container {
    position: relative;
    overflow: hidden; }
  .modal__header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    height: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    box-sizing: border-box;
    color: #000000;
    background-color: #ececed; }
    .modal__header > h2 {
      margin-bottom: 0;
      font-size: 1.25rem;
      font-weight: 500; }
    .modal__header .close-button {
      position: static;
      font-size: 1rem;
      line-height: normal; }
      .modal__header .close-button > nd-icon {
        color: #8b8a8d;
        /* stylelint-disable */
        /* stylelint-enable */ }
        .modal__header .close-button > nd-icon:hover {
          opacity: 0.5; }
  .modal__body {
    overflow: auto;
    margin-bottom: 4rem;
    padding: 1.5rem;
    max-height: calc(90vh - 8rem); }
  .modal__footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: end;
        justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: white;
    border-top: 1px solid #e6e4e3; }

.modal-overlay {
  background: rgba(20, 20, 36, 0.45); }

.nd-modal-hide-scrollbar {
  overflow: hidden !important; }

.timePicker__header, .timePicker__arrows-top, .timePicker__arrows-bottom, .timePicker__numbers, .timePicker__controls {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.timePicker {
  display: inline-block;
  position: relative; }
  .timePicker input:focus {
    outline: none; }
  .timePicker__header {
    height: 48px;
    width: 11.875rem;
    padding: 0;
    background-color: #0000a0;
    color: #ffffff;
    font-size: 1.25rem; }
  .timePicker__main {
    height: 228px;
    width: 11.875rem;
    line-height: 1;
    top: 0;
    left: 0;
    display: inline-block;
    position: absolute;
    border: 1px #e6e4e3;
    color: #000000;
    padding: 0;
    background: #ffffff;
    z-index: 200;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1); }
  .timePicker__input {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #e6e4e3;
    font-size: 2rem;
    width: 45px; }
  .timePicker__inputSign {
    font-size: 2rem; }
  .timePicker__buttons {
    color: #0000a0;
    margin-top: 0.5rem; }
    .timePicker__buttons:hover {
      color: #3399ff; }
  .timePicker__arrows-top {
    height: 16px; }
    .timePicker__arrows-top button {
      margin-top: -0.5rem; }
      .timePicker__arrows-top button .icon {
        margin-top: -1.5rem;
        margin-bottom: -0.8rem; }
  .timePicker__arrows-bottom {
    height: 16px; }
    .timePicker__arrows-bottom button {
      margin-top: -1.5rem; }
      .timePicker__arrows-bottom button .icon {
        margin-top: -1.5rem;
        margin-bottom: -1.5rem; }
  .timePicker__numbers {
    margin-top: 0.5rem;
    margin-bottom: 1rem; }
  .timePicker__controls {
    margin-top: 1rem; }
  .timePicker__actionarea {
    height: 180px;
    margin: 1.5rem; }
    .timePicker__actionarea .icon--small,
    .timePicker__actionarea .icon--small svg {
      width: 3rem;
      height: 3rem; }
      .timePicker__actionarea .icon--small:hover,
      .timePicker__actionarea .icon--small svg:hover {
        color: #3399ff; }

.colors-negative .timePicker__header {
  background-color: #f0c1ae;
  color: #000000; }

.colors-negative .timePicker__icons {
  color: #0000a0; }

.nd-steps {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
  .nd-steps__step {
    -ms-flex: 1 1;
        flex: 1 1;
    text-align: center;
    display: -ms-flexbox;
    display: flex; }
    .nd-steps__step--active .nd-steps__step-bubble {
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      margin: 0;
      margin-bottom: 1rem;
      background-color: #0000a0;
      color: #ffffff; }
    .nd-steps__step--done .nd-steps__step-bubble {
      border: 1px solid #40bfa3; }
  .nd-steps__checkmark-icon {
    width: 1rem; }
    .nd-steps__checkmark-icon g {
      fill: #40bfa3; }
  .nd-steps__step-line {
    -ms-flex-positive: 1;
        flex-grow: 1;
    height: 1px;
    border-bottom: 1px solid #8b8a8d;
    margin: 0;
    margin-top: 1rem; }
  .nd-steps__step-line--hidden {
    border: none; }
  .nd-steps__step-line--left {
    margin-right: 0.25rem; }
  .nd-steps__step-line--right {
    margin-left: 0.25rem; }
  .nd-steps__step-content-wrapper {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    outline: none; }
  .nd-steps__step-bubble-wrapper {
    display: -ms-flexbox;
    display: flex; }
  .nd-steps__step-bubble {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    color: #0000a0;
    border: 1px solid #0000a0;
    border-radius: 50%;
    line-height: 2rem;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1.25rem;
    margin: 0rem;
    margin-bottom: 1rem;
    transition-timing-function: ease-in-out;
    transition-property: all, background-color, border;
    transition-duration: 0.15s, 0.075s, 0.225s; }
  .nd-steps__step-label {
    display: inline-block;
    margin: 0 0.5rem;
    font-size: 0.8125rem; }
    .nd-steps__step-label-active {
      font-weight: 700; }
  .nd-steps--interactive .nd-steps__step-bubble {
    outline: none; }
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-bubble {
    color: #0000a0;
    cursor: pointer; }
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-label {
    cursor: pointer; }
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-bubble:hover,
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-bubble:focus {
    color: #0000a0;
    background: #ffffff;
    border-color: #ffffff;
    transition-duration: 0.15s, 0.225s, 0.075s; }
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active).nd-steps__step--done .nd-steps__step-bubble:hover,
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active).nd-steps__step--done .nd-steps__step-bubble:focus {
    background: #ffffff; }
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active).nd-steps__step--done .nd-steps__step-bubble:hover .nd-steps__checkmark-icon g,
  .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active).nd-steps__step--done .nd-steps__step-bubble:focus .nd-steps__checkmark-icon g {
    fill: #40bfa3; }
  .colors-negative .nd-steps__step--done .nd-steps__step-bubble {
    background: #40bfa3; }
  .colors-negative .nd-steps__step--line {
    background: #0000a0; }
  .colors-negative .nd-steps__step-bubble {
    border-color: #0000a0;
    color: #ffffff;
    transition-duration: 0.15s; }
  .colors-negative .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-bubble {
    color: #f0c1ae; }
  .colors-negative .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-bubble:hover,
  .colors-negative .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active) .nd-steps__step-bubble:focus {
    color: #0000a0;
    background: #ffffff;
    border-color: #ffffff;
    transition-duration: 0.15s; }
  .colors-negative .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active).nd-steps__step--done .nd-steps__step-bubble:hover,
  .colors-negative .nd-steps--interactive .nd-steps__step:not(.nd-steps__step--active).nd-steps__step--done .nd-steps__step-bubble:focus {
    background: #0000a0; }

.nd-pagination {
  outline-style: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .nd-pagination a.nd-pagination-button {
    text-decoration: none; }

.nd-pagination-button {
  color: #0000a0;
  font-size: 1.25rem;
  margin: 0 5px;
  cursor: pointer; }
  .nd-pagination-button--selected {
    outline: none;
    background-color: #f0c1ae;
    border-radius: 50%; }
  .nd-pagination-button--disabled .icon {
    color: #8b8a8d;
    cursor: default; }
  .nd-pagination-button--page {
    display: inline-block;
    height: 30px;
    width: 30px;
    text-align: center; }
    .nd-pagination-button--page:hover, .nd-pagination-button--page:focus {
      outline: none;
      background-color: #f0c1ae;
      border-radius: 50%; }
  .nd-pagination-button--previous {
    margin-right: 15px; }
  .nd-pagination-button--next {
    margin-left: 15px; }
  .nd-pagination-button--more {
    cursor: default; }
    .nd-pagination-button--more:hover, .nd-pagination-button--more:focus {
      outline: inherit;
      background-color: inherit;
      border-radius: inherit; }
  .nd-pagination-button--hidden {
    visibility: hidden; }

.colors-negative .nd-pagination-button {
  color: #ffffff; }
  .colors-negative .nd-pagination-button--disabled .icon {
    color: #8b8a8d; }
  .colors-negative .nd-pagination-button--selected {
    color: #0000a0; }
  .colors-negative .nd-pagination-button--page:hover, .colors-negative .nd-pagination-button--page:focus {
    color: #0000a0; }
  .colors-negative .nd-pagination-button--first:hover, .colors-negative .nd-pagination-button--first:focus, .colors-negative .nd-pagination-button--last:hover, .colors-negative .nd-pagination-button--last:focus {
    color: #ffffff; }
  .colors-negative .nd-pagination-button--more:hover, .colors-negative .nd-pagination-button--more:focus {
    color: #ffffff; }

nd-icon {
  color: #0000a0;
  position: relative; }
  nd-icon .icon {
    color: currentColor; }
  nd-icon ng-transclude {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.nd-notification {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  .nd-notification--active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; }

.expand-row__preview {
  cursor: pointer; }

.expand-row__preview:hover {
  background-color: #f1f8ff; }

.expand-row__preview--header,
.expand-row__preview--category {
  font-size: 0.8125rem;
  text-transform: uppercase;
  cursor: default;
  line-height: 1; }
  .expand-row__preview--header:hover,
  .expand-row__preview--category:hover {
    background: initial; }

.expand-row__preview--header {
  border: 1px solid #e6e4e3;
  border-bottom: none;
  line-height: 2.75rem; }
  .expand-row__preview--header .expand-row__preview__content {
    padding: 0; }
    .expand-row__preview--header .expand-row__preview__content .columns {
      line-height: 1rem;
      margin: 0.8rem 0; }

.expand-row__preview--footer {
  cursor: default; }

.expand-row__flyout__container {
  padding-left: 0.75rem; }

.expand-row__preview--category .expand-row__preview__content,
.expand-row__preview--category .expand-row__flyout-column {
  padding-top: 1.5rem;
  padding-bottom: 1rem; }

.expand-row__side {
  width: 20px; }

.expand-row__preview__content,
.expand-row__details__content,
.expand-row__details__actions {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem; }

.expand-row__details__content,
.expand-row__details__actions {
  padding: 0.75rem 1.5rem; }

.expand-row__details__content {
  padding-bottom: 1.5rem; }

.expand-row__preview {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid #e6e4e3;
  border-bottom-color: transparent;
  border-top: 1px solid #e6e4e3; }

.expand-row__details > .columns {
  padding: 0; }

.expand-row__details__actions--separated {
  border-top: 1px solid #e6e4e3; }

.row .row.expand-row {
  margin-left: 0;
  margin-right: 0; }

.expand-row .row {
  -ms-flex-align: center;
      align-items: center; }

.expand-row__icon-column {
  display: inline-block;
  margin-right: 0.5rem; }
  .expand-row__icon-column .icon {
    width: 1.5rem; }
    .expand-row__icon-column .icon.flyout-icon--css-fallback {
      width: 1rem; }
  .expand-row__icon-column:last-child {
    margin-right: 0; }

.expand-row--selected {
  background-color: #ffffff; }

.expand-row-container--white {
  background-color: #ffffff; }
  .expand-row-container--white .expand-row__preview--header {
    background-color: #ececed; }

.expand-row--expanded .expand-row__details {
  border-bottom: 1px solid #e6e4e3;
  border-bottom-color: transparent; }

.expand-row--expanded .expand-row__preview {
  border-bottom: 1px solid #e6e4e3; }

.expand-row-container:not(.expand-row-container--multi-expand) .expand-row-wrapper--expanded + nd-expand-row .expand-row__preview {
  border-top-color: transparent; }

.expand-row-container:not(.expand-row-container--multi-expand) .expand-row-wrapper--expanded .expand-row__preview {
  border-top: 1px solid black; }

.expand-row-container:not(.expand-row-container--multi-expand) .expand-row-wrapper--expanded .expand-row__details {
  border-bottom: 1px solid black; }

nd-expand-row:last-child .expand-row__preview {
  border-bottom: 1px solid #e6e4e3; }

nd-expand-row:last-child .expand-row--expanded .expand-row__details {
  border-bottom: 1px solid #e6e4e3; }

.nd-slide-up-down.ng-enter-prepare {
  display: none; }

.nd-data-grid-buttons-right {
  text-align: center; }
  .nd-data-grid-buttons-right > .icon {
    width: 2rem; }

.data-grid__preview__content .st-sort-ascent::after {
  content: '\25B2';
  padding-left: 0.2rem; }

.data-grid__preview__content .st-sort-descent::after {
  content: '\25BC';
  padding-left: 0.2rem; }

.data-grid__preview {
  cursor: pointer; }
  @media (min-width: 768px) {
    .data-grid__preview {
      -ms-flex-align: center;
          align-items: center; } }

.data-grid__preview:hover {
  background-color: #f1f8ff;
  border-top: 1px solid #c9e4ff;
  border-bottom: 1px solid #c9e4ff; }

.data-grid-wrapper--expanded .data-grid__preview:hover {
  border-bottom-color: transparent; }

nd-data-grid-row:hover + nd-data-grid-row .data-grid__preview {
  border-top-color: transparent; }

.data-grid__preview--header {
  font-size: 0.8125rem;
  text-transform: uppercase;
  cursor: default;
  line-height: 1;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  .data-grid__preview--header:hover {
    background: initial; }

.data-grid__preview--header {
  border: 1px solid #e6e4e3;
  border-bottom: none;
  line-height: 2.75rem; }
  .data-grid__preview--header .data-grid__preview__content {
    padding: 0; }
    .data-grid__preview--header .data-grid__preview__content .columns {
      line-height: 1.5;
      margin: 1rem 0; }

.data-grid__preview--footer {
  cursor: default; }

.data-grid__preview--category .data-grid__preview__content,
.data-grid__preview--category .data-grid__flyout-column {
  padding-top: 1.5rem;
  padding-bottom: 1rem; }

.data-grid__side {
  width: 20px; }

.data-grid__preview__content,
.data-grid__details__actions {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem; }

.data-grid__details__actions {
  padding: 0.75rem 1.5rem; }

.data-grid__details__content {
  padding: 1.5rem; }

.data-grid__preview {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid #e6e4e3;
  border-bottom-color: transparent;
  border-top: 1px solid #e6e4e3; }

.data-grid__details > .columns {
  padding: 0; }

.data-grid__details__actions--separated {
  border-top: 1px solid #e6e4e3; }

@media (min-width: 768px) {
  .row .row.data-grid {
    margin-left: 0;
    margin-right: 0; } }

.data-grid .row {
  -ms-flex-align: center;
      align-items: center; }

.data-grid .three-dots-button-content {
  transform: rotate(-90deg) translate(0, -6px) !important;
  font-size: 1.125rem;
  letter-spacing: 1px;
  font-weight: 600;
  display: block; }

.data-grid .three-dots-button:hover,
.data-grid .three-dots-button:active,
.data-grid .three-dots-button:visited,
.data-grid .three-dots-button:focus {
  text-decoration: none !important;
  background-color: #c9e4ff !important; }

.data-grid-waiting--vertical-align {
  display: inline-block; }

.data-grid__icon-column {
  display: inline-block;
  margin-right: 0.5rem; }
  .data-grid__icon-column .icon {
    width: 2rem; }
    .data-grid__icon-column .icon.flyout-icon--css-fallback {
      width: 1rem; }
  .data-grid__icon-column:last-child {
    margin-right: 0; }

.data-grid__flyout-column {
  width: 2rem; }

.data-grid-row-expanded {
  border-top: solid 1px #f3f3f3; }
  .data-grid-row-expanded .nd-data-grid-expand-default-class {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }

.nd-data-grid-error {
  width: 100%;
  height: 100%; }
  .nd-data-grid-error .icon {
    color: #8b8a8d; }

.nd-data-grid-empty {
  width: 100%;
  height: 100%;
  padding-top: 1.3rem; }

.nd-data-grid-expand-default-class {
  width: 100%;
  height: 220px; }

.data-grid--selected {
  background-color: #f3f3f3; }

.data-grid__preview--header div[st-sort] {
  cursor: pointer; }

.data-grid-container--white {
  background-color: #ffffff; }
  .data-grid-container--white .data-grid__preview--header {
    background-color: #ececed; }

.data-grid--expanded .data-grid__preview {
  border-bottom: 1px solid #e6e4e3; }

.data-grid-container:not(.data-grid-container--multi-expand) .data-grid-wrapper--expanded + nd-data-grid .data-grid__preview {
  border-top-color: transparent; }

.data-grid-container:not(.data-grid-container--multi-expand) .data-grid-wrapper--expanded .data-grid__preview {
  border-top: 1px solid #000000; }

.data-grid-container:not(.data-grid-container--multi-expand) .data-grid-wrapper--expanded .data-grid-row-expanded {
  border-bottom: 1px solid #000000; }

nd-data-grid-row:nth-last-of-type(1) .data-grid__preview {
  border-bottom: 1px solid #e6e4e3; }

nd-data-grid-row:nth-last-of-type(1) .data-grid__preview:hover {
  border-bottom: 1px solid #c9e4ff; }

.nd-data-grid-error--padding-top {
  padding-top: 1rem; }

.data-grid-group-row .data-grid-group-row--header {
  background-color: #ececed;
  border: 1px solid #e6e4e3;
  border-bottom: transparent; }

.data-grid-group {
  font-size: 0.8125rem;
  padding: 0.75rem 0; }
  @media (min-width: 768px) {
    .data-grid-group {
      padding: 0.75rem; } }

.data-grid-pagination {
  text-align: right;
  font-size: 1.125rem;
  padding: 0.75rem;
  color: #0000a0;
  margin-right: -0.75rem;
  margin-left: -0.75rem; }
  @media (min-width: 768px) {
    .data-grid-pagination {
      margin: auto; } }
  .data-grid-pagination > div {
    display: inline-block;
    text-align: center;
    cursor: pointer; }
  .data-grid-pagination__item {
    margin: 0 0.4rem;
    padding: 0 0.2rem; }
    .data-grid-pagination__item--selected {
      font-weight: bold;
      color: #000000; }
      .data-grid-pagination__item--selected:hover {
        text-decoration: none !important; }
  .data-grid-pagination__first {
    margin: 0 0.5rem 0 1rem; }
  .data-grid-pagination__last {
    margin: 0 1rem 0 0.5rem; }
  .data-grid-pagination__first:hover, .data-grid-pagination__last:hover, .data-grid-pagination__item:hover {
    text-decoration: underline; }
  .data-grid-pagination .icon {
    margin-bottom: 4px; }

.data-grid-pipe-loading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 2rem; }

.data-grid-default-waiting {
  width: 40px;
  height: 40px;
  top: -20px; }
  .data-grid-default-waiting__svg {
    width: 40px;
    height: 40px;
    transform-origin: 20px 20px 20px; }

.data-grid-arrow {
  border-right: 1px solid #0000a0;
  border-bottom: 1px solid #0000a0;
  display: inline-block;
  margin-top: 5px;
  padding: 5px;
  transform-origin: 70% 70%; }
  .data-grid-arrow--up {
    transform: rotate(-135deg); }
  .data-grid-arrow--down {
    transform: rotate(45deg); }

.button.three-dots-button {
  padding: 2px; }

.button.three-dots-button > nd-icon {
  color: transparent;
  fill: #0000a0; }

.contentLoader__outerContainer {
  width: 100%;
  height: 100%; }

.contentLoader__spinnerContainer,
.contentLoader__errorContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 100%; }

.contentLoader__spinnerContainer {
  margin: 2rem 0; }

.contentLoader__errorContainer__text {
  text-align: center;
  margin-bottom: 1rem; }

.contentLoader__errorIcon {
  color: #8b8a8d !important;
  margin-bottom: 1rem; }

.contentLoader__example__panels {
  width: 380px;
  height: 240px;
  margin-top: 20px; }

.button {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0.5rem 1.75rem;
  font-size: 1rem;
  font-family: 'Nordea Sans';
  font-weight: 500;
  vertical-align: middle;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 50px;
  -webkit-appearance: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  @supports (-webkit-appearance: none) {
    .button {
      padding-top: 0.6rem; } }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button + .button {
    margin-left: 0.75rem; }
  .button.ng-hide, .button.ng-leave {
    transition: none; }
  .button.disabled, .button[disabled] {
    pointer-events: none; }
  .button--large {
    padding: 0.75rem 2.5rem;
    font-size: 1.25rem; }
  .button .icon {
    color: currentColor;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    top: 0; }
  .button--icon {
    padding-left: 0.6rem;
    padding-right: 0.6rem; }
  .button--icon-right, .button--icon-left {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
    .button--icon-right .icon, .button--icon-left .icon {
      position: absolute;
      height: 100%; }
    .button--icon-right.button--large, .button--icon-left.button--large {
      padding-left: 4.5rem;
      padding-right: 4.5rem; }
  .button--icon-right .icon:last-of-type {
    right: 1rem; }
  .button--icon-left .icon:first-of-type {
    left: 1rem; }

a.button:hover,
a.button:focus {
  text-decoration: none; }

.button.button--primary, .button {
  color: #ffffff;
  background-color: #0000a0;
  border-color: #0000a0; }
  .button.button--primary:hover, .button:hover, .button.button--primary:focus, .button:focus {
    color: #0000a0;
    background-color: #e5f2ff;
    border-color: #e5f2ff; }
  .button.button--primary.disabled, .button.disabled, .button.button--primary[disabled], .button[disabled] {
    color: #ececed;
    background-color: #c9c7c7;
    border-color: #c9c7c7; }

.button.button--secondary {
  color: #0000a0;
  background-color: #ffffff;
  border-color: #0000a0; }
  .button.button--secondary:hover, .button.button--secondary:focus {
    background-color: #e5f2ff; }
  .button.button--secondary.disabled, .button.button--secondary[disabled] {
    color: #c9c7c7;
    background-color: #ececed;
    border-color: #c9c7c7; }

.button.button--tertiary {
  color: #0000a0;
  background-color: transparent;
  border-color: transparent; }
  .button.button--tertiary:hover, .button.button--tertiary:focus {
    text-decoration: underline; }
  .button.button--tertiary.disabled, .button.button--tertiary[disabled] {
    color: #c9c7c7;
    text-decoration: none; }

.button.button--negative {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff; }
  .button.button--negative:hover, .button.button--negative:focus {
    color: #000000;
    background-color: #ffffff; }
  .button.button--negative.disabled, .button.button--negative[disabled] {
    color: #c9c7c7;
    background-color: transparent;
    border-color: #c9c7c7; }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
a.md-no-style,
button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }

.md-padding {
  padding: 8px; }

.md-margin {
  margin: 8px; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 600; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 800;
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3); }

md-input-container {
  box-sizing: content-box; }

@media (min-width: 960px) {
  .md-padding {
    padding: 16px; } }

/*
 * Theming + customization over the existing angular material components
 */
/*
 * Overwrite the z-index values of `md-backdrop` to have them aligned with our app's z-indexes
 *
 * `md-backdrop` is an internal and undocumented component that angular material uses for
 * the full-screen background overlay of dialogs, select components and other things that
 * require a click-catcher behind some absolutely positioned element
 *
 * TODO: investigate if we can get rid of this overwrite by not modifying the z-index of
 * the components we create based on angular material. Then we might not need to align anything.
 *
 */
md-backdrop {
  z-index: 500; }

md-backdrop.md-menu-backdrop {
  z-index: 1699; }

md-backdrop.md-select-backdrop {
  z-index: 1201; }

md-backdrop.md-dialog-backdrop {
  z-index: 1199; }

md-backdrop.md-bottom-sheet-backdrop {
  z-index: 899; }

md-backdrop.md-sidenav-backdrop {
  z-index: 699; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > checkbox > checkbox-default-theme styles
 */
md-checkbox .md-ripple {
  color: #000000; }

md-checkbox.md-checked .md-ripple {
  color: #3399ff; }

md-checkbox.md-checked.md-focused .md-container:before {
  background-color: rgba(127, 191, 255, 0.3); }

md-checkbox .md-ink-ripple {
  color: #000000; }

md-checkbox.md-checked .md-ink-ripple {
  color: #3399ff; }

md-checkbox:not(.md-checked) .md-icon {
  border-color: #0000a0; }

md-checkbox.md-checked .md-icon {
  background-color: #0000a0; }

md-checkbox.md-checked .md-icon:after {
  border-color: #ffffff; }

md-checkbox .md-indeterminate[disabled] .md-container {
  color: #c9c7c7; }

md-checkbox[disabled]:not(.md-checked) .md-icon {
  border-color: #c9c7c7; }

md-checkbox[disabled].md-checked .md-icon {
  background-color: #c9c7c7; }

md-checkbox[disabled].md-checked .md-icon:after {
  border-color: #ffffff; }

md-checkbox[disabled] .md-icon:after {
  border-color: #ffffff; }

md-checkbox[disabled] .md-label,
md-checkbox[disabled] .label--secondary {
  color: #c9c7c7; }

/*
 * This section includes customizations that are not expected by angular material.
 */
md-checkbox {
  margin-bottom: 0; }

md-checkbox .md-icon {
  border-radius: 0; }

md-checkbox.nd-checkbox--align-top .md-container {
  top: 0.25rem;
  transform: none; }

md-checkbox:not([disabled]):hover .md-icon {
  border-color: #3399ff; }

md-checkbox:not([disabled]):not(.md-checked):hover .md-icon {
  background: #e5f2ff; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > radioButton > radioButton-theme styles
 */
md-input-container .md-input {
  color: #000000;
  border-color: #c9c7c7; }

md-input-container .md-input::-webkit-input-placeholder {
  color: #474748; }

md-input-container .md-input:-moz-placeholder {
  color: #474748; }

md-input-container .md-input::-moz-placeholder {
  color: #474748; }

md-input-container .md-input:-ms-input-placeholder {
  color: #474748; }

md-input-container .md-input::-webkit-input-placeholder {
  color: #474748; }

md-input-container .md-input::-ms-clear {
  display: none; }

md-input-container > md-icon {
  color: #0000a0; }

md-input-container label,
md-input-container .md-placeholder {
  color: #474748; }

md-input-container .md-input-messages-animation,
md-input-container .md-input-message-animation {
  color: #b00000; }

md-input-container .md-input-messages-animation .md-char-counter,
md-input-container .md-input-message-animation .md-char-counter {
  color: #8b8a8d; }

md-input-container.md-input-focused .md-input::-webkit-input-placeholder {
  color: #474748; }

md-input-container.md-input-focused .md-input:-moz-placeholder {
  color: #474748; }

md-input-container.md-input-focused .md-input::-moz-placeholder {
  color: #474748; }

md-input-container.md-input-focused .md-input:-ms-input-placeholder {
  color: #474748; }

md-input-container.md-input-focused .md-input::-webkit-input-placeholder {
  color: #474748; }

md-input-container:not(.md-input-invalid).md-input-has-value label {
  color: #474748; }

md-input-container:not(.md-input-invalid).md-input-focused .md-input,
md-input-container:not(.md-input-invalid).md-input-resized .md-input {
  border-color: #0000a0; }

md-input-container:not(.md-input-invalid).md-input-focused label,
md-input-container:not(.md-input-invalid).md-input-focused md-icon {
  color: #0000a0; }

md-input-container.md-input-invalid .md-input {
  border-color: #b00000; }

md-input-container.md-input-invalid label,
md-input-container.md-input-invalid .md-input-message-animation,
md-input-container.md-input-invalid .md-char-counter {
  color: #b00000; }

md-input-container .md-input[disabled],
[disabled] md-input-container .md-input {
  border-bottom-color: transparent;
  color: #8b8a8d;
  background-image: linear-gradient(to right, #8b8a8d 0%, #8b8a8d 33%, transparent 0%);
  background-image: -ms-linear-gradient(left, transparent 0%, #8b8a8d 100%); }

/*
 * This section includes customizations that are not expected by angular material.
 */
input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

md-input-container {
  margin: 1.5rem 0.75rem 0 0; }

md-input-container > .icon {
  position: absolute;
  top: 0px;
  left: 2px;
  right: auto; }

md-input-container > .icon.icon--small {
  top: 8px; }

md-input-container label.md-required:after {
  display: none; }

md-input-container > .icon {
  color: #0000a0; }

md-input-container:not(.md-input-invalid).md-input-focused .icon {
  color: #0000a0; }

md-input-container.md-icon-right > .icon:last-of-type {
  margin: 0;
  right: 2px;
  left: auto; }

md-input-container.md-input-invalid .md-input::-webkit-input-placeholder {
  color: #b00000; }

md-input-container.md-input-invalid .md-input:-moz-placeholder {
  color: #b00000; }

md-input-container.md-input-invalid .md-input::-moz-placeholder {
  color: #b00000; }

md-input-container.md-input-invalid .md-input:-ms-input-placeholder {
  color: #b00000; }

md-input-container.md-input-invalid .md-input::-webkit-input-placeholder {
  color: #b00000; }

.nd-input-container--no-error-spacer .md-errors-spacer {
  display: none; }

md-input-container[md-no-float] {
  margin-top: 0;
  margin-bottom: 0; }

md-input-container[md-no-float] .md-errors-spacer {
  min-height: 0; }

.nd-input__inline-label--single-line {
  display: inline-block;
  margin-top: 0.5rem;
  vertical-align: middle;
  margin-right: 1rem; }

md-input-container.nd-icon-left-inside > .icon:not(.icon--small),
md-input-container.nd-icon-right-inside > .icon:not(.icon--small) {
  top: -3px; }

md-input-container.nd-icon-left-inside > md-icon,
md-input-container.nd-icon-left-inside > .icon {
  left: 0;
  right: auto;
  padding-left: 0.125rem; }

md-input-container.nd-icon-left-inside .md-input {
  padding-left: 2.25rem; }

md-input-container.nd-icon-right-inside .md-input {
  padding-right: 2.25rem; }

md-input-container.nd-icon-left-inside > label {
  left: 2.25rem;
  right: auto; }

md-input-container.nd-icon-right-inside > md-icon:last-of-type,
md-input-container.nd-icon-right-inside > .icon:last-of-type {
  right: 0;
  left: auto;
  margin: 0;
  padding-right: 0.125rem; }

md-input-container.nd-icon-right-inside > .icon--small + .md-input {
  padding-right: 0.375rem; }

md-input-container .nd-input-container__clear-button {
  cursor: pointer;
  visibility: hidden;
  transform: scale(0);
  transition: visibility 150ms ease-in-out, transform 150ms ease-in-out;
  outline: none;
  left: auto;
  right: 0; }

md-input-container .nd-input-container__clear-button:focus svg {
  outline: 2px solid #3399ff; }

@media (-webkit-min-device-pixel-ratio: 0) {
  md-input-container .nd-input-container__clear-button:focus svg {
    outline: -webkit-focus-ring-color auto; } }

md-input-container.md-input-has-value .nd-input-container__clear-button {
  visibility: inherit;
  transform: scale(1); }

/*
 * Customization based on angular material theming docs
 */
md-menu-content {
  background-color: #ffffff; }

md-menu-content md-menu-item {
  color: #000000;
  min-height: 2.75rem;
  height: 2.75rem; }

md-menu-content md-menu-item md-icon,
md-menu-content md-menu-item .icon {
  color: #000000; }

md-menu-content md-menu-item .md-button[disabled] {
  color: #c9c7c7; }

md-menu-content md-menu-item .md-button[disabled] md-icon,
md-menu-content md-menu-item .md-button[disabled] .icon {
  color: #c9c7c7; }

md-menu-content md-menu-divider {
  background-color: #e6e4e3; }

/*
 * Modifications of the md-menu component that are not just "theming" according to how
 * angular material tells us we should customize components.
 */
.md-open-menu-container {
  z-index: 1700;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25); }
  .md-open-menu-container:focus {
    outline: none; }
  .md-open-menu-container.md-active md-menu-content > * {
    transition: none;
    transition-duration: 0s;
    transition-delay: 0s; }

md-menu-item > .md-button {
  padding-left: 1rem;
  padding-right: 2rem;
  font-size: 1rem;
  color: #474748;
  white-space: nowrap; }

md-menu-content md-menu-item .md-button {
  transition: background .15s linear; }

md-menu-content md-menu-item .md-button:not([disabled]):focus,
md-menu-content md-menu-item .md-button:not([disabled]):hover {
  background-color: #f1f8ff;
  outline: none; }

md-menu > .md-button {
  border: none; }
  md-menu > .md-button:hover {
    background-color: #99ccff !important; }
  md-menu > .md-button .three-dots-button-content {
    transform: rotate(-90deg) translate(1px, -4px); }

/*
 * This section includes customizations that are not expected by angular material.
 */
.md-panel:focus {
  outline: none; }

body .md-panel-outer-wrapper {
  position: fixed; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > progressCircular > progressCircular-theme styles
 */
md-progress-circular path {
  stroke: #0000a0; }

/*
 * This section includes customizations that are not expected by angular material.
 */
md-progress-circular {
  margin: auto; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > radioButton > radioButton-theme styles
 */
md-radio-button .md-off {
  border-color: #0000a0; }

md-radio-button .md-on {
  background-color: #0000a0; }

md-radio-button.md-checked .md-off {
  border-color: #0000a0; }

md-radio-button.md-checked .md-ink-ripple {
  color: #3399ff; }

md-radio-button .md-container .md-ripple {
  color: #3399ff; }

md-radio-group:not([disabled]) .md-on,
md-radio-button:not([disabled]) .md-on {
  background-color: #0000a0; }

md-radio-group:not([disabled]) .md-checked .md-off,
md-radio-group:not([disabled]).md-checked .md-off,
md-radio-button:not([disabled]) .md-checked .md-off,
md-radio-button:not([disabled]).md-checked .md-off {
  border-color: #0000a0; }

md-radio-group:not([disabled]) .md-checked .md-ink-ripple,
md-radio-group:not([disabled]).md-checked .md-ink-ripple,
md-radio-button:not([disabled]) .md-checked .md-ink-ripple,
md-radio-button:not([disabled]).md-checked .md-ink-ripple {
  color: #3399ff; }

md-radio-group:not([disabled]) .md-container .md-ripple,
md-radio-button:not([disabled]) .md-container .md-ripple {
  color: #3399ff; }

md-radio-group[disabled],
md-radio-button[disabled],
md-radio-button[disabled] .label--secondary {
  color: #c9c7c7; }

md-radio-group[disabled] .md-container .md-off,
md-radio-button[disabled] .md-container .md-off {
  border-color: #c9c7c7; }

md-radio-group[disabled] .md-container .md-on,
md-radio-button[disabled] .md-container .md-on {
  border-color: #c9c7c7;
  background-color: #c9c7c7; }

md-radio-group .md-checked:not([disabled]) .md-ink-ripple {
  color: #3399ff; }

md-radio-group .md-checked .md-ink-ripple {
  color: #3399ff; }

md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
  background-color: rgba(127, 191, 255, 0.3); }

/*
 * This section includes customizations that are not expected by angular material.
 */
md-radio-button {
  margin: 0; }

md-radio-button:not([disabled]):not(.md-checked):hover .md-off {
  background-color: #e5f2ff; }

md-radio-group > * + * {
  margin-top: 0.75rem; }

md-radio-group.md-focused.ng-empty md-radio-button:not([disabled]) .md-container:before {
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  background-color: rgba(127, 191, 255, 0.3); }

md-radio-group.md-focused.ng-empty md-radio-button:not([disabled]) ~ md-radio-button:not([disabled]) .md-container:before {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent; }

.nd-radio-group--horizontal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: start;
      align-items: flex-start; }
  .nd-radio-group--horizontal > * {
    margin-top: 0; }
  .nd-radio-group--horizontal > * + * {
    margin-left: 0.75rem; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > select > select-theme styles
 */
md-input-container:not(.md-input-focused):not(.md-input-invalid) md-select .md-select-value span:first-child:after {
  color: #474748; }

md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value {
  color: #000000; }

md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value.md-select-placeholder {
  color: #0000a0; }

md-input-container.md-input-invalid md-select .md-select-value {
  color: #b00000 !important;
  border-bottom-color: #b00000 !important; }

md-input-container.md-input-invalid md-select.md-no-underline .md-select-value {
  border-bottom-color: transparent !important; }

md-select[disabled] .md-select-value {
  border-bottom-color: #e6e4e3; }

md-select .md-select-value {
  border-bottom-color: #c9c7c7; }

md-select .md-select-value.md-select-placeholder {
  color: #000000; }

md-select .md-select-value span:first-child:after {
  color: #b00000; }

md-select.md-no-underline .md-select-value {
  border-bottom-color: transparent !important; }

md-select.ng-invalid.ng-touched .md-select-value {
  color: #b00000 !important;
  border-bottom-color: #b00000 !important; }

md-select.ng-invalid.ng-touched.md-no-underline .md-select-value {
  border-bottom-color: transparent !important; }

md-select:not([disabled]):focus .md-select-value {
  color: #000000;
  border-bottom-color: #0000a0; }

md-select:not([disabled]):focus .md-select-value.md-select-placeholder {
  color: #000000; }

md-select:not([disabled]):focus.md-no-underline .md-select-value {
  border-bottom-color: transparent !important; }

md-select[disabled] .md-select-value {
  color: #8b8a8d; }

md-select[disabled] .md-select-value.md-select-placeholder {
  color: #8b8a8d; }

md-select[disabled] .md-select-icon {
  color: #8b8a8d; }

md-select .md-select-icon {
  color: #0000a0; }

md-select-menu {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25); }

md-select-menu md-content {
  background: #ffffff; }

md-select-menu md-content md-optgroup {
  color: #000000; }

md-select-menu md-content md-option {
  color: #000000; }

md-select-menu md-content md-option[disabled] .md-text {
  color: #8b8a8d; }

md-select-menu md-content md-option:not([disabled]):focus,
md-select-menu md-content md-option:not([disabled]):hover {
  background: #f1f8ff; }

md-select-menu md-content md-option[selected] {
  color: #000000; }

md-select-menu md-content md-option[selected]:focus {
  color: #000000; }

.md-checkbox-enabled .md-ripple {
  color: #000000; }

.md-checkbox-enabled[selected] .md-ripple {
  color: #3399ff; }

.md-checkbox-enabled .md-ink-ripple {
  color: #000000; }

.md-checkbox-enabled[selected] .md-ink-ripple {
  color: #3399ff; }

.md-checkbox-enabled:not(.md-checked) .md-icon {
  border-color: #0000a0; }

.md-checkbox-enabled[selected] .md-icon {
  background-color: #0000a0; }

.md-checkbox-enabled[selected].md-focused .md-container:before {
  background-color: #0000a0; }

.md-checkbox-enabled[selected] .md-icon:after {
  border-color: #ffffff; }

.md-checkbox-enabled .md-indeterminate[disabled] .md-container {
  color: #8b8a8d; }

.md-checkbox-enabled md-option .md-text {
  color: #000000; }

/*
 * This section includes customizations that are not expected by angular material.
 */
.md-select-menu-container {
  z-index: 1500; }

md-input-container md-select .md-select-value span:first-child:after {
  display: none; }

md-select .md-select-icon:after {
  content: '' !important;
  transform: none !important;
  width: 1rem;
  height: 1rem;
  float: right;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww%2Ew3%2Eorg%2F2000%2Fsvg%22%3E%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%230000a0%22%20stroke%2Dwidth%3D%222%22%20stroke%2Dlinecap%3D%22round%22%20stroke%2Dlinejoin%3D%22round%22%20points%3D%2212%2E5%205%2E8%208%2010%2E3%203%2E5%205%2E8%22%2F%3E%3C%2Fsvg%3E"); }

md-select[disabled] .md-select-icon:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww%2Ew3%2Eorg%2F2000%2Fsvg%22%3E%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%238b8a8d%22%20stroke%2Dwidth%3D%222%22%20stroke%2Dlinecap%3D%22round%22%20stroke%2Dlinejoin%3D%22round%22%20points%3D%2212%2E5%205%2E8%208%2010%2E3%203%2E5%205%2E8%22%2F%3E%3C%2Fsvg%3E"); }

md-select[disabled] .md-select-value {
  border-bottom-style: dotted; }

md-select[disabled] {
  cursor: not-allowed; }

md-input-container.nd-icon-left-inside .md-select-value {
  padding-left: 2.25rem; }

md-input-container.nd-icon-right-inside .md-select-value {
  padding-right: 2.25rem; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > switch > switch-theme styles
 */
md-switch .md-ink-ripple {
  color: #3399ff; }

md-switch .md-thumb {
  background-color: #ffffff; }

md-switch .md-bar {
  background-color: #8b8a8d; }

md-switch.md-checked .md-ink-ripple {
  color: #3399ff; }

md-switch.md-checked .md-thumb {
  background-color: #40bfa3; }

md-switch.md-checked .md-bar {
  background-color: rgba(64, 191, 163, 0.4); }

md-switch.md-checked.md-focused .md-thumb:before {
  background-color: rgba(127, 191, 255, 0.3); }

md-switch[disabled] .md-thumb {
  background-color: #c9c7c7; }

md-switch[disabled] .md-bar {
  background-color: #e6e4e3; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > tabs > tabs-default-theme styles
 */
md-tabs md-tabs-wrapper {
  background-color: transparent;
  border-color: #e6e4e3; }

md-tabs md-ink-bar {
  color: #e6e4e3;
  background: #40bfa3; }

md-tabs .md-tab[disabled],
md-tabs .md-tab[disabled] md-icon {
  color: #c9c7c7; }

md-tabs .md-tab.md-focused {
  background: #ffffff; }

/*
 * This section includes customizations that are not expected by angular material.
 */
md-tabs md-ink-bar {
  height: 0.25rem; }

md-tabs .md-tab {
  text-transform: none;
  font-weight: inherit;
  font-size: 1rem;
  color: #0000a0;
  padding: 0;
  margin: 0 2rem 0.6875rem 0; }
  md-tabs .md-tab span.badge {
    float: right;
    background-color: transparent;
    color: #0000a0;
    font-size: 1rem;
    padding: 0;
    min-width: 0;
    margin-left: 0.2rem; }
    md-tabs .md-tab span.badge:before {
      content: "("; }
    md-tabs .md-tab span.badge:after {
      content: ")"; }

md-tabs .md-tab[disabled],
md-tabs .md-tab[disabled] md-icon {
  opacity: 1; }

md-tabs .md-tab.md-active,
md-tabs .md-tab.md-active md-icon {
  font-weight: 700; }

md-tabs.colors-negative .md-tab[disabled] md-icon,
.colors-negative md-tabs .md-tab[disabled] md-icon {
  opacity: 0.5; }

md-tabs.colors-negative .md-tab.md-focused,
.colors-negative md-tabs .md-tab.md-focused {
  color: #000000; }

md-tabs[md-border-bottom] md-tabs-wrapper {
  border-width: 0; }

md-tabs md-tabs-canvas, md-tabs md-tabs-canvas md-pagination-wrapper {
  height: 2rem; }
  md-tabs md-tabs-canvas.md-paginated, md-tabs md-tabs-canvas md-pagination-wrapper.md-paginated {
    margin: 0 3.5rem; }

body md-tabs md-tabs-wrapper {
  margin-bottom: 1.5rem; }

md-tabs-content-wrapper md-tab-content {
  transition: none; }

/*
 * This section includes theming based on angular material's theming docs
 * see angular-material > tooltip > tooltip-default-theme styles
 */
.md-panel.md-tooltip {
  color: #ffffff;
  background-color: #474748; }

/*
 * This section includes customizations that are not expected by angular material.
 */
.md-panel.md-tooltip {
  z-index: 1800 !important;
  height: auto;
  max-width: 314px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: normal;
  white-space: normal;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.8); }

.md-panel.md-tooltip.md-show {
  opacity: 1; }

/*
 * Below you will find a fine selection of copy pasted code + rewrites of angular material components.
 * They reinvent the wheel that we get for free from AM.
 * TODO: slowly remove them and use the styles directly from AM + some customization (See above examples)
 */
md-autocomplete md-autocomplete-wrap.md-menu-showing {
  z-index: 501; }

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 1800;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25); }

md-autocomplete {
  border-radius: 0; }
  md-autocomplete:focus {
    outline: none; }
  md-autocomplete .md-show-clear-button button {
    position: absolute;
    right: 0;
    top: 0.25rem;
    color: #0000a0;
    margin: auto 1rem; }
    md-autocomplete .md-show-clear-button button md-icon svg {
      stroke-width: 1.5; }
  md-autocomplete input:not(.md-input) {
    border-bottom: #c9c7c7 solid 2px;
    font-size: 1.25rem;
    color: #000000;
    width: 100%;
    box-sizing: border-box;
    padding: 0 3.5rem 0 1rem; }
    md-autocomplete input:not(.md-input)::-webkit-input-placeholder {
      color: #474748; }
    md-autocomplete input:not(.md-input):-moz-placeholder {
      color: #474748; }
    md-autocomplete input:not(.md-input)::-moz-placeholder {
      color: #474748; }
    md-autocomplete input:not(.md-input):-ms-input-placeholder {
      color: #474748; }
    md-autocomplete input:not(.md-input)::-webkit-input-placeholder {
      color: #474748; }
    md-autocomplete input:not(.md-input).ng-invalid.ng-touched {
      border-bottom-color: #b00000; }
  md-autocomplete input::-ms-clear {
    display: none; }
  md-autocomplete input:not(.md-input):focus,
  md-autocomplete .md-menu-showing input:not(.md-input) {
    border-color: #0000a0;
    outline: none; }
  md-autocomplete[disabled] input:not(.md-input) {
    color: #8b8a8d;
    border-bottom-color: #e6e4e3;
    border-bottom-style: dotted; }
  md-autocomplete md-input-container {
    display: block;
    margin-top: 18px;
    margin-bottom: 18px; }

.md-autocomplete-suggestions-container {
  background: #ffffff; }
  .md-autocomplete-suggestions-container li {
    color: #000000; }
    .md-autocomplete-suggestions-container li .highlight {
      font-weight: 700; }
    .md-autocomplete-suggestions-container li:hover, .md-autocomplete-suggestions-container li.selected {
      background: #f1f8ff; }

@keyframes sliderFocusThumb {
  0% {
    transform: scale(0.7); }
  30% {
    transform: scale(1); }
  100% {
    transform: scale(0.7); } }

@keyframes sliderDiscreteFocusThumb {
  0% {
    transform: scale(0.7); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(0); } }

@keyframes sliderDiscreteFocusRing {
  0% {
    transform: scale(0.7);
    opacity: 0; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0); } }

md-slider {
  height: 48px;
  min-width: 128px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  display: block;
  -ms-flex-direction: row;
      flex-direction: row;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */ }
  md-slider *, md-slider *:after {
    box-sizing: border-box; }
  md-slider .md-slider-wrapper {
    outline: none;
    width: 100%;
    height: 100%; }
  md-slider .md-slider-content {
    position: relative; }
  md-slider .md-track-container {
    width: 100%;
    position: absolute;
    top: 23px;
    height: 2px; }
  md-slider .md-track {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-fill {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: width, height; }
  md-slider .md-track-ticks {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-ticks canvas {
    width: 100%;
    height: 100%; }
  md-slider .md-thumb-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: left, bottom; }
  md-slider .md-thumb {
    z-index: 1;
    position: absolute;
    left: -10px;
    top: 14px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-slider .md-thumb:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border-width: 3px;
      border-style: solid;
      transition: inherit; }
  md-slider .md-sign {
    /* Center the children (slider-thumb-text) */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    position: absolute;
    left: -14px;
    top: -17px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.4) translate3d(0, 67.5px, 0);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    /* The arrow pointing down under the sign */ }
    md-slider .md-sign:after {
      position: absolute;
      content: '';
      left: 0px;
      border-radius: 16px;
      top: 19px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top-width: 16px;
      border-top-style: solid;
      opacity: 0;
      transform: translate3d(0, -8px, 0);
      transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1); }
    md-slider .md-sign .md-thumb-text {
      z-index: 1;
      font-size: 12px;
      font-weight: bold; }
  md-slider .md-focus-ring {
    position: absolute;
    left: -17px;
    top: 7px;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider .md-disabled-thumb {
    position: absolute;
    left: -14px;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.5);
    border-width: 4px;
    border-style: solid;
    display: none; }
  md-slider.md-min .md-sign {
    opacity: 0; }
  md-slider:focus {
    outline: none; }
  md-slider.md-dragging .md-thumb-container,
  md-slider.md-dragging .md-track-fill {
    transition: none; }
  md-slider:not([md-discrete]) {
    /* Hide the sign and ticks in non-discrete mode */ }
    md-slider:not([md-discrete]) .md-track-ticks,
    md-slider:not([md-discrete]) .md-sign {
      display: none; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper .md-thumb:hover {
      transform: scale(0.8); }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
      transform: scale(1);
      opacity: 1; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
      animation: sliderFocusThumb 0.7s cubic-bezier(0.35, 0, 0.25, 1); }
    md-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb {
      transform: scale(1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
    transform: scale(0);
    animation: sliderDiscreteFocusRing 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
    animation: sliderDiscreteFocusThumb 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb, md-slider[md-discrete]:not([disabled]).md-active .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign,
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
  md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-discrete][disabled][readonly] .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete][disabled][readonly] .md-sign,
  md-slider[md-discrete][disabled][readonly] .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[disabled] .md-track-fill {
    display: none; }
  md-slider[disabled] .md-track-ticks {
    opacity: 0; }
  md-slider[disabled]:not([readonly]) .md-sign {
    opacity: 0; }
  md-slider[disabled] .md-thumb {
    transform: scale(0.5); }
  md-slider[disabled] .md-disabled-thumb {
    display: block; }
  md-slider[md-vertical] {
    -ms-flex-direction: column;
        flex-direction: column;
    min-height: 128px;
    min-width: 0; }
    md-slider[md-vertical] .md-slider-wrapper {
      -ms-flex: 1;
          flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 48px;
      -ms-flex-item-align: center;
          align-self: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center; }
    md-slider[md-vertical] .md-track-container {
      height: 100%;
      width: 2px;
      top: 0;
      left: calc(50% - (2px / 2)); }
    md-slider[md-vertical] .md-thumb-container {
      top: auto;
      margin-bottom: 23px;
      left: calc(50% - 1px);
      bottom: 0; }
      md-slider[md-vertical] .md-thumb-container .md-thumb:after {
        left: 1px; }
      md-slider[md-vertical] .md-thumb-container .md-focus-ring {
        left: -16px; }
    md-slider[md-vertical] .md-track-fill {
      bottom: 0; }
    md-slider[md-vertical][md-discrete] .md-sign {
      left: -40px;
      top: 9.5px;
      transform: scale(0.4) translate3d(67.5px, 0, 0);
      /* The arrow pointing left next the sign */ }
      md-slider[md-vertical][md-discrete] .md-sign:after {
        top: 9.5px;
        left: 19px;
        border-top: 14px solid transparent;
        border-right: 0;
        border-bottom: 14px solid transparent;
        border-left-width: 16px;
        border-left-style: solid;
        opacity: 0;
        transform: translate3d(0, -8px, 0);
        transition: all 0.2s ease-in-out; }
      md-slider[md-vertical][md-discrete] .md-sign .md-thumb-text {
        z-index: 1;
        font-size: 12px;
        font-weight: bold; }
    md-slider[md-vertical][md-discrete].md-active .md-sign:after,
    md-slider[md-vertical][md-discrete] .md-focused .md-sign:after, md-slider[md-vertical][md-discrete][disabled][readonly] .md-sign:after {
      top: 0; }
    md-slider[md-vertical][disabled][readonly] .md-thumb {
      transform: scale(0); }
    md-slider[md-vertical][disabled][readonly] .md-sign,
    md-slider[md-vertical][disabled][readonly] .md-sign:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-invert]:not([md-vertical]) .md-track-fill {
    left: auto;
    right: 0; }
  md-slider[md-invert][md-vertical] .md-track-fill {
    bottom: auto;
    top: 0; }

md-slider-container {
  display: block; }
  md-slider-container > *:first-child:not(md-slider),
  md-slider-container > *:last-child:not(md-slider) {
    min-width: 25px;
    max-width: 100%;
    height: 25px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: color, max-width; }
  md-slider-container > *:first-child:not(md-slider) {
    margin-right: 16px; }
  md-slider-container > *:last-child:not(md-slider) {
    margin-left: 16px; }
  md-slider-container[md-vertical] {
    -ms-flex-direction: column;
        flex-direction: column; }
    md-slider-container[md-vertical] > *:first-child:not(md-slider),
    md-slider-container[md-vertical] > *:last-child:not(md-slider) {
      margin-right: 0;
      margin-left: 0;
      text-align: center; }
  md-slider-container md-input-container input[type="number"] {
    text-align: left;
    padding-left: 5px;
    height: 50px;
    margin-top: -25px; }

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff; } }

md-slider .md-track-container {
  height: 4px; }

md-slider .md-track {
  background-color: #e6e4e3;
  border-radius: 4px; }

md-slider .md-track-fill {
  background-color: #0000a0;
  border-radius: 4px; }

md-slider .md-focus-ring {
  background-color: rgba(0, 0, 160, 0.4);
  top: 8px;
  left: -18px; }

md-slider .md-thumb {
  width: 26px;
  height: 26px;
  left: -14px;
  top: 12px; }
  md-slider .md-thumb::after {
    background-color: #ffffff;
    border-color: #ffffff;
    width: 26px;
    height: 26px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.5); }

md-slider-container > span:first-child {
  height: 48px;
  line-height: 48px; }

md-slider-container .input-container:last-child {
  padding: 0;
  height: 48px;
  margin-top: 0;
  margin-bottom: 1rem; }

md-icon, md-icon * {
  stroke: currentColor; }
